import React from 'react';
import {
  Select,
  Checkbox,
  Input,
  ListItemText,
  MenuItem,
  Box,
  FormControl
} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import isNil from 'lodash-es/isNil';
import uniq from 'lodash-es/uniq';

const MultiSelect = ({ options, label, name, description, defaultValue, fontColor, onChange }) => {
  const [values, setValues] = React.useState(isNil(defaultValue) ? [] : defaultValue)

  function handleChange(event) {
    const newValue = uniq(event.target.id);
    setValues(newValue);
    onChange({ field: name, value: newValue })
  }

  // Reset values when default values props are changing
  React.useEffect(() => {
    setValues(isNil(defaultValue) ? [] : defaultValue);
  }, [defaultValue])

  return (
    <Box mb="24px">
      <FormControl fullWidth> sdfsdf
        { label && <Box mb="24px" className="component-label" style={{ color: fontColor }}>{ReactHtmlParser(label)}</Box> }
        { description && <Box className="component-label-description" style={{ color: fontColor }}>{ReactHtmlParser(description)}</Box> }
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          onChange={handleChange}
          value={values}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox checked={values.includes(option.id)} />
              <ListItemText primary={option.value} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

const MemoizedComponent = React.memo(MultiSelect);

export default MemoizedComponent;