import React from 'react';
import { Box } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import ReactHtmlParser from 'react-html-parser';
import isNil from 'lodash-es/isNil';

const EmailAddress = (props) => {
  const { label, onChange, name, defaultValue, error, fontColor, description } = props;

  const handleChange = (event) => {
    onChange({ field: name, value: event.target.value })
  }

  return (
    <Box mb="24px">
      { label && <Box mb="24px" className="component-label" style={{ color: fontColor }}>{ReactHtmlParser(label)}</Box> }
      { description && <Box className="component-label-description" style={{ color: fontColor }}>{ReactHtmlParser(description)}</Box> }
      <TextField
        onChange={handleChange}
        placeholder="Email Address"
        margin="normal"
        fullWidth
        name={name}
        value={!isNil(defaultValue) ? defaultValue : ''}
        error={!isNil(error)}
        helperText={error}
      />
    </Box>
  )
}

const MemoizedComponent = React.memo(EmailAddress);

export default MemoizedComponent;