import React, { useState } from 'react';
import isNil from 'lodash-es/isNil';
import { format } from 'date-fns';
import {
  Box,
  Icon,
  TextField,
  InputAdornment,
  Button
} from '@material-ui/core';
import Pencil from 'icons/pencil.svg';
import ReactHtmlParser from 'react-html-parser';

const Keywords = (props) => {
  const { label, onChange, name, defaultValue, description, fontColor, placeholder, withList } = props;
  const [values, setValues] = useState([]);
  const [addedValue, setAddedValue] = useState('');

  // Reset values when default values props are changing
  React.useEffect(() => {
    const newValue = isNil(defaultValue) ? [] : [...defaultValue];
    setValues(newValue);

    if (!isNil(defaultValue) && defaultValue.length > 0) {
      if (withList) {
        setValues([...defaultValue])
      } else {
        setValues([...defaultValue])
        setAddedValue(defaultValue[0].content)
      }
    }
  }, [defaultValue])

  function handleChange(event) {
    const newValues =  [{ content: event.target.value, created_at: 'First Convo' }];
    
    if (withList) {
      setAddedValue(event.target.value);
    } else {
      setValues(newValues);
      setAddedValue(event.target.value);
      onChange({ field: name, value: newValues })
    }
  }

  function onClickSave() {
    if (isNil(addedValue) || addedValue === '') return;
  
    const newValues =  [...values, { content: addedValue, created_at: format(new Date(), 'dd/MM/yyyy') } ];

    setValues(newValues);
    onChange({ field: name, value: newValues })
    setAddedValue('');
  }

  function value() {
    return addedValue;
  }

  function renderList() {
    if (!withList || values.length == 0) return null;

    return values.map((value) => {
      return (
        <>
          <Box mb="24px" style={{ color: fontColor }}>
            <strong>
              {value.created_at === 'First Convo' ? 'FROM OUR FIRST CONVO' : `ADDED ${value.created_at}`}
            </strong>
          </Box>

          <div 
            key={value.content}
            style={{
              marginTop: '10px',
              marginBottom: '30px',
              backgroundColor: '#ff8c00',
              color: 'white',
              padding: '10px',
              borderRadius: '4px'
            }}
          >
            {value.content}
          </div>
        </>
      )
    })
  }

  return (
    <Box mb="24px" style={{ color: fontColor }}>
      { label && <Box mb="24px" className="component-label" style={{ color: fontColor }}>{ReactHtmlParser(label)}</Box> }
      { description && <Box className="component-label-description" style={{ color: fontColor }}>{ReactHtmlParser(description)}</Box> }
      
      {renderList()}

      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Icon fontSize="small"><img src={Pencil} /></Icon>
            </InputAdornment>
          )
        }}
        fullWidth
        onChange={handleChange}
        value={value()}
        placeholder={placeholder}
      />

      {withList && (
        <div style={{ marginTop: '20px' }}>
          <Button size="small"
            style={{ background: '#276EF1', borderRadius: '20px', color: '#fff', width: 'auto', padding: '5px 10px' }}
            onClick={onClickSave}
          >
            Save
          </Button>
        </div>
      )}
    </Box>
  )
}

export default Keywords;