import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Icon,
  Link,
  Toolbar,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Carousel } from 'react-responsive-carousel';
import { useStyles } from '../../styles/reflectionDashboard';

import ProjectContext from '../../contexts/projectContext';
import BurgerMenu from "../common/BurgerMenu";
import HamburgerIcon from 'icons/hamburger-white.svg';
import CarouselFirst from 'icons/carousel-first.png';
import CarouselSecond from 'icons/carousel-second.png';
import CarouselThird from 'icons/carousel-third.png';
import CarouselFourth from 'icons/carousel-fourth.png';
import PinIcon from 'icons/push-pin.png';
import TepLogo from 'icons/tep-logo.png';
import SemiCircleProgressBar from 'react-progressbar-semicircle';
import ReflectionFooter from './ReflectionFooter';
import ReflectionPayForwardModal from './ReflectionPayForwardModal';
import PrivacyOathModal from './PrivacyOathModal';
import ReflectionModal from './ReflectionModal';
import Footer from '../pages/Footer';
import DragDrop from './DragDrop';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import WithProjectTemplate from "../common/WithProjectTemplate";
import {useReflectionViewVisited} from "../../api/project";

const ReflectionDashboardDragDrop = () => {
  const classes = useStyles();
  const { currentProject } = React.useContext(ProjectContext);
  const [open, setOpen] = React.useState(false);
  const [firstVisit, setFirstVisit] = React.useState(false);
  const [isPayForwardModalOpen, setPayForwardModalOpen] = useState(false);
  const [isPrivacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [isReflectionModalOpen, setReflectionModalOpen] = useState(false);
  const [ markAsViewed, { loading }] = useReflectionViewVisited();

  useEffect(() => {
    if (currentProject && !currentProject.reflectionViewVisited) {
      markAsViewed({ variables: { id: currentProject.id, reflection_view_visited: true } })
      setReflectionModalVisible();
    }
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  function setPayForwardModalVisible() {
    setPayForwardModalOpen(!isPayForwardModalOpen);
  }

  function renderPayForwardModal() {
    if (!isPayForwardModalOpen) return null;
    return <ReflectionPayForwardModal open={isPayForwardModalOpen} onClose={setPayForwardModalVisible} />
  }

  function setPrivacyModalVisible() {
    setPrivacyModalOpen(!isPrivacyModalOpen);
  }

  function renderPrivacyModal() {
    if (!isPrivacyModalOpen) return null;
    return <PrivacyOathModal open={isPrivacyModalOpen} onClose={setPrivacyModalVisible} />
  }

  function setReflectionModalVisible() {
    setReflectionModalOpen(!isReflectionModalOpen);
  }

  function renderReflectionModal() {
    if (!isReflectionModalOpen) return null;
    return <ReflectionModal open={isReflectionModalOpen} onClose={setReflectionModalVisible} />
  }

  return (
    <div className={clsx('page-container', classes.root)}>
        <WithProjectTemplate>
      <Container disableGutters={true} maxWidth="sm">
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
          color="default"
          style={{ background: `#4D4D4D`, padding: `24px 16px 0 16px` }}
        >
          <Toolbar disableGutters={true}>
            <Grid xs container>
              <Grid item container xs={10} alignItems="center">
                <Grid item>
                  <img src={TepLogo} style={{ marginBottom: '-10px', marginRight: '10px', width: '60px' }} />
                </Grid>
                <Grid item>
                  <Typography variant="h6" className={clsx('lora')} style={{ color: '#fff' }}>
                    The Empathy Project
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={2} justify="flex-end">
                <Icon fontSize="large" onClick={handleDrawerOpen}><img src={HamburgerIcon} /></Icon>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <Box className={clsx(classes.dashboardContent)} mt="80px" pt="10px">
          <Box my="20px" px="16px" component="div">
            <Carousel
              style={{ borderRadius: '8px' }}
              autoPlay
              showIndicators
              infiniteLoop
              stopOnHover
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              interval={7000}>
              <Box className={clsx(classes.roomCard)}
                   style={{ width: '100%',
                     backgroundColor: '#444',
                     backgroundImage: `url(${CarouselFirst})`,
                     backgroundSize: '100% 100%', borderRadius: '8px' }}>
                <Box className={clsx(classes.carouselContent)}>
                  Drag and drop your rooms anytime you have new priorities. The only room you cannot move is the Living Room at the #1 spot.
                </Box>
              </Box>
              <Box className={clsx(classes.roomCard)}
                   style={{ width: '100%',
                     backgroundColor: '#444',
                     backgroundImage: `url(${CarouselSecond})`,
                     backgroundSize: 'cover', borderRadius: '8px' }}>
                <Box className={clsx(classes.carouselContent)}>
                  Enter each room and enjoy weekly supply of empathy - reflections (for you) and wish lists (from you).
                </Box>
              </Box>
              <Box className={clsx(classes.roomCard)}
                   style={{ width: '100%',
                     backgroundColor: '#444',
                     backgroundImage: `url(${CarouselThird})`,
                     backgroundSize: 'cover', borderRadius: '8px' }}>
                <Box className={clsx(classes.carouselContent)}>
                  Find your most actionable and powerful growth opportunities in your weekly Giftbox (Coming Soon)
                </Box>
              </Box>
              <Box className={clsx(classes.roomCard)}
                   style={{ width: '100%',
                     backgroundColor: '#444',
                     backgroundImage: `url(${CarouselFourth})`,
                     backgroundSize: 'cover', borderRadius: '8px' }}>
                <Box className={clsx(classes.carouselContentOath)}>
                  <Link href="#" onClick={setPrivacyModalVisible} underline="always" style={{ color: '#fff' }}>TEP PRIVACY OATH</Link>
                  {renderPrivacyModal()}
                </Box>
            </Box>
          </Carousel>
          </Box>

          <DragDrop currentProject={currentProject} />
          <Box my="24px" px="16px">
            <Button variant="contained" size="medium" style={{ backgroundColor: '#F98500', 'color': '#fff' }}
                    onClick={setPayForwardModalVisible}>
              Pay It Forward
            </Button>
            {renderPayForwardModal()}
          </Box>

          <ReflectionFooter />
          <Box pt="24px" style={{ backgroundColor: '#fff', color: '#595959' }}>
            <Footer />
          </Box>
        </Box>
        {renderReflectionModal()}
      </Container>
      </WithProjectTemplate>
    </div>
  )
}

export default ReflectionDashboardDragDrop;
