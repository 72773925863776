import React from 'react'
import { Box, Button, Dialog, DialogTitle, Grid, Icon, Link, TextField, Typography } from '@material-ui/core'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles'
import Close from 'icons/close.svg'

const useStyles = makeStyles(theme => ({
  closeIcon: {
    height: 'auto',
    width: 'auto'
  }
}));

const CustomDialog = (props) => {
  const classes = useStyles();
  const { open, onClose, background } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth>
      { !background && <DialogTitle>
        <Box mt="12px">
          <Grid container
            direction="row"
            justify="space-between"
            alignItems="center">
            <Typography variant="body1" color="primary" className={ clsx('bold') }>{props.title}</Typography>
            <Icon className={ clsx(classes.closeIcon) } onClick={handleClose}><img src={Close} /></Icon>
          </Grid>
        </Box>
      </DialogTitle>}
      {props.children}
    </Dialog>
  )
}

export default CustomDialog