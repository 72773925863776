import React from 'react';
import { Box } from '@material-ui/core'
import { Chip } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser';
import isNil from 'lodash-es/isNil';

import DurationField from './DurationField';

const NestedRateFieldWithDuration = (props) => {
  const [value, setValue] = React.useState(defaultValue)

  const {
    label,
    onChange,
    name,
    defaultValue,
    options,
    description,
    fontColor,
    error,
    logicalExpression
  } = props;

  // Reset values when default values props are changing
  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const showDurationField = (val) => {
    // We always assume that api will return a series of answer
    // option identifier separated by comma.
    return logicalExpression.split(',').map((i) => i.trim()).includes(val);
  }

  const handleClick = (option) => {
    const newValue = showDurationField(option.identifier) 
      ? { duration: '00', ...value, value: option.identifier }
      : { value: option.identifier }

    setValue(newValue);
    onChange({ field: name, value: newValue })
  }

  const handleDurationChange = (duration) => {
    const newValue = { ...value, duration }
    setValue(newValue);
    onChange({ field: name, value: newValue })
  }

  function renderRateFields() {
    let answerOptions = options.map(option => {
      return <Grid item key={option.id}>
        <Grid container direction="column" alignItems="center" spacing={4}>
          <Typography variant="h2">
            <Chip
              color="primary"
              label={option.title}
              clickable size="medium"
              className="chip-rate"
              onClick={() => handleClick(option)}
              variant={!isNil(value) && value.value === option.identifier ? 'default' : 'outlined'}
            />
          </Typography>
          <Box style={{ width: '80px', color: '#595959', textAlign: 'center', lineHeight: '14px', marginTop: '5px' }}>
            {ReactHtmlParser(option.description)}
          </Box>
        </Grid>
      </Grid>;
    });

    return answerOptions;
  }

  function renderConditionalField() {
    if (isNil(value) || isNil(value.value)) return null;

    // We always assume that api will return a series of answer
    // option identifier separated by comma.
    if (!showDurationField(value.value)) return null;

    return (
      <Box my="24px">
        <DurationField
          label="Hours Daily?"
          fontColor={fontColor} 
          defaultValue={defaultValue.duration}
          onChange={(duration) => handleDurationChange(duration.value)}
          error={error}
        />
      </Box>
    )
  }

  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      { label && <Box mb="24px" className="component-label" style={{ color: fontColor }}>{ReactHtmlParser(label)}</Box>}
      { description && <Box className="component-label-description" style={{ color: fontColor }}>{ReactHtmlParser(description)}</Box>}
      <Box my="24px">
        <Grid container justify="space-around">
          {renderRateFields()}
        </Grid>
      </Box>

      {renderConditionalField()}
    </Box>
  )
}

const MemoizedComponent = React.memo(NestedRateFieldWithDuration);

export default MemoizedComponent;