import React from 'react';

import ReflectionPayForward from '../reflection/ReflectionPayForward';
import WithProjectTemplate from '../common/WithProjectTemplate';

const ReflectionPayForwardPage = () => {
  return (
    <WithProjectTemplate>
      <ReflectionPayForward />
    </WithProjectTemplate>
  )
}

export default ReflectionPayForwardPage;