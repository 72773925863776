/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState, useEffect } from 'react';
import isNil from 'lodash-es/isNil';
import uniq from 'lodash-es/uniq';
import isUndefined from 'lodash-es/isUndefined';
import includes from 'lodash-es/includes';
import pull from 'lodash-es/pull';

const useForm = ({
  defaultState,
  submitAction,
  validationSchema,
  clearFormState,
  runValidationOnEveryChange = false
}) => {
  const [schemaValidation, setSchemaValidation] = useState(validationSchema)
  const [values, setValues] = useState(defaultState);
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [disabledSubmit, setDisableSubmit] = useState(false);

  // Run submit action when
  // there are no errors and is submitted
  useEffect(() => {
    const formFields = Object.keys(values);
    const foundErrors = formFields.some((name) => !!errors[name]);
    if (!foundErrors && isSubmitted) {
      submitAction();
      setIsSubmitted(false);
    }
  }, [errors, isSubmitted, submitAction, values]);

  const detectTouchedFieldPaths = (touchedFieldPaths = []) => {
    // cannot run any validation without a schema
    if (isUndefined(schemaValidation)) {
      return touchedFieldPaths;
    }

    return [...touchedFieldPaths];
  };

  function clearErrors(touchedFieldNames) {
    const touchedFieldPaths = detectTouchedFieldPaths(touchedFieldNames);
    const updatedErrors = touchedFieldPaths.reduce(
      (acc, key) => ({
        ...acc,
        [key]: undefined
      }),
      Object(errors)
    );
    setErrors(updatedErrors);
  }

  const updateErrors = (
    error,
    touchedFieldNames
  ) => {
    const detectedErrors = error.inner.reduce(
      (acc, err) => ({
        ...acc,
        [err.path]: err.message
      }),
      {}
    );
    const touchedFieldPaths = detectTouchedFieldPaths(touchedFieldNames);
    const updatedErrors = touchedFieldPaths.reduce(
      (acc, key) => ({
        ...acc,
        [key]: detectedErrors[key]
      }),
      Object(errors)
    );
    setErrors(updatedErrors);
  };

  function runValidations(values, touchedFieldNames) {
    if (isNil(schemaValidation)) {
      return;
    }

    const keys = Object.keys(values);
    const data = keys.reduce((acc, fieldName) => {
      const value = values[fieldName] !== '' ? values[fieldName] : undefined;
      return {
        ...acc,
        [fieldName]: value
      };
    }, {});

    const affectedFieldNames = touchedFieldNames.filter((name) =>
      includes(keys, name)
    );

    try {
      schemaValidation.validateSync(data, { abortEarly: false });
      clearErrors(affectedFieldNames);
    } catch (error) {
      updateErrors(error, affectedFieldNames);
    }

    // Always set submitting to false
    // to be safe, it should only be set when submit is performed
    setIsSubmitted(false);
  }

  function assignUpdatedValue(fieldName, value) {
    return { ...Object(values), [fieldName]: value };
  }

  function handleChange(event) {
    const { name, value, checked, type } = event.target;

    if (isNil(name)) {
      return;
    }

    const parsed = parseFloat(value);
    let val = !/number|range|checkbox/.test(type)
      ? value
      : !isNaN(parsed)
        ? parsed
        : /checkbox/.test(type)
          ? checked
          : '';

    // Checkboxes with specified values
    if (/checkbox/.test(type) && !isNil(value)) {
      val = value;
    } else {
      val = '';
    }

    const updatedValues = assignUpdatedValue(name, val);

    // We can run validation on every change
    if (runValidationOnEveryChange) {
      runValidations(updatedValues, [name]);
    }
    if (isSubmitted) {
      setIsSubmitted(false);
    }
    setValues(updatedValues);
  }

  // This is usable in cases wherein the form component has element
  // that will do changes in the fields also.
  // E.g icons click in logging calls.
  // This will also useful in using react-datepicker since it has it's
  // own onChange method inside that has multiple return
  // and we only need the return value
  function handleSpecificChange(
    fields
  ) {
    let updatedValues = {};

    if (fields instanceof Array) {
      for (let i = 0; i < fields.length; i++) {
        const { field, value } = fields[i];
        updatedValues = { ...updatedValues, ...{ [field]: value } };
      }
      setValues({ ...values, ...updatedValues });
    } else {
      updatedValues = assignUpdatedValue(fields.field, fields.value);
      setValues(updatedValues);
    }

    // We can run validation on every change
    if (runValidationOnEveryChange) {
      if (fields instanceof Array) {
        runValidations(
          updatedValues,
          fields.map((f) => f.field)
        );
      } else {
        runValidations(updatedValues, [fields.field]);
      }
    }

    if (isSubmitted) {
      setIsSubmitted(false);
    }
  }

  // Will be useful for clearing validation error directly
  function directClearError(fields) {
    let updatedErrors = {};

    if (fields instanceof Array) {
      for (let i = 0; i < fields.length; i++) {
        const { field } = fields[i];
        updatedErrors = { ...updatedErrors, ...{ [field]: undefined } };
      }
    } else {
      updatedErrors = { ...errors, [fields.field]: undefined };
    }

    setErrors(updatedErrors);
  }

  function clearAllErrors() {
    setErrors({});
  }

  function resetForm() {
    setValues(!isNil(clearFormState) ? clearFormState : defaultState);
  }

  function formHasValue() {
    return (
      Object.entries(values).filter(([, v]) => {
        return !isNil(v) && v !== '' && v.length !== 0;
      }).length !== 0
    );
  }

  function notFilledOut() {
    return (
      Object.entries(values).filter(([, v]) => {
        return isNil(v) || v === '' || v.length === 0;
      }).length !== 0
    );
  }

  function hasErrors() {
    return (
      Object.entries(errors).filter(([, v]) => {
        return !isNil(v);
      }).length !== 0
    );
  }

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  function handleSubmit() {
    clearAllErrors();
    runValidations(values, Object.keys(values));
    setIsSubmitted(true);
  }

  function buildErrors(errors) {
    
  }

  return {
    handleChange,
    handleSpecificChange,
    handleSubmit,
    directClearError,
    clearAllErrors,
    resetForm,
    values,
    errors,
    formHasValue,
    runValidations,
    notFilledOut: notFilledOut(),
    hasErrors: hasErrors(),
    setErrors,
    setValues,
    setSchemaValidation,
    disabledSubmit,
    setDisableSubmit
  };
};

export default useForm;
