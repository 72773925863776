import React from 'react';
import { AppBar } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { Drawer } from '@material-ui/core'
import { Icon } from '@material-ui/core'
import { Link } from '@material-ui/core';
import { List } from '@material-ui/core';
import { Toolbar } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import clsx from 'clsx';

import { unAuthenticate } from '../../utils/auth';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import MenuIcon from 'icons/menu.svg';
import ChevronLeftIcon from 'icons/chevron-left.svg';
import ChevronRight from 'icons/chevron-right.svg';
import Styles from '../../styles/dashboard';
import Projects from '../dashboard/Projects';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }
}));

const drawerWidth = 240;

const Dashboard = () => {
  const classesDashboard = Styles();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Fade in={true} unmountOnExit>
      <div className={clsx(classesDashboard.pageContainer)} maxWidth="sm">
        <Container className={clsx(classesDashboard.pageContainer)} maxWidth="sm">
          <AppBar
            position="static"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
            color="default"
          >
            <Toolbar>
              <Box><Icon fontSize="large" onClick={handleDrawerOpen}><img src={MenuIcon} /></Icon></Box>
              <Box ml="20px">
                <Typography variant="h6" className={clsx('lora')} color="primary">
                  The Empathy Project
                </Typography>
              </Box>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="temporary"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <Box onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <Icon fontSize="small"><img src={ChevronLeftIcon} /></Icon> : <Icon fontSize="small"><img src={ChevronRight} /></Icon>}
              </Box>
            </div>
            <Divider />
            <List style={{ padding: '16px', fontSize: '18px' }}>
              <Link href="" onClick={unAuthenticate}>
                Logout
              </Link>
            </List>
            <Divider />
          </Drawer>

          <Projects />
        
        </Container>
      </div>
    </Fade>
  )
}

export default Dashboard;