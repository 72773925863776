import { gql, useMutation } from '@apollo/client';

export const SEND_PAY_FORWARD_EMAIL = gql`
  mutation AddProjectUser($id: ID! $email: String!) {
    addProjectUser(input: {id: $id email: $email}) {
      success
      errors
    }
  }
`;

export function useSendPayForwardEmail(onCompleted, onError) {
  return useMutation(SEND_PAY_FORWARD_EMAIL, { errorPolicy: 'all', onCompleted: onCompleted, onError: onError });
}