import { makeStyles } from '@material-ui/styles'
import ReflectionCoverBackground from 'icons/cover-background.png';
import ReflectionLetterBackground from 'icons/letter-background.svg';

const drawerWidth = 240;

export const useStyles = makeStyles(theme => ({
  viewContent: {
    height: '100%',
    fontSize: '16px',
    lineHeight: '18px',
    marginTop: '104px',
    position: 'relative'
  },
  h2: {
    textAlign: 'center'
  },
  content: {
    textAlign: 'center',
    margin: '12px 24px 24px 24px',
    fontSize: '16px'
  },
  root: {
    display: 'flex',
    backgroundColor: '#fff'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
    background: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    padding: '16px'
  },
  drawerHeader: {
    marginBottom: '34px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  reflectionBox: {
    borderRadius: '20px'
  },
  reflectionBoxTitle: {
    fontWeight: 'bold',
    color: '#fff'
  },
  reflectionBoxInside: {
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '31px',
    borderRadius: '5px'
  },
  reflectionBoxContent: {
    lineHeight: '24px'
  },
  reflectionOptions: {
    borderRadius: '20px'
  },
  pointsButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    bottom: '117px',
    zIndex: '99',
    right: '16px'
  },
  pointsButtonContainer: {
    width: '71px',
    height: '71px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 4px 9px rgba(133, 133, 133, 0.25)'
  },
  sidebarHeader: {
    height: '100%',
    fontSize: '18px',
    color: theme.palette.primary.main
  },
  sidebarLink: {
    fontSize: '14px',
    lineHeight: '19px',
    color: '#A39C9C'
  },
  sidebarList: {
    padding: '16px 0'
  },
  cardBottom: {
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px'
  },
  cardTop: {
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px'
  },
  contentContainer: {
    backgroundColor: '#fff',
    filter: `drop-shadow(0px 4px 16px rgba(205, 205, 205, 0.48))`
  },
  show: {
    opacity: 1,
    maxHeight: '100%',
    transition: 'all 0.3s ease-in-out',
    padding: '24px'
  },
  hide: {
    opacity: 0,
    maxHeight: 0,
    transition: 'all 0.3s ease-in-out'
  },
  active: {
    backgroundColor: '#01CDC2',
    borderTop: '2px solid #01CDC2',
    borderLeft: '2px solid #01CDC2',
    borderRight: '2px solid #01CDC2'
  }
}));