import React from 'react';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Background from 'icons/background.svg'
import Footer from './Footer'
import {navigate} from '@reach/router';

const useStyles = makeStyles({
  root: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'top center'
  },
  h2: {
    textAlign: 'center'
  },
  layout: {
    minHeight: '100vh',
    padding: '16px'
  }
});

const Preamble = () => {
  const classes = useStyles();
  const onInvite = () => {
    navigate('/project/create')
  }

  const onInviteList = () => {
    navigate('/dashboard')
  }
  return (
    <div className={ clsx('page-container', classes.root) }>
      <Container maxWidth="sm" className={ clsx(classes.layout) }>
        <Grid container spacing={3} direction="column" justify="space-between" className={ clsx(classes.layout) }>
          <Grid item>
            <Box mt="64px">
              <Typography variant="h2" className={ clsx(classes.h2, 'lora') }>The Empathy Project</Typography>
            </Box>
            <Typography align="center" variant="body1" component="div">
              <Box mt="64px">
                <Box className={ clsx(classes.content) }>
                  To start an Empathy Project,
                </Box>
                <Box className={ clsx(classes.content) }>
                  simply invite a friend to join you for
                </Box>
                <Box className={ clsx(classes.content) }>
                  a 60-minute conversation.
                </Box>
              </Box>
            </Typography>
          </Grid>
          <Grid item>
            <Box my="44px">
              <Box mt="44px">
                <Button variant="contained" color="primary" onClick={onInvite}>
                  Invite a Friend
                </Button>
              </Box>
              <Box mt="24px">
                <Button variant="contained" color="primary" onClick={onInviteList}>
                  Go to My Invite List
                </Button>
              </Box>
            </Box>
            <Footer />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Preamble;