import React from 'react'
import { Box, Grid, Icon, Link, Typography } from '@material-ui/core'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles'
import Facebook from 'icons/facebook.svg'
import LinkedIn from 'icons/linkedin.svg'
import YouTube from 'icons/youtube.svg'

const useStyles = makeStyles({
  box: {
    textAlign: 'center'
  }
});

const Footer = (props) => {
  const classes = useStyles();
  const newPageUrl = (arg) => {
    switch (arg) {
      case 'Facebook':
        return 'https://www.facebook.com/IgniteMNL/'
      case 'Youtube':
        return 'https://www.youtube.com/channel/UCBa_u5kztnrm_1jePaX88LA'
      case 'LinkedIn':
        return 'https://www.linkedin.com/company/ignitedelta'
    }
  }
  const onButtonClick = (arg) => {
    window.open(newPageUrl(arg), "_blank")
  };
  return (
    <Box className={ clsx('footer', classes.box, props.classes) } pb="44px">
      <Box mb="24px">
        <Grid container direction="column">
          <Grid item>
            <Typography variant="body2">
              © 2021 IGNITE HOUSE. ALL RIGHTS RESERVED.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              <Link href="/terms" underline="none">TERMS OF USE</Link> | <Link href="/privacy-policy" underline="none">PRIVACY POLICY</Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={3} justify="center">
          <Grid item>
            <Icon fontSize="small" onClick={() => onButtonClick('Facebook')}><img src={Facebook} /></Icon>
          </Grid>
          <Grid item>
            <Icon fontSize="small" onClick={() => onButtonClick('LinkedIn')}><img src={LinkedIn} /></Icon>
          </Grid>
          <Grid item>
            <Icon fontSize="small" onClick={() => onButtonClick('Youtube')}><img src={YouTube} /></Icon>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Footer