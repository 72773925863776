import React from 'react';
import { Box, Container, Grid, Icon, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import HandshakeIcon from 'icons/shake.svg'
import { useStyles } from '../../styles/invitation';
import {navigate} from '@reach/router';
import ChevronRightWhite from 'icons/chevron-right-white.svg'
import Fade from '@material-ui/core/Fade';


const InvitationEnd = () => {
  const classes = useStyles();
  const nextStep = () => {
    navigate('/dashboard');
  }

  return (
    <Fade in={true} unmountOnExit>
      <div className={ clsx('page-container', classes.root) } style={{ height: '100vh' }}>
        <Container maxWidth="sm" className={ clsx(classes.layout) }>
          <Box mx="12px">
            <Box my="44px">
              <Typography align="center" className={ clsx(classes.title, 'lora') }>The Empathy Project</Typography>
            </Box>
            <Box m="32px">
              <Grid container justify="space-evenly" className={clsx('segment-subtitle')} style={{ color: '#C4C4C4' }}>
                <Grid item>Step 1</Grid>
                <Grid item>|</Grid>
                <Grid item>Step 2</Grid>
                <Grid item>|</Grid>
                <Grid item className={clsx('bold')} style={{ color: '#595959' }}>Step 3</Grid>
              </Grid>
            </Box>
            <Typography align="center" variant="body1" component="div">
              <Paper elevation={3} className={ clsx(classes.invitation) }>
                <Box className={ clsx(classes.image) }>
                  <img src={HandshakeIcon} />
                </Box>
                <Box mb="12px">
                  Thank you for starting this <br /><Typography display="inline" className={clsx('bold')}>Empathy Project</Typography>.
                </Box>
                <Box my="12px" mb="64px">
                  We sent you an email with a link to click on
                  when you and your friend are ready 
                  for your Empathy Project conversation.
                </Box>
              </Paper>
            </Typography>
          </Box>
          <Box my="44px" style={{ display: 'flex', justifyContent: 'center' }}>
            <Box className={ clsx('next-button') }
              style={{ border: `1px solid #595959`, backgroundColor: '#595959' }}>
              <Icon fontSize="large" onClick={nextStep}><img src={ChevronRightWhite} /></Icon>
            </Box>
          </Box>
        </Container>
      </div>
    </Fade>
  )
}

export default InvitationEnd;