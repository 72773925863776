import React, { useContext } from 'react'
import {
  Box,
  Button,
  Grid
} from '@material-ui/core'
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles'

import ProjectContext from '../../contexts/projectContext'
import CustomDialog from '../dialog/CustomDialog'
import ReflectionModalBg from 'icons/reflection-modal-bg.png'
import MirrorIcon from 'icons/mirror.png'
import TalkIcon from 'icons/talk-bubbles-outline.png'
import ConferenceIcon from 'icons/reflection-room.png'

const useStyles = makeStyles(theme => ({
  closeIcon: {
    height: 'auto',
    width: 'auto'
  },
  content: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 99999,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '25px',
    color: '#595959',
    marginTop: '40px'
  }
}));

const ReflectionModal = (props) => {
  const classes = useStyles();
  const { open, onClose } = props;
  const { currentProject } = useContext(ProjectContext);

  const handleClose = () => {
    onClose();
  };

  return (
    <CustomDialog
      onClose={handleClose}
      open={open}
      fullWidth
      title="REFLECTION"
      background={true}
    >
      <Box style={{ position: 'absolute', right: 0, top: 0, height: '615px' }}>
        <img src={ReflectionModalBg} style={{ height: '615px', width: '100%' }} />
      </Box>
      <Box className={clsx(classes.content)}>
        <Grid container direction="column" justify="center">
          <Grid item>
            <Box mb="20px">
              Dear {currentProject.firstName},
            </Box>
          </Grid>
          <Grid item>
            <Box mb="24px">
              We've arranged your life into 15 Reflection Rooms: 
            </Box>
          </Grid>
          <Grid item>
            <Box mb="24px">
              <Grid item container>
                <Grid item xs={2}>
                  <img src={MirrorIcon} />
                </Grid>
                <Grid item xs={10}>
                  <Box style={{ fontWeight: 'normal', paddingLeft: '10px' }}>
                    each room a mirror reflecting 
                    our empathy conversation; 
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box mb="24px">
              <Grid item container>
                <Grid item xs={2}>
                  <img src={TalkIcon} />
                </Grid>
                <Grid item xs={10}>
                  <Box style={{ fontWeight: 'normal', paddingLeft: '10px' }}>
                    each room inviting more conversation and so deeper self-understanding; 
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box mb="24px">
              <Grid item container>
                <Grid item xs={2} style={{ textAlign: 'center', marginTop: '5px' }}>
                  <img src={ConferenceIcon} style={{ width: '35px' }} />
                </Grid>
                <Grid item xs={10}>
                  <Box style={{ fontWeight: 'normal', paddingLeft: '10px' }}>
                    each room surfacing your most powerful possibilities with each passing week.
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box mt="20px" mb="30px">
              <Button variant="contained" color="primary" size="small"
                style={{ background: '#276EF1', padding: '15px', fontSize: '14px' }}
                onClick={handleClose}>
                Next
              </Button>
            </Box>
          </Grid>

        </Grid>
      </Box>
    </CustomDialog>
  )
}

export default ReflectionModal