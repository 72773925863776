import * as yup from 'yup';
import isNil from 'lodash-es/isNil';
import omitBy from 'lodash-es/omitBy';

export const emailField = ({ attribute }) =>
  yup
    .string()
    .nullable(true)
    .trim()
    .required()
    .email()
    .label(attribute);

export const buildValidationSchema = () => {
  const shape = {
    email: emailField({ attribute: 'Email' })
  };
  return yup.object().shape(omitBy(shape, isNil));
};

export default buildValidationSchema;