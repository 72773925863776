import React from 'react';

const useApplicationContextUpdater = () => {
  const [user, setUser] = React.useState();
  const [toastMessage, setToastMessage] = React.useState();
  const [toastOpen, setToastOpen] = React.useState();
  const [toastColor, setToastColor] = React.useState();

  return {
    user,
    setUser,
    toastMessage,
    setToastMessage,
    toastOpen,
    setToastOpen,
    toastColor,
    setToastColor
  };
};

export default useApplicationContextUpdater;
