import React from 'react';
import clsx from 'clsx';
import { Box, Grid, Button } from '@material-ui/core'
import isNil from 'lodash-es/isNil';

import { useStyles } from '../../styles/reflection';
import { useSubmitAnswer } from '../../api/project';

import TextField from '../inputs/Textfield';
import TagSelection from '../inputs/TagSelection';
import useProjectGraphqlHandler from '../../hooks/useProjectGqlHandler'
import useForm from '../../hooks/useForm';
import ApplicationContext from '../../contexts/applicationContext';
import RateField from '../inputs/RateField';
import ReadOnly from '../inputs/ReadOnly';

const ReflectionPointsForm = ({ 
  segment,
  fname,
  hideDefaultSave,
  customSave,
  onSubmitSuccessCallback
}) => {
  const classes = useStyles();
  const { setToastOpen, setToastMessage, setToastColor } = React.useContext(ApplicationContext)

  const {
    currentProject,
    buildAnswersPayload,
    sortAnswerOptions,
    buildStepAnswers
  } = useProjectGraphqlHandler();

  const [submitAnswer, { loading: submittingAnswer }] = useSubmitAnswer(onSubmitSuccess, onSubmitFail);

  function onSubmitFail() {
    setToastColor('error');
    setToastMessage('Problem occured, failed to save information.');
    setToastOpen(true);
  }

  function onSubmitSuccess() {
    if (!isNil(onSubmitSuccessCallback)) {
      onSubmitSuccessCallback()
    }
  }

  const buildSegmentFormState = () => {
    return buildStepAnswers(segment.steps[0])
  }

  const submit = () => {
    const payload = {
      projectId: currentProject.id,
      answer: {
        segment_id: segment.id.toString(), answers: buildAnswersPayload(values, segment.steps[0])
      }
    }
    submitAnswer({ variables: { ...payload } })
  }

  const {
    values,
    handleSubmit,
    handleSpecificChange,
  } = useForm({
    defaultState: buildSegmentFormState,
    submitAction: submit
  })

  const renderQuestionInput = (question) => {
    if (question.questionType) {
      switch (question.questionType) {
        case 'Input Text Field':
          return <TextField
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            defaultValue={values[question.id]}
            fontColor={segment.fontColor}
            description={question.description}
            placeholder={question.placeholder}
          />
        case 'Tag Selection':
          return <TagSelection key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            options={sortAnswerOptions(question.answerOptions)}
            defaultValue={values[question.id]}
            fontColor={segment.fontColor}
            description={question.description}
          />
        case 'Read_Only':
          return <ReadOnly
            key={question.id}
            content={question.title}
            label={question.title}
            fontColor={segment.fontColor}
            lightning={false}
          />;
        case 'Rate Field (with labels)':
          return <RateField
            key={question.id}
            label={question.title}
            name={question.id}
            options={sortAnswerOptions(question.answerOptions)}
            onChange={handleSpecificChange}
            defaultValue={values[question.id]}
            fontColor={segment.fontColor}
            description={question.description}
          />;
        default:
          return <Box mb="24px" px="16px" key={question.id}>{question.questionType}</Box>;
      }
    } else {
      return null;
    }
  }

  const renderName = () => {
    if (isNil(fname)) return null;

    return (
      <Grid item>
        <Box mb="24px" className={clsx('segment-subtitle', 'bold')}>
          {`${fname}'S REFLECTION`}
        </Box>
      </Grid>
    )
  }

  // Renders custom save butto from caller component
  const renderCustomSave = () => {
    if (isNil(customSave)) return null;

    return customSave(handleSubmit, submittingAnswer);
  }


  const renderSave = () => {
    if (hideDefaultSave) return null;

    return (
      <Grid item>
        <Button
          onClick={handleSubmit}
          variant="outlined"
          color="primary"
          size="small"
          className={clsx(classes.btnSave)}
          disabled={submittingAnswer}
        >
          save
        </Button>
      </Grid>
    )
  }

  const questions = segment.steps[0].questions.sort((a,b) => a.displayOrder - b.displayOrder)

  return (
    <>
      <Box className={clsx(classes.pointsWrapper)}>
        <Box className={clsx(classes.pointsContainer)}>
          <Grid container justify="space-between">
            {renderName()}
            {renderSave()}
          </Grid>

          {questions.map((question) => {
            return renderQuestionInput(question);
          })}
        </Box>
      </Box>
      {renderCustomSave()}
    </>
  )
}

export default ReflectionPointsForm;