import { makeStyles } from '@material-ui/styles'
import ReflectionCoverBackground from 'icons/cover-background.png';
import ReflectionLetterBackground from 'icons/letter-background.svg';

const drawerWidth = 240;

export const useStyles = makeStyles(theme => ({
  viewContent: {
    display: 'flex',
    fontSize: '16px',
    lineHeight: '18px',
    marginTop: '104px',
    position: 'relative'
  },
  h2: {
    textAlign: 'center'
  },
  content: {
    textAlign: 'center',
    margin: '12px 24px 24px 24px',
    fontSize: '16px'
  },
  root: {
    background: `linear-gradient(180deg, rgba(136, 208, 207, 0.1) 0%, rgba(201, 216, 216, 0.1) 58.94%, rgba(235, 132, 178, 0.1) 100%), 
    url(${ReflectionCoverBackground}) no-repeat bottom`,
    height: '100%',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
    background: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    padding: '16px'
  },
  drawerHeader: {
    marginBottom: '34px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    minHeight: '50px'
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  intro: {
    fontSize: '16px',
    lineHeight: '26px'
  },
  giftBox: {
    background: '#01CDC2',
    boxShadow: '0px 4px 16px rgba(205, 205, 205, 0.48)',
    borderRadius: '20px',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#fff'
  },
  radioBtn: {
    fontSize: '14px',
    lineHeight: '21px'
  },
  radio: {
    root: {
      marginBottom: '24px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  icon: {
    borderRadius: '50%',
    width: 20,
    height: 20,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#276EF1',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  payForward: {
    background: '#fff',
    borderRadius: '10px',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    boxShadow: `0px 4px 16px rgba(205, 205, 205, 0.48)`
  },
  sidebarHeader: {
    height: '100%',
    fontSize: '18px',
    color: theme.palette.primary.main
  },
  sidebarLink: {
    fontSize: '14px',
    lineHeight: '19px',
    // color: '#A39C9C',
    color: '#595959',
    textDecoration: 'none'
  },
  sidebarLinkSub: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#A39C9C',
    textDecoration: 'none',
    paddingLeft: '10px'
  },
  sidebarList: {
    padding: '16px 0',
  },
  sidebarListSub: {
    padding: '10px 0 10px 10px',
  }
}));