import { createMuiTheme }  from '@material-ui/core/styles'

const theme = createMuiTheme({
  typography: {
    fontFamily: `'Open Sans', sans-serif`,
    h1: {
    	fontSize: '40px',
    	lineHeight: '54px'
    },
    h2: {
    	fontSize: '36px',
    	lineHeight: '38px',
    	fontWeight: 'normal',
    },
    subtitle1: {
    	fontSize: '26px',
    	lineHeight: '35px'
    },
    subtitle2: {
		textTransform: 'uppercase',
		fontSize: '12px',
		lineHeight: '16px',
		letterSpacing: '0.08em'
    },
    body1: {
    	fontSize: '16px',
    	lineHeight: '22px'
    },
    body2: {
    	fontSize: '12px',
    	lineHeight: '21px'
    },
    content: {
    	fontSize: '16px'
    }
  },
  palette: {
    primary: { 
    	main: '#595959',
    	text: '#fff'
    },
    secondary: { 
    	main: '#757575',
    	text: '#fff'
    },
  }
})

theme.props = {

	MuiButtonBase: {
      disableRipple: true
    }

}

theme.overrides = {

	MuiCssBaseline: {
      '@global': {
      	"body": {
		    margin: 0,
		    padding: 0,
		    width: '100%',
    		overflowX: 'hidden'
		},
		"html": {
		    margin: 0,
		    padding: 0,
		    width: '100%',
    		overflowX: 'hidden'
		},
		"*": {
		    boxSizing: 'border-box'
		},
        ".page-container": {
	    	height: 'auto',
	    	minHeight: '100vh',
		  	width: '100%',
		  	overflow: 'hidden',
		    flexGrow: 1,
		    color: theme.palette.primary.main
	    },
	    ".footer": {
	    	width: '100%'
	    },
	    ".footer-default": {
	    	marginTop: '64px'
	    },
	    ".bold": {
	    	fontWeight: 'bold'
	    },
	    ".italic": {
	    	fontStyle: 'italic'
	    },
	    ".lora": {
	    	fontFamily: `Lora`
	    },
	    ".fname": {
	    	fontSize: '19px',
	    	display: 'inline-block',
	    	textTransform: 'uppercase'
	    },
	    ".default-content": {
	    	padding: '0 16px',
	    	fontSize: '16px',
	    	minHeight: '100%'
	    },
	    ".card-review": {
	    	backgroundColor: '#E7FFFB'
	    },
	    ".card-confirm": {
	    	backgroundColor: '#FDFFE8'
	    },
	    ".card-go-live": {
	    	backgroundColor: '#FFE9E9'
	    },
		".card-in-progress": {
			backgroundColor: '#FDFFE8'
		},
	    ".uppercase" : {
			textTransform: 'uppercase'	    	
	    },
	    ".pending": {
	    	color: '#C4C4C4'
	    },
	    ".completed": {
	    	color: '#00D809'
	    },
	    ".underline": {
	    	textDecoration: 'underline'
	    },
	    ".full-height": {
	    	minHeight: '100vh'
	    },
	    ".full-percent-height": {
	    	minHeight: '100%'
	    },
	    ".next-button": {
	    	width: '72px',
	    	height: '72px',
	    	borderRadius: '50%',
	    	border: `1px solid ${theme.palette.primary.main}`,
	    	textAlign: 'center',
	    	lineHeight: '72px',
	    	display: 'flex',
	    	justifyContent: 'center',
	    	alignItems: 'center'
	    },
	    ".segment-subtitle": {
	    	fontSize: '18px',
	    	lineHeight: '25px'
	    },
	    ".segment-subheading": {
	    	fontSize: '26px',
	    	lineHeight: '35px'
	    },
	    ".segment-heading": {
	    	fontSize: '39px',
	    	lineHeight: '53px',
	    	margin: '0 16px'
	    },
	    ".chip-rate": {
	    	height: '40px',
	    	width: '40px',
	    	fontSize: '20px'
		},
	    ".component-label": {
	    	fontSize: '16px',
	    	lineHeight: '25px',
	    	fontWeight: 400
		},
		".component-label-description": {
	    	'fontSize': '12px',
			'lineHeight': '16px',
			'letterSpacing': '0.08em',
			'textTransform': 'uppercase',
			'fontWeight': 'bold'
		},
		".component-tag-label": {
			fontSize: '12px',
	    	lineHeight: '24px',
	    	fontWeight: 'bold',
	    	textTransform: 'uppercase'
		},
		".segment-end": {
			fontSize: '27px',
			textAlign: 'center',
			alignContent: 'center',
			fontFamily: `'Open Sans', sans-serif`,
			fontWeight: 600,
			lineHeight: '37px',
			color: '#fff'
		},
		".container-with-gutter": {
			padding: '16px'
		},
		".segment-desc-paper": {
			height: '100%',
			overflow: 'auto'
		},
		".segment-step-paper": {
			height: '100%',
			overflow: 'auto'
		},
		".segment-container": {
			height: '100vh'
		},
		".label-lightning": {
			fontSize: '20px'
		},
		".slide": {
			background: 'none !important'
		}
      },
    },

	MuiButton: {
		root: {
	    	textTransform: 'none',
	    	disableElevation: true,
	    	boxShadow: 'none',
	    	fontSize: '18px',
	    	fontWeight: 600,
	    	lineHeight: '21px',
	    	width: '100%',
	    	borderRadius: '10px',
	    	padding: '24px 0'
    	},
		containedPrimary: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.text,
			boxShadow: 'none',
			'&:active': { // button clicked
	       		backgroundColor: theme.palette.secondary.main
	     	}
		},
		outlinedPrimary: {
			border: `1px solid ${theme.palette.primary.main}`,
			backgroundColor: '#fff',
			color: theme.palette.primary.main
		}
	},

	MuiInputLabel: {
	    root: {
	    	fontWeight: 'bold',
			textTransform: 'uppercase',
			fontSize: '12px',
			lineHeight: '16px',
			letterSpacing: '0.08em',
			color: theme.palette.primary.main
	    }
	},

	MuiAppBar: {
		root: {
			paddingTop: '24px'
		}
	},

	MuiChip: {
		root: {
			borderRadius: '5px'
		}
	},

	MuiCheckbox: {
		root: {
			fontFamily: `'Open Sans', sans-serif`,
			fontSize: '14px',
			lineHeight: '19px',
			letterSpacing: '0.08em',
			color: theme.palette.primary.main	
		}
	},

	MuiIcon: {
		root: {
			display: 'flex',
			justifyContent: 'center'
		}
	},

	MuiCircularProgress: {
		static: {
			color: 'red'
		}
	},

	MuiPickersToolbar: {
        toolbar: { backgroundColor: '#595959' }
    },

    MuiRadio: {
    	root: {
    		padding: '0 9px !important'
    	}
    }

}

export default theme
