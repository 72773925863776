import React from 'react';

import PayForwardLink from '../payForward/PayForwardLink';
import WithProjectTemplate from '../common/WithProjectTemplate'

const PayForwardLinkPage = () => {
  return (
    <WithProjectTemplate>
      <PayForwardLink />
    </WithProjectTemplate>
  )
}

export default PayForwardLinkPage;