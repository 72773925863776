import React from 'react';

import PayForwardEnd from '../payForward/PayForwardEnd';
import WithProjectTemplate from '../common/WithProjectTemplate'

const PayForwardEndPage = () => {
  return (
    <WithProjectTemplate>
      <PayForwardEnd />
    </WithProjectTemplate>
  )
}

export default PayForwardEndPage;