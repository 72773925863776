import React from 'react';
import { Box } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { FormControl } from '@material-ui/core'
import { NativeSelect } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser';
import Textfield from '../inputs/Textfield';
import isNil from 'lodash-es/isNil';

const RankingWithInput = (props) => {
  const { onChange, name, rankQuestions, defaultValue, fontColor, label, description } = props;
  const [values, setValues] = React.useState(defaultValue);

  const [rankOptions, setRankOptions] = React.useState([]);

  const buildRankOptions = () => {
    const ranks = rankQuestions.map((_, index) => {
      return {
        label: index + 1,
        value: index + 1
      }
    });

    setRankOptions([{label: '', value: ''}, ...ranks]);
  }

  React.useState(buildRankOptions, []);

  // TODO: revisit this!
  // Native select target value is undefined for some reason
  // added this workaround to get the selected value.
  const handleDropDownChange = (event, rankQuestion) => {
    let value = '';
    let newValue = {};
    const selectedIndex = event.target.options.selectedIndex;
    if (selectedIndex !== '') value = rankOptions[selectedIndex].value;
    newValue[rankQuestion.id] = { ...values[rankQuestion.id], value: value };
    setValues({...values, ...newValue})
    onChange({ field: name, value: {...values, ...newValue} })
  }

  const handleInputChange = (event, rankQuestion) => {
    let newValue = {};
    newValue[rankQuestion.id] = {...values[rankQuestion.id], notes: event.value };
    setValues({...values, ...newValue});
    onChange({ field: name, value: {...values, ...newValue} });
  }

  const handleClear = (event) => {
    event.preventDefault();
    setValues(undefined);
    onChange({ field: name, value: {}})
  }

  const rankIsSelected = (rank) => {
    if (!isNil(values)) {
      const ranksSelected = Object.values(values);
      const selected = ranksSelected.find((r) => r.value === rank.value);
      return !isNil(selected);
    }

    return false;
  }

  // Reset values when default values props are changing
  React.useEffect(() => {
    setValues(defaultValue);
  }, [defaultValue])

  function renderRankQuestion(rankQuestion) {
    const value = !isNil(values) && !isNil(values[rankQuestion.id]) ? values[rankQuestion.id] : {};
    return (
      <Box mb="24px" key={rankQuestion.id}>
        <FormControl fullWidth>
          { rankQuestion.title && <Box mb="24px" className="component-label" style={{ color: fontColor }}>{ReactHtmlParser(rankQuestion.title)}</Box> }
          { rankQuestion.description && <Box className="component-label-description" style={{ color: fontColor }}>{ReactHtmlParser(rankQuestion.description)}</Box> }
          <NativeSelect
            onChange={(event) => handleDropDownChange(event, rankQuestion)}
            inputProps={{
              name: name,
              id: 'dropdown',
            }}
            value={value.value ? value.value : ''}
          >
            {rankOptions.map((rank) => {
              return (
                <option key={rank.value}  disabled={rankIsSelected(rank)} value={rank.value}>{rank.label}</option>
              )
            })}
          </NativeSelect>

          <Textfield 
            onChange={(event) => handleInputChange(event, rankQuestion)}
            label={'Notes'}
            defaultValue={value.notes ? value.notes : ''}
          />
        </FormControl>
      </Box>
    )
  }

  return (
    <Box mb="24px">
      { label && <Box mb="24px" className="component-label" style={{ color: fontColor }}>{ReactHtmlParser(label)}</Box> }
      { description && <Box mb="24px" className="component-label-description" style={{ color: fontColor }}>{ReactHtmlParser(description)}</Box> }
      {rankQuestions.map((rankQuestion) => {
        return renderRankQuestion(rankQuestion)
      })}
      <Button variant="outlined" color="primary" onClick={handleClear} size="small" style={{ width: 'auto' }}>
        Clear
      </Button>
    </Box>
  )
}

const MemoizedComponent = React.memo(RankingWithInput);

export default MemoizedComponent;