import React, { useState } from 'react';
import {
  Box,
} from '@material-ui/core';
import clsx from 'clsx';
import isNil from 'lodash-es/isNil';

import { useStyles } from '../../styles/reflectionDashboard';
import { useMarkConversationAsOpened } from '../../api/reflection';
import useReflectionHandler from '../../hooks/useReflectionHandler';

import ConversationsList from './ConversationsList';
import ConversationForm from './ConversationForm';
import CustomDialog from '../dialog/CustomDialog';

const RoomViewConversation = ({ onViewDropdownClick, conversations }) => {
  const classes = useStyles();
  const { currentProject, currentRoom, setCurrentRoom } = useReflectionHandler();
  const [currentConversationIndex, setCurrentConversationIndex] = useState();
  const [direction, setDirection] = useState("right");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [markConversationAsOpened, {}] = useMarkConversationAsOpened(onMarkSuccess);

  function onMarkSuccess() {
    const conversation = conversations[currentConversationIndex];
    const room = {
      ...currentRoom,
      unopenedConversations: currentRoom.unopenedConversations.filter((c) => c !== conversation.id)
    }
    setCurrentRoom(room);
  }

  function onConversationClick(conversationIndex) {
    const conversation = conversations[conversationIndex];
    setCurrentConversationIndex(conversationIndex);

    const unopened = currentRoom.unopenedConversations.includes(conversation.id);
    if (unopened) {
      markConversationAsOpened({ variables: { id: currentProject.id, conversation_id: conversation.id } })
    }

    // Show modal on living room
    if ((currentRoom.id === '1' || currentRoom.title === 'GPS Room') && unopened) {
      setModalOpen(true);
    }
  }

  function onNextClick() {
    if (currentConversationIndex === conversations.length - 1) return;
    setCurrentConversationIndex(currentConversationIndex + 1);
    setDirection('right');
  }

  function onPreviousClick() {
    if (currentConversationIndex === 0) return;
    setCurrentConversationIndex(currentConversationIndex - 1);
    setDirection('left');
  }

  function onConversationDropClick(view) {
    setCurrentConversationIndex(undefined);
    onViewDropdownClick(view);
  }

  function renderContent() {
    const sortedConversations = conversations.slice().sort((a, b) => a.displayOrder - b.displayOrder)

    if (isNil(currentConversationIndex)) {
      return (
        <ConversationsList
          onConversationClick={onConversationClick}
          conversations={sortedConversations}
          onViewDropdownClick={onConversationDropClick}
        />
      )
    }

    return (
      <ConversationForm
        onViewDropdownClick={onConversationDropClick}
        conversation={conversations[currentConversationIndex]}
        slideDirection={direction}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
        showNextButton={currentConversationIndex < conversations.length - 1}
        showPreviousButton={currentConversationIndex !== 0}
        currentConversationIndex={currentConversationIndex}
        conversations={conversations}
      />
    )
  }

  function renderModal() {
    return (
      <CustomDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        fullWidth
        title=""
      >
        <Box className={clsx(classes.content)}>
          <Box px="20px">
            We have reflected your keyword stories
            from our first empathy conversation,
            but encourage you to revisit them often
            and add more keywords as your truths clarify...
          </Box>
          <br />
          <br />
        </Box>
      </CustomDialog>
    )
  }

  return (
    <Box className={clsx(classes.roomContent)}>
      {renderModal()}
      {renderContent()}
    </Box>
  )
}

export default RoomViewConversation;