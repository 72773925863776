import React, { useContext } from 'react';
import { Box, Container, Grid, Icon, Link, Paper, Typography } from '@material-ui/core';

import { useStyles } from '../../styles/invitation';

import clsx from 'clsx';
import ViberIcon from 'icons/viber.svg'
import WhatsappIcon from 'icons/whatsapp.svg'
import MessengerIcon from 'icons/messenger.svg'
import EnvelopeMailIcon from 'icons/envelope-mail.svg'
import LinkIcon from 'icons/link-fill.svg'
import {navigate} from '@reach/router';
import ChevronRightWhite from 'icons/chevron-right-white.svg'
import ApplicationContext from '../../contexts/applicationContext';
import useToast from '../../hooks/useToasti';
import copy from 'copy-to-clipboard';
import Fade from '@material-ui/core/Fade';
import Invitation from "./Invitation";

const ProjectInvitation = (props) => {
  const classes = useStyles();
  const { project } = props.location.state
  const projectSlug = project.slug
  const { renderToast } = useToast();
  const { setToastOpen, setToastMessage } = useContext(ApplicationContext);

  const nextStep = () => {
    navigate(`/invite/step3`)
  }

  function onClickLink(e){
    e.preventDefault();
    copy(`${process.env.REACT_APP_BASE_URL}/invite/${projectSlug}`);
    setToastMessage('Invitation link copied')
    setToastOpen(true);
  }

  return (
    <Fade in={true} unmountOnExit>
      <div className={ clsx('page-container', classes.root) }>
        { renderToast() }
        <Container maxWidth="sm" className={ clsx(classes.layout) }>
          <Box mx="12px">
            <Box my="44px">
              <Typography align="center" className={ clsx(classes.title, 'lora') }>The Empathy Project</Typography>
            </Box>
            <Box m="32px">
              <Grid container justify="space-evenly" className={clsx('segment-subtitle')} style={{ color: '#C4C4C4' }}>
                <Grid item>Step 1</Grid>
                <Grid item>|</Grid>
                <Grid item className={clsx('bold')} style={{ color: '#595959' }}>Step 2</Grid>
                <Grid item>|</Grid>
                <Grid item>Step 3</Grid>
              </Grid>
            </Box>
            <Typography variant="body1" component="div">
              <Box mt="24px">
                Please send this invite via the messaging app you use with your friend.
              </Box>
            </Typography>
            <Invitation intaker={true}/>
            <Box mt="44px" style={{ textAlign: 'center', color: '#99989E', fontSize: '14px', lineHeight: '19px' }}>
              Copy this link by selecting the box below
            </Box>
            <Box mt="24px" className={ clsx(classes.linkContainer) }>
              <Grid xs container>
                <Grid item xs={2}>
                  <img src={LinkIcon} />
                </Grid>
                <Grid item xs={10}>
                  <Link
                    href="#"
                    underline="always"
                    onClick={(e) => onClickLink(e)}
                    style={{ fontSize: '16px', lineHeight: '22px', color: '#595959', fontWeight: '600' }}>
                    { `${process.env.REACT_APP_BASE_URL}/invite/${projectSlug}` }
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <Box mt="24px" style={{ textAlign: 'center', color: '#99989E', fontSize: '14px', linHeight: '19px' }}>
              and share via your preferred messaging app
            </Box>
          </Box>
          <Box mt="24px">
            <Grid container spacing={4} justify="center">
              <Grid item>
                <Link href={`viber://forward?text=${process.env.REACT_APP_BASE_URL}/invite/${projectSlug}`}>
                  <Icon fontSize="large"><img src={ViberIcon} /></Icon>
                </Link>
              </Grid>
              <Grid item>
                <Link href={`whatsapp://send?text=${process.env.REACT_APP_BASE_URL}/invite/${projectSlug}`}>
                  <Icon fontSize="large"><img src={WhatsappIcon} /></Icon>
                </Link>
              </Grid>
              <Grid item>
                <Link href={`fb-messenger://share/?link=${process.env.REACT_APP_BASE_URL}/invite/${projectSlug}&app_id=381527309922628`}>
                  <Icon fontSize="large"><img src={MessengerIcon} /></Icon>
                </Link>
              </Grid>
              <Grid item>
                <Link href={`mailto:?body=${process.env.REACT_APP_BASE_URL}/invite/${projectSlug}`} target='_top'>
                  <Icon fontSize="large"><img src={EnvelopeMailIcon} /></Icon>
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Box my="44px" style={{ display: 'flex', justifyContent: 'center' }}>
            <Box className={ clsx('next-button') }
              style={{ border: `1px solid #595959`, backgroundColor: '#595959' }}>
              <Icon fontSize="large" onClick={nextStep}><img src={ChevronRightWhite} /></Icon>
            </Box>
          </Box>
        </Container>
      </div>
    </Fade>
  )
}

export default ProjectInvitation;