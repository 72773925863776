import React, { useContext } from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { Link } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { navigate } from '@reach/router';
import ProjectContext from "../../contexts/projectContext";

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px 0',
    padding: '16px 12px',
    fontSize: '14px',
    lineHeight: '19px',
    color: theme.palette.primary.main
  },
  content: {
    height: '100%'
  }
}));

const InviteeCard = ({ project }) => {
  const classes = useStyles();
  const { firstName, lastName, slug } = project;
  const { setCurrentProject } = useContext(ProjectContext);
  const onActionClick = () => {
    setCurrentProject(project);
    navigate(`project/${slug}/reflection/dashboard`);
  }

  return (
    <Paper elevation={3} className={ clsx(classes.root, 'card-review', 'bold') }>
      <Grid container className={ clsx(classes.content) } alignItems="flex-end" justify="space-between">
        <Grid item>
          <Grid container direction="column">
            <Grid item className={ clsx('completed') }>
              Gifter
            </Grid>
            <Grid item className={ clsx('uppercase') }>
              {`${firstName} ${lastName}`}
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={ clsx('uppercase') }>
          <Link onClick={onActionClick} underline="always">
            View
          </Link>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default InviteeCard;