import React from 'react';
import { Box } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { InputAdornment } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser';
import isNil from 'lodash-es/isNil';

const MobileNumber = (props) => {
  const { label, onChange, name, defaultValue, description, fontColor, error } = props;

  // TODO: Revisit this
  function handleInputChange(event) {
    let value = event.target.value;
    if (value.toString() === '-' || value === '+') return;
    if (isNaN(value)) return;
    if (value.length === 1 && value !== '9') return;
    if (event.target.value.length > 10) return;

    onChange({ field: name, value: `+63${value}` })
  }

  return (
    <Box mb="24px" style={{ color: fontColor }}>
      { label && <Box mb="24px" className="component-label" style={{ color: fontColor }}>{ReactHtmlParser(label)}</Box> }
      { description && <Box className="component-label-description" style={{ color: fontColor }}>{ReactHtmlParser(description)}</Box> }
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              +63
            </InputAdornment>
          )
        }}
        fullWidth
        onChange={(event) => handleInputChange(event)}
        value={!isNil(defaultValue) ? defaultValue.substring(3) : ''}
        placeholder={'XXXXXXXXXX'}
        error={!isNil(error)}
        helperText={error}
      />
    </Box>
  )
}

const MemoizedComponent = React.memo(MobileNumber);

export default MemoizedComponent;