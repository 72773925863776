import React from 'react'
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Icon,
  Toolbar,
  Typography
} from '@material-ui/core'
import { useStyles } from '../../styles/backtofuture';
import clsx from 'clsx';
import { navigate } from "@reach/router";
import isNil from 'lodash-es/isNil';

import { useSubmitAnswer } from '../../api/project';
import { useFetchBackToFuture } from '../../api/reflection';
import useToast from '../../hooks/useToasti';

import DataLoader from '../common/DataLoader';
import HamburgerIcon from 'icons/hamburger.svg';
import PersonIcon from 'icons/person-white.svg';
import ArrowIcon from 'icons/arrow-left-right.svg';
import CloseIcon from 'icons/close-outline.svg';
import ThoughtIcon from 'icons/thought-balloon.svg';
import TimeForwardIcon from 'icons/time-forward-white.svg';
import ProjectContext from '../../contexts/projectContext';
import Fade from '@material-ui/core/Fade';
import BurgerMenu from "../common/BurgerMenu";
import ReflectionPointsForm from './ReflectionPointsForm';
import BackToFutureQuestion from './BackToFutureQuestion';
import BackToFutureDetails from './BackToFutureDetails';
import FriendsIconWhite from 'icons/friends-white.svg';
import ApplicationContext from '../../contexts/applicationContext';

const BackToFuture = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [float, setFloat] = React.useState(false);
  const { renderToast } = useToast();

  const parentDivRef = React.useRef();
  const futureRef = React.useRef();
  const gpsRef = React.useRef();
  const reflectionPointsRef = React.useRef();
  const { currentProject } = React.useContext(ProjectContext);
  const [submitAnswer, { loading: submittingAnswer }] = useSubmitAnswer(onSubmitSuccess, onSubmitFail);
  const { setToastOpen, setToastMessage, setToastColor } = React.useContext(ApplicationContext)

  const [fetchBackToFuture, {
    data,
    loading,
  }] = useFetchBackToFuture();

  // Fetch Reflection Points
  React.useEffect(() => {
    fetchBackToFuture({ variables: { id: currentProject.id } })
  }, []);

  function onSubmitSuccess() {
    setToastColor('success');
    setToastMessage('Successfully saved information.');
    setToastOpen(true);
  }

  function onSubmitFail() {
    setToastColor('error');
    setToastMessage('Problem occured, failed to save information.');
    setToastOpen(true);
  }

  const upperFirstName = () => {
    if (isNil(currentProject)) return null;
    return currentProject.firstName.toUpperCase();
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const onNext = () => {
    navigate(`/project/${currentProject.slug}/reflection/gift-box`)
  }

  const scroll = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const renderCustomSave = (save, disabled) => {
    const onClick = () => {
      save();
    }

    return (
      <Box mb="16px">
        <Button variant="contained" color="primary" onClick={onClick} disabled={disabled}>
          Next
        </Button>
      </Box>
    )
  }

  const renderReflection = () => {
    if (isNil(data) || isNil(data.backToFuture.backToFuturePoints)) return null;

    return (
      <ReflectionPointsForm
        segment={data.backToFuture.backToFuturePoints}
        hideDefaultSave={true}
        customSave={renderCustomSave}
        onSubmitSuccessCallback={onNext}
      />
    )
  };

  const onOptionSelect = (identifier) => {
    const backToFutureQuestion = data.backToFuture.backToFutureQuestion;
    const answer = {
      step_id: backToFutureQuestion.stepId,
      question_id: backToFutureQuestion.id,
      answer: identifier
    }
    const payload = {
      projectId: currentProject.id,
      answer: {
        segment_id: backToFutureQuestion.segmentId, answers: [answer]
      }
    }
    submitAnswer({ variables: { ...payload } })
  }

  const renderOptions = () => {
    if (isNil(data) || isNil(data.backToFuture.backToFutureQuestion)) return null;

    return <BackToFutureQuestion 
      onSelect={onOptionSelect}
      question={data.backToFuture.backToFutureQuestion}
    />
  }

  const renderDetails = () => {
    if (isNil(data) || isNil(data.backToFuture.backToFutureDetails)) return null;

    return (
      <div ref={gpsRef}>
        <BackToFutureDetails details={data.backToFuture.backToFutureDetails} />
      </div>
    )
  }


  if (loading) return <DataLoader />

  return (
    <Fade in={true} unmountOnExit>
      <div ref={parentDivRef} className={clsx('page-container', classes.root)}>
        <Container disableGutters={true} maxWidth="sm">
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
            color="default"
            style={{ background: `#fff`, padding: `24px 16px 0 16px`, borderBottom: `1px solid #595959` }}
          >
            <Toolbar disableGutters={true} style={{ justifyContent: 'space-between' }}>
              <Box>
                <Typography variant="h6" className={clsx('lora')} color="primary">
                  The Empathy Project
                </Typography>
              </Box>
              <Box><Icon fontSize="large" onClick={handleDrawerOpen}><img src={HamburgerIcon} /></Icon></Box>
            </Toolbar>
          </AppBar>
          <BurgerMenu
            open={open}
            setOpen={setOpen}
            currentProject={currentProject}
          />
          <Box className={clsx(classes.viewContent)} p="16px">
            {renderToast()}

            <Box style={{ backgroundColor: '#fff' }}>
              <Box ref={futureRef} mb="34px" className="bold" style={{ fontSize: '18px', lineHeight: '24px', textAlign: 'center' }}>
                {`${upperFirstName()}’S BACK TO THE FUTURE`}
              </Box>

              <Box mb="24px" className={clsx(classes.reflectionBoxContent)}>
                When I'm 95, this is the kind of life I want to have lived that I believe would have led to the most joy, meaning  and fulfillment in my life:
              </Box>

              <Box mb="24px">
                <Box p="24px"
                  className={clsx(classes.cardTop)}
                  style={{
                    backgroundColor: '#01CDC2',
                    borderTop: '2px solid #01CDC2',
                    borderLeft: '2px solid #01CDC2',
                    borderRight: '2px solid #01CDC2'
                  }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid xs={4}>
                      <img src={FriendsIconWhite} />
                    </Grid>
                    <Grid xs={8}>
                      <Box className={clsx(classes.reflectionBoxTitle)}>A PERSON WHO FULFILLED MY LIFE'S MISSION</Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box p="24px"
                  className={clsx(classes.cardBottom)}
                  style={{
                    borderBottom: '2px solid #01CDC2',
                    borderLeft: '2px solid #01CDC2',
                    borderRight: '2px solid #01CDC2'
                  }}>
                  <Typography variant="body1">
                    I used my gifts to their fullest in order to serve the people whose pains I am gifted and called to heal. But I did not achieve wealth, power, fame.
                  </Typography>
                </Box>

              </Box>

              {renderOptions()}
              {renderDetails()}


              <Box mb="44px" style={{ fontSize: '18px', lineHeight: '24px' }}>
                We refer to this intersection of <Typography display="inline" className={clsx('bold')}>Genius, Purpose and Service </Typography> as <Typography display="inline" className={clsx('bold')}>GPS</Typography>. It is our personal navigation system for life to help us live our deepest truth and so fulfill our life's highest potential and greatest promise.
              </Box>

              <Box ref={reflectionPointsRef} mb="36px" className={clsx('segment-subtitle', 'bold')}>{`${upperFirstName()}'S REFLECTION`}</Box>

              <Box mb="24px">
                What forces influence the kind of person I want to be and the kind of life I want to live?
              </Box>

              <Box mb="24px" style={{ fontSize: '14px', lineHeight: '19px' }}>
                <Grid container justify="space-evenly">
                  <Grid item>
                    <Typography display="inline" className={clsx('bold')}>1</Typography> = No influence at all
                  </Grid>
                  <Grid item>
                    <Typography display="inline" className={clsx('bold')}>4</Typography> = Very influential
                  </Grid>
                </Grid>
              </Box>

              {renderReflection()}
            </Box>

            {float && <Box className={clsx(classes.pointsButtonWrapper)}>
              <Grid container direction="column">
                <Grid item>
                  <Box onClick={() => scroll(futureRef)} mb="12px" className={clsx(classes.pointsButtonContainer)} style={{ background: '#01CDC2' }}>
                    <img src={PersonIcon} />
                  </Box>
                </Grid>
                <Grid item>
                  <Box onClick={() => scroll(gpsRef)} mb="12px" className={clsx(classes.pointsButtonContainer)} style={{ background: '#B08EB1' }}>
                    <img src={ArrowIcon} />
                  </Box>
                </Grid>
                <Grid item>
                  <Box onClick={() => scroll(reflectionPointsRef)} mb="12px" className={clsx(classes.pointsButtonContainer)} style={{ background: '#DD3874' }}>
                    <img src={ThoughtIcon} />
                  </Box>
                </Grid>
                <Grid item>
                  <Box mb="12px" className={clsx(classes.pointsButtonContainer)} style={{ background: '#595959' }}>
                    <Button onClick={() => setFloat(false)}>
                      <img src={CloseIcon} />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>}

            {!float && <Box className={clsx(classes.pointsButtonWrapper)}>
              <Box mb="12px" className={clsx(classes.pointsButtonContainer)} style={{ background: '#595959' }}>
                <Button onClick={() => setFloat(true)}>
                  <img src={TimeForwardIcon} />
                </Button>
              </Box>
            </Box>}

          </Box>
        </Container>
      </div>
    </Fade>
  )
}

export default BackToFuture;
