import React from 'react';
import {
  Box,
  Button,
  Container,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Link,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from '@reach/router';
import clsx from 'clsx';
import Background from 'icons/background.svg';

const useStyles = makeStyles({
  root: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'top center'
  },
  h2: {
    textAlign: 'center'
  },
  layout: {
    minHeight: '100vh',
    padding: '16px'
  }
});

const Terms = () => {
  const classes = useStyles();

  const onContinue = () => {
    navigate(`/dashboard`);
  }

  return (
    <div className={ clsx('page-container', classes.root) }>
      <Container maxWidth="sm" className={ clsx(classes.layout) }>
        <Box mt="64px">
          <Typography variant="h2" className={ clsx(classes.h2, 'lora') }>The Empathy<br/>Project</Typography>
        </Box>
        <Box mt="44px" mb="24px" style={{ textAlign: 'center' }}>
          <Typography variant="body1" className="bold">TERMS OF USE</Typography>
        </Box>

        <Box mb="24px">
      		The following Terms of Use (the “Terms“) govern the access and use by you of the applications, websites, content, products, and services (the “Services“) made available on 
      		<Link to="https://ignitehouse.vc/" style={{ color: '#c36' }}> https://ignitehouse.vc/ </Link> and all subpage under this main page, applications including 
      		<Link to="https://iamiggy.app/" style={{ color: '#c36' }}> https://iamiggy.app/ </Link> and 
      		<Link to="https://restart.app/" style={{ color: '#c36' }}> https://restart.app/ </Link> collectively referred to in this document as “Site/Services” by Ignite 100 Ventures Holdings Corp. (“Ignite Founders Ventures”).
        </Box>
        <Box mb="24px">
      		Please read the following Terms carefully before accessing or using the Site/Services. By accessing you hereby agree to be bound to these Terms, which establish a contractual relationship (the “Contract”) between you and Ignite Founders Ventures. If you do not agree to these Terms, do not access the Site/Services. Ignite Founders Ventures will not be liable for any loss or damage arising from your failure to comply with these Terms. Ignite Founders Ventures reserves the right and sole discretion to modify these Terms at any time.
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Representation and Warranties</Typography>
        </Box>
        <Box mb="24px">
      		By accessing the Site/Services you acknowledge the following:
        </Box>
        <Box mb="24px">
      		You represent and warrant that you are at least eighteen (18) years old and are capable of entering into binding contracts;
        </Box>
        <Box mb="24px">
      		You represent and warrant that you have the authority and capacity to enter into this Contract, to avail of the Site/Services, and to abide by these Terms;
        </Box>
        <Box mb="24px">
      		You will be solely responsible for any and all liability that results from, or is alleged as a result of your access/use of the Site/Services including, but not limited to personal injuries and property damages;
        </Box>
        <Box mb="24px">
      		You have the legal right to delegate Ignite Founders Ventures, its agents, officers, employees or representatives (the “Personnel”), the authority to access, use and share all information provided by you under this Contract, and the Personnel are given the authority by you to make decisions on your behalf for purposes of providing the Services you have availed of; and
        </Box>
        <Box mb="24px">
      		You will obey all local laws related to the matters set forth herein, and will be solely responsible for any violations of such local laws.
        </Box>
        <Box mb="24px">
      		Further, as a precondition to your accessing and using  the Site/Services, you agree to the following:
        </Box>
        <Box mb="24px">
      		Ignite Founders Ventures reserves the right to share or publish certain information about you in connection with the Site/Services you have availed of, under reasonable terms and conditions;
        </Box>
        <Box mb="24px">
      		Ignite Founders Ventures reserves the right to offer its Site/Services free of charge, impose certain charges for certain Site/Services (the “Premium Services”), and/or impose charges in the event that it is deemed appropriate.
        </Box>
        <Box mb="24px">
      		By purchasing any of the Premium Services offered by Ignite Founders Ventures, you agree to pay all applicable fees and taxes, and be bound to additional terms specific to the Premium Services. Failure to pay these fees, taxes, and abide by the additional terms will result in the immediate termination of your Premium Services.
        </Box>
        <Box mb="24px">
      		You are responsible for all charges incurred in connection with your access of the Site and/or use of the Services (including, but not limited to, online subscriptions, application fees, etc.); and
        </Box>
        <Box mb="24px">
      		Ignite Founders Ventures reserves the right to deny you access to the Site and/or use of its Services on reasonable grounds, based on its sole discretion.
        </Box>
        <Box mb="24px">
      		Ignite Founders Ventures has the exclusive right and ownership over the Site and its Services, including all copyrights and other intellectual property rights in the Services and its contents.
        </Box>
        <Box mb="24px">
      		Ignite Founders Ventures has the right and sole discretion to terminate the Site and/or Services at any time, for any reason.
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Account Information, Password and Security</Typography>
        </Box>
        <Box mb="24px">
      		You are responsible for maintaining the confidentiality of your password and account information, including all relevant information needed to access the Site and/or use the Services;
        </Box>
        <Box mb="24px">
      		You are fully responsible for any and all activities that occur under your password or account;
        </Box>
        <Box mb="24px">
      		You agree to:
        </Box>
        <Box mb="24px">
      		Immediately notify Ignite Founders Ventures of any unauthorized use of your password or account or any other breach of security; and
        </Box>
        <Box mb="24px">
      		Ensure that you exit from your account at the end of each session when accessing the Site/Services.
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Payment</Typography>
        </Box>
        <Box mb="24px">
      		By availing any of our Premium Services and providing Ignite Founders Ventures with your payment information, you hereby agree to these payment terms. To purchase any of the Premium Services offered by Ignite Founders Ventures, you must have Internet access and a current valid accepted payment method as indicated during sign-up process. You agree to Ignite Founders Ventures, or its third-party payment processor, storing, accessing and using your payment information in connection with your use of any of the Premium Services. You also agree to pay all applicable fees and taxes for the Premium Services as they become due, whether on a one-time, installment, or subscription basis. Your transaction with us may be subject to foreign exchange fees or differences in prices, including because of exchange rates.
        </Box>
        <Box mb="24px">
      		As described in the Privacy Policy, the processing of your payment information is done by a third-party payment processor, and you will be routed to a secure payment flow controlled by that processor to complete the payment transaction. Once your payment transaction is completed, a confirmation page will be displayed, and you will receive a confirmation email with your name, order number, and the payment amount.
        </Box>
        <Box mb="24px">
      		By agreeing to this Terms of Use, you agree to be bound by the Terms of Service of our third-party payment processor as applicable. Any breach of those terms will be treated as a breach of these Terms.
        </Box>
        <Box mb="24px">
      		When you make a purchase, you agree not to use an invalid or unauthorized payment method. If any fee is not paid in a timely manner, or our processors are unable to process your transaction using the payment information provided, we reserve the right to terminate your account or suspend or terminate your access to the Premium Services as we deem appropriate. If your payment method fails and you still get access to the Premium Services you are availing, you agree to pay us the corresponding fees within thirty (30) days of notification from us. We reserve the right to disable access to any of the Premium Services for which we have not received adequate payment.
        </Box>
        <Box mb="24px">
      		If your payment details change, your card provider may provide us with updated card details and you agree to our collection of such details. We may use these new details in order to prevent any interruption to the Premium Services. If you would like to use a different payment method or if there is a change in payment method, please visit your account page to update your billing information.
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Subscription and Cancellation</Typography>
        </Box>
        <Box mb="24px">
      		Your subscription to any of the Premium Services begins as soon as your initial payment is processed. Your subscription will automatically renew each month without notice until you cancel. You authorize us to store your payment method(s) and to automatically charge your payment method(s) every month until you cancel. We will automatically charge you the then-current rate for your plan, plus applicable taxes, every month upon renewal until you cancel.
        </Box>
        <Box mb="24px">
      		We may change your plan’s rate each monthly renewal term, and we will notify you of any rate change with the option to cancel. If the applicable tax rate changes during your one-month term, we will accordingly adjust the tax-inclusive price for your plan on your next billing date.
        </Box>
        <Box mb="24px">
      		If your primary payment method fails, you authorize us to charge any other payment method in your account. If you have not provided us a backup payment method(s) and you fail to provide payment, or if all payment methods in your account fail, we may suspend your subscription. You can edit your payment information anytime in your account page.
        </Box>
        <Box mb="24px">
      		You can cancel your subscription anytime via your account page or by contacting us. If you cancel within 14 days of your initial order, you’ll be fully refunded. Should you cancel after 14 days, your payment is non-refundable, and your service will continue until the end of that month’s billing period.
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Disclaimer of Warranty and Limitation of Liability</Typography>
        </Box>
        <Box mb="24px">
      		Ignite Founders Ventures makes no warranty, express or implied, regarding the Services it offers. The use of the Site and its Services are offered on as “AS IS”, “AS AVAILABLE” basis without warranties of any kind, other than warranties that are incapable of exclusion, waiver or limitation under the laws applicable to these Terms. Without limiting the foregoing, Ignite Founders Ventures makes no warranty (1) as to the content, quality, or accuracy of data or information provided; (2) as to any service or product obtained; (3) that the Site or its Services will be completely safe, secure or error-free; (4) that the Site or its Services will function without disruptions, delays, or imperfections; or (5) that any particular result or information will be obtained.
        </Box>
        <Box mb="24px">
      		Ignite Founders Ventures does not assume any responsibility for, and will not be liable for, any damages to, or any viruses, malware or other malicious, destructive or corrupting code, agent program or macros that may infect, your computer, telecommunication equipment or other property caused by or arising from your access to, or use of, the Site and/or the Services, or your downloading of any information or materials from the Site. Ignite Founders Ventures likewise does not assume any responsibility for, and will not be liable for, any damages or losses arising out of, related to, or resulting from any security breach, cyberattack or any other security intrusion on the Site.
        </Box>
        <Box mb="24px">
      		To the extent permitted by law, Ignite Founders Ventures also DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. The liability of Ignite Founders Ventures shall be limited to the fullest extent permitted by applicable law, and under no circumstance will it be liable to you for any lost profits, revenues, information, or data, or consequential, special, indirect, exemplary, punitive, or incidental damages arising out of or related to these Terms or the use of the Site or Services.
        </Box>
        <Box mb="24px">
      		Ignite Founders Ventures shall not be liable for any goods or services offered or provided on the Site or its Services. Ignite Founders Ventures does not give any warranty whatsoever in connection with its Services.
        </Box>
        <Box mb="24px">
      		In the event that a claim is made or an action is filed by you against Ignite Founders Ventures, any of its directors, or Personnel, the aggregate liability of Ignite Founders Ventures its directors, or Personnel shall not in any event exceed Php1,000.00 or the amount of actual damages suffered and substantiated by you, whichever is lower.
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Indemnification</Typography>
        </Box>
        <Box mb="24px">
      		You agree to hold Ignite Founders Ventures and its Personnel free and harmless from any liability to any third party, and to indemnify Ignite Founders Ventures against all indirect, special, punitive, incidental, consequential or exemplary damages (including, without limitation, those resulting from loss of revenues, lost profits, loss of goodwill, loss of use, lost data, business interruption, or other intangible losses) incurred in connection with your use, inability to use, or the results of use of the Site and Services, any platforms linked to this platform, or the materials, information or services contained on any or all such platforms.
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Copyright</Typography>
        </Box>
        <Box mb="24px">
      		Ignite Founders Ventures is, unless otherwise stated, the owner of all copyrights and other intellectual property rights in the Service and its contents. Individuals who have posted works on the Site are either the copyright owners of that work or are posting the work under license from a copyright owner or his or her agent or otherwise as permitted by law. You may not reproduce, distribute, publicly display or perform, or prepare derivative works based on any of the Content including any such works without the express, written consent of the appropriate owner of copyright in such works. Ignite Founders Ventures does not claim ownership rights in your works or other materials posted by you on the Site (“Your Content”). You agree not to distribute any part of the Service other than Your Content in any medium other than as permitted in these Terms or by use of functions on the Service provided by us. You agree not to alter or modify any part of the Service unless expressly permitted to do so by us or by use of functions on the Service provided by us.
        </Box>
        <Box mb="24px">
      		You are responsible for all of the Content you upload, download, and otherwise copy, distribute and display using the Site or Services. You must have the legal right to copy, distribute and display all parts of any content that you upload, download and otherwise copy, distribute and display. Content provided to you by others, or made available through websites, magazines, books and other sources, are protected by copyright and should not be uploaded, downloaded, or otherwise copied, distributed or displayed without the consent of the copyright owner or as otherwise permitted by law.
        </Box>
        <Box mb="24px">
      		Ignite Founders Ventures does not claim ownership rights in Your Content. You own the content you create and share on the Site or through the Services and nothing in these Terms takes away the rights you have to Your Content. For the sole purpose of enabling us to make Your Content available through the Site or Services, you grant to Ignite Founders Ventures a non-exclusive, transferable, sub-licensable, royalty-free, and worldwide license to host, use, modify, reproduce, distribute, re-format, store, translate, prepare derivative works based on, and publicly display and perform Your Content. You can end this license any time by deleting Your Content or account. Please note that when you upload Content, third parties will be able to copy, distribute and display Your Content using readily available tools on their computers for this purpose although other than by linking to Your Content on the Site any use by a third party of Your Content could violate these Terms unless the third party receives permission from you by license.
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Copyright Violations</Typography>
        </Box>
        <Box mb="24px">
      		Ignite Founders Ventures respects the intellectual property rights of others and expects users of the Site to do the same. At its discretion and in appropriate circumstances, Ignite Founders Ventures may remove Your Content submitted to the Site, terminate the accounts of users or prevent access to the Site by users who infringe the intellectual property rights of others. If you believe the copyright in your work or in the work for which you act as an agent has been infringed through this Service, please contact Ignite Founders Ventures for notice of claims of copyright infringement, at the details below.
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Termination</Typography>
        </Box>
        <Box mb="24px">
      		You may stop using the Site/Services at any time. Termination of your account does not relieve you of any obligation to pay any outstanding fees.
        </Box>
        <Box mb="24px">
      		You agree that Ignite Founders Ventures, in its sole discretion, may deactivate your account or otherwise terminate your use of the Site/Services, or any part thereof, for any reason or no reason, including, without limitation, if Ignite Founders Ventures believes that you have (a) breached this Terms of Use; (b) infringed the intellectual property rights of a third party; (c) posted, uploaded or transmitted unauthorized content to the Site/Services; (d) failed to make the timely payment of fees for the Premium Services, if any; or (e) violated or acted inconsistently with the letter or spirit of this Terms of Use or any applicable code of conduct. You agree that any deactivation or termination of your access to the Site/Services may be affected without prior notice to you and that Ignite Founders Ventures shall not be liable to you nor any third-party for any termination of your account or access to the Site/Services. You also acknowledge that Ignite Founders Ventures may retain and store your information on Ignite Founders Ventures’ systems notwithstanding any termination of your account as set forth in our Privacy Policy.
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Non-Waiver of Rights</Typography>
        </Box>
        <Box mb="24px">
      		Failure, omission or delay on the part of Ignite Founders Ventures to exercise its rights or remedies under these Terms shall not operate as a waiver of rights.
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Separability</Typography>
        </Box>
        <Box mb="24px">
      		If any clause or part of a clause of these Terms shall be, or be found by any authority or court of competent jurisdiction to be, invalid or unenforceable, such invalidity or unenforceability shall not affect the other clauses or parts of such clauses of these Terms, all of which shall remain in full force and effect.
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Governing Law and Venue</Typography>
        </Box>
        <Box mb="24px">
      		These Terms shall be governed by the laws of the Republic of the Philippines and in the event of any suit arising from or in connection with these Terms, the appropriate court in Makati City shall have exclusive jurisdiction over the case to the exclusion of all other courts.
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Contact Us</Typography>
        </Box>
        <Box mb="24px">
      		If you have any concerns or questions, feel free to contact us at founders@ignitegifts.com
        </Box>

        <Box my="48px">
	        <FormGroup row>
	      		<FormControlLabel
	      		style={{ letterSpacing: '0.08em' }}
		        control={
		          <Checkbox
		            color="primary"
		            style={{ padding: '0 9px' }}
		          />
		        }
		        label="I have read and accept the Terms & Conditions."
		      />
	      </FormGroup>
        </Box>

        <Box mb="24px">
          <Button variant="contained" color="primary" onClick={onContinue}>
            Continue
          </Button>
        </Box>

      </Container>
    </div>
  )
}

export default Terms;
