import React, { useContext } from 'react';
import {
  Box,
  Grid,
} from '@material-ui/core';
import clsx from 'clsx';
import ChevronRight from 'icons/chevron-right.svg';
import isNil from 'lodash-es/isNil';

import { useMarkConversationAsOpened } from '../../api/reflection';
import { useStyles } from '../../styles/reflectionDashboard';
import RoomViewMenu from './RoomViewMenu';
import ProjectContext from '../../contexts/projectContext';

const ConversationsList = ({ 
  onConversationClick,
  conversations,
  onViewDropdownClick
}) => {
  const classes = useStyles();
  const { currentRoom } = useContext(ProjectContext);

  function handleConversationClick(conversationIndex, id) {
    onConversationClick(conversationIndex);
  }

  // TODO: finalize this once API is ready
  function renderConversations() {
    return conversations.map((conversation, index) => {
      return (
        <Box
          key={conversation.id} py="16px"
          style={{ borderBottom: '1px solid #595959' }}
          onClick={() => handleConversationClick(index, conversation.id)}
        >
          <Grid container xs justify="space-between">
            <Grid item container xs={10} alignItems="center">
              <Grid item>{conversation.title}</Grid>
              {currentRoom.unopenedConversations.includes(conversation.id) && (
                <Grid item><Box className={clsx(classes.notifDot)}></Box></Grid>
              )}
            </Grid>
            <Grid item container xs={2} justify="flex-end">
              <img src={ChevronRight} height="16px" style={{ marginRight: '5px' }} />
            </Grid>
          </Grid>
        </Box>
      )
    });
  }

  return (
    <>
      <RoomViewMenu
        title={!isNil(currentRoom) ? currentRoom.title : ''}
        notifCount={!isNil(currentRoom) ? currentRoom.unopenedConversations.length : 0}
        showNavigation={false}
        onViewDropdownClick={onViewDropdownClick}
      />
      <Box mb="24px">
        Every 7-10 days we feed you a new "conversation" to deepen your reflection and drive more 
        insights on what really matters and your most powerful possibilities in this room.
      </Box>

      <Box mb="12px">In this room:</Box>

      <Box mb="24px">
        <Grid xs container>
          <Grid item container style={{ marginBottom: '12px' }} alignItems="center">
            <Grid item>
              <Box className={clsx(classes.notif)} mr="5px" style={{ background: '#595959', color: '#fff' }}>
                {conversations.length}
              </Box>
            </Grid>
            <Grid item><Box className="bold">Total Conversations</Box></Grid>
          </Grid>
          <Grid item container style={{ marginBottom: '12px' }} alignItems="center">
            <Grid item>
              <Box className={clsx(classes.notif)} mr="5px">{!isNil(currentRoom) ? currentRoom.unopenedConversations.length : 0}</Box>
            </Grid>
            <Grid item><Box className="bold">Unanswered Conversations</Box></Grid>
          </Grid>
        </Grid>
      </Box>
      <Box mb="24px">
        {renderConversations()}
      </Box>
    </>
  )
}

export default ConversationsList;