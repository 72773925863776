import React from 'react';
import isNil from 'lodash-es/isNil';

import useProjectQqlHandler from '../../hooks/useProjectGqlHandler';
import DataLoader from '../common/DataLoader';
import ProjectContext from '../../contexts/projectContext';
import Error from '../common/Error';

const WithProjectTemplate = ({ children }) => {
  const { currentProject } = React.useContext(ProjectContext);
  const { fetchCurrentProject, fetchingCurrentProject, fetchProjectError } = useProjectQqlHandler();

  // Fetch current project on mount.
  // This is creitical because we want to get
  // whole state of the project before starting
  // Only fetch when user refreshes page in intaker route
  React.useEffect(() => {
    if (isNil(currentProject)) {
      fetchCurrentProject()
    }
  }, []);

  if (!isNil(fetchProjectError)) {
    return <Error />
  }

  if (fetchingCurrentProject || isNil(currentProject)) {
    return <DataLoader />
  }

  // TODO: Handle Error

  return (
    <>
      {children}
    </>
  )
}

export default WithProjectTemplate;