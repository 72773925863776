import React from 'react';
import { Box } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Icon } from '@material-ui/core'
import { InputAdornment } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser';
import Pencil from 'icons/pencil.svg';
import isNil from 'lodash-es/isNil';

const WholeNumberField = (props) => {
  const { label, onChange, name, defaultValue, fontColor, description, error } = props;

  const handleChange = (event) => {
    onChange({ field: name, value: event.target.value })
  }

  return (
    <Box mb="24px">
      { label && <Box mb="24px" className="component-label" style={{ color: fontColor }}>{ReactHtmlParser(label)}</Box> }
      { description && <Box className="component-label-description" style={{ color: fontColor }}>{ReactHtmlParser(description)}</Box> }
      <TextField
        onChange={handleChange}
        name={name}
        type="number"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Icon fontSize="small"><img src={Pencil} /></Icon>
            </InputAdornment>
          ),
          inputProps: {
            max: 2019, min: 1700
          }
        }}
        fullWidth
        value={!isNil(defaultValue) ? defaultValue : ''}
        error={!isNil(error)}
        helperText={error}
      />
    </Box>
  )
}

const MemoizedComponent = React.memo(WholeNumberField);

export default MemoizedComponent;