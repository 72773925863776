// Use this hook inside the project provider
// or it will not work properly, because it 
// needs the states inside the project context

import React from 'react';
import isNil from 'lodash-es/isNil';
import { navigate } from '@reach/router';

import { useFetchRooms, useFetchRoom, useFetchNextPreviousRoom } from '../api/reflection';

import ProjectContext from '../contexts/projectContext';
import ApplicationContext from '../contexts/applicationContext';

const useReflectionHandler = () => {
  const { setToastOpen, setToastMessage, setToastColor } = React.useContext(ApplicationContext)

  const {
    currentProject,
    currentRoomConversationsAnswer,
    setCurrentRoomConversationsAnswer,
    currentRoom,
    setCurrentRoom,
    setRoomFetchAction,
    roomFetchAction,
    rooms
  } = React.useContext(ProjectContext);

  const [fetchRoom,
    { loading: loadingRoom, error: loadRoomError, data: roomData }
  ] = useFetchRoom(onRoomFetchSuccess, onFetchError);

  // Fetch rooms
  const [fetchRooms, {
    loading: loadingRooms,
    error: loadRoomsError,
    data: roomsData }
  ] = useFetchRooms(onFetchRoomsSuccess);

  const [fetchNextPreviousRoom, {
    loading: nextPreviousRoomLoading,
    error: nextPreviousRoomError,
    data: nextPreviousRoomData
  }] = useFetchNextPreviousRoom(onNextPreviousCompleted);

  function getRooms(projectId) {
    fetchRooms({ variables: { id: projectId } })
  }

  function getRoom(roomId) {
    fetchRoom({ variables: { id: currentProject.id, room_id: roomId } })
  }

  function getNextPreviousRoom(roomId, action) {
    fetchNextPreviousRoom({ variables: { id: currentProject.id, room_id: roomId, key: action } });
  }

  function onFetchRoomsSuccess(response) {
  }

  // This will replace the current history and replace room ID in URL
  function onNextPreviousCompleted(response) {
    if (!isNil(response.nextPreviousRoom)) {
      const room = response.nextPreviousRoom;
      setCurrentRoom({
        ...room,
        unopenedConversations: room.unopenedConversations.map((c) => c.id),
        unopenedSuggestions: room.unopenedSuggestions.map((s) => s.id)
      });
      setRoomFetchAction(undefined);
      const answers = buildRoomAnswers(room);
      setCurrentRoomConversationsAnswer(answers);
      navigate(`/project/${currentProject.slug}/reflection/rooms/${room.id}`, { state: { roomId: room.id }, replace: true });
    }
  }

  // For now use thi single method to show toast
  // error when an api call fails
  function onFetchError() {
    setToastColor('error')
    setToastMessage('Ooops! Something went wrong. Failed to fetch data')
    setToastOpen(true)
  }

  function onRoomFetchSuccess(response) {
    setRoomFetchAction(undefined);
    if (!isNil(response)) {
      const room = response.fetchRoom;
      setCurrentRoom({
        ...room,
        unopenedConversations: room.unopenedConversations.map((c) => c.id),
        unopenedSuggestions: room.unopenedSuggestions.map((s) => s.id)
      });
      const answers = buildRoomAnswers(response.fetchRoom);
      setCurrentRoomConversationsAnswer(answers);
    }
  }

  function buildConversationAnswers(conversation) {
    let questions = {};
    
    // this is just a monkey patch to handle old projects
    const handleOldProjectKeywords = (question) => {
      let answer;
      try {
        answer = JSON.parse(question.answer).arr;
      } catch(_e) {
        answer = [{content: question.answer, created_at: 'First Convo'}]
      }

      return answer;
    }

    conversation.questions.forEach((question) => {
      switch (question.questionType) {
        case 'Tag Selection':
        case 'Check box':
        case 'Multi Select Dropdown':
        case 'Keywords':
          questions[question.id] = !isNil(question.answer) ? handleOldProjectKeywords(question) : [];
          break;
        case 'Rank Questions: Dropdown + Input Field':
        case 'Ranking':
        case 'Nested Checkbox with Duration Field':
        case 'Nested Rating with Duration Field':
        case 'Dynamic Dropdown':
          questions[question.id] = !isNil(question.answer) ? JSON.parse(question.answer) : {};
          break;
        default:
          questions[question.id] = question.answer;
      }
    });
    return questions;
  }

  // This will be called after a successful segment fetch
  // Build conversations answers quickly so we can populate them
  // correctly in conversation forms.
  function buildRoomAnswers(room) {
    let conversations = {};

    room.conversations.forEach((conversation) => {
      conversations[conversation.id] = buildConversationAnswers(conversation);
    });

    return conversations;
  }

  function buildAnswersPayload(formValues, conversation) {
    const questions = Object.keys(formValues);
    let answers = [];

    const shouldStringifyAnswer = (answer) => {
      return answer instanceof Array || answer instanceof Object;
    }

    const stringify = (answer) => {
      if (answer instanceof Array) {
        return JSON.stringify({ arr: answer });
      } else if (answer instanceof Object) {
        return JSON.stringify(answer);
      }
    }

    // Loop on questions
    // question will be the question id here
    questions.forEach((question) => {
      const questionAnswer = formValues[question];
      const answer = {
        conversation_id: conversation.id.toString(),
        question_id: question.toString(),
        answer: shouldStringifyAnswer(questionAnswer) ? stringify(questionAnswer) : questionAnswer
      }
      answers = [...answers, answer]
    });
    return answers;
  }

  function updateCurrentRoomConversationsAnswers(conversationId, answers) {
    const updatedAnswers = currentRoomConversationsAnswer;
    updatedAnswers[conversationId] = answers;
    setCurrentRoomConversationsAnswer(updatedAnswers);
  }

  // This returns the room answers set on segment fetch
  function buildConversationFormDefaultStates(conversationId) {
    return currentRoomConversationsAnswer[conversationId];
  }

  function sortAnswerOptions(options) {
    return options.slice().sort((a, b) => a.displayOrder - b.displayOrder)
  }

  return {
    updateCurrentRoomConversationsAnswers,
    buildAnswersPayload,
    currentProject,
    getRooms,
    buildConversationFormDefaultStates,
    loadingRooms,
    loadRoomsError,
    roomsData,
    sortAnswerOptions,
    currentRoom,
    setCurrentRoom,
    getRoom,
    loadingRoom,
    loadRoomError,
    roomData,
    currentRoomConversationsAnswer,
    rooms,
    getNextPreviousRoom,
    nextPreviousRoomLoading,
    nextPreviousRoomError,
    nextPreviousRoomData
  }
}

export default useReflectionHandler;