import { gql, useLazyQuery, useMutation } from '@apollo/client';

export const FETCH_REFLECTION = gql`
  query reflectionView($slug: String!) {
    reflectionView(slug: $slug){
      reflectionPoints {
        id
        description
        name
        bgColor
        subtitle
        iconName
        fontColor
        subSegmentTitle
        isFirst
        isLast
        steps {
          id
          title
          nextButtonColor
          previousButtonColor
          displayOrder
          questions {
            description
            displayOrder
            id
            questionTypeId
            status
            title
            answer
            questionType
            placeholder
            answerOptions{
              id
              title
              value
              displayOrder
              description
              status
              identifier
            }
          }
        }
      }
      reflectionDetails
    }
  }
`;

export function useFetchReflection() {
  return useLazyQuery(FETCH_REFLECTION, { errorPolicy: 'all', fetchPolicy: 'no-cache' });
}

export const FETCH_BACK_TO_FUTURE = gql`
  query backToFuture($id: ID!) {
    backToFuture(id: $id){
      backToFuturePoints {
        id
        description
        name
        bgColor
        subtitle
        iconName
        fontColor
        subSegmentTitle
        isFirst
        isLast
        steps {
          id
          title
          nextButtonColor
          previousButtonColor
          displayOrder
          questions {
            description
            displayOrder
            id
            questionTypeId
            status
            title
            answer
            questionType
            placeholder
            logicalExpression
            answerOptions{
              id
              title
              value
              displayOrder
              description
              status
              identifier
            }
          }
        }
      }
      backToFutureQuestion {
        description
        displayOrder
        id
        questionTypeId
        status
        title
        answer
        questionType
        placeholder
        stepId
        segmentId
        logicalExpression
        answerOptions{
          id
          title
          value
          displayOrder
          description
          status
          identifier
        }
      }
      backToFutureDetails
    }
  }
`;

export function useFetchBackToFuture() {
  return useLazyQuery(FETCH_BACK_TO_FUTURE, { errorPolicy: 'all', fetchPolicy: 'no-cache' });
}

export const FETCH_ROOMS = gql`
  query fetchRooms($id: ID!) {
    fetchRooms(id: $id){
      id
      title
      isFirst
      isLast
      description
      displayOrder
      title
      priorityScore
      happinessScore
      importanceScore
      happinessRating
      duration
      status
      backgroundImageUrl
      unopenedConversations {
        id
      }
      unopenedSuggestions {
        id
      }
    }
  }
`

export function useFetchRooms(onCompleted) {
  return useLazyQuery(FETCH_ROOMS, { errorPolicy: 'all', fetchPolicy: 'no-cache', onCompleted: onCompleted });
}

export const FETCH_ROOM = gql`
  query fetchRoom($id: ID!, $room_id: ID!) {
    fetchRoom(id: $id, roomId: $room_id){
      id
      title
      isFirst
      isLast
      description
      displayOrder
      title
      priorityScore
      happinessScore
      importanceScore
      happinessRating
      duration
      status
      unopenedConversations {
        id
      }
      unopenedSuggestions {
        id
      }
      roomsCollection {
        id
        title
      }
      conversations {
        id
        title
        description
        questions {
          description
          displayOrder
          conversationDisplayOrder
          id
          questionTypeId
          status
          title
          answer
          questionType
          placeholder
          logicalExpression
          answerOptions{
            id
            title
            value
            displayOrder
            description
            status
            identifier
          }
        }
      }
      wishlists {
        id
        title
        recommendations
        challenges
        lessons
        mistakes
        includedRooms
      }
      giftbox {
        id
        title
        suggestions {
          id
          link
          description
        }
      }
    }
  }
`

export function useFetchRoom(onCompleted) {
  return useLazyQuery(FETCH_ROOM, { errorPolicy: 'all', fetchPolicy: 'no-cache', onCompleted: onCompleted });
}

export const FETCH_NEXT_PREVIOUS_ROOM = gql`
  query nextPreviousRoom($id: ID!, $room_id: ID!, $key: String!) {
    nextPreviousRoom(id: $id, roomId: $room_id, key: $key){
      id
      title
      isFirst
      isLast
      description
      displayOrder
      title
      priorityScore
      happinessScore
      importanceScore
      happinessRating
      duration
      status
      unopenedConversations {
        id
      }
      unopenedSuggestions {
        id
      }
      roomsCollection {
        id
        title
      }
      conversations {
        id
        title
        description
        questions {
          description
          displayOrder
          conversationDisplayOrder
          id
          questionTypeId
          status
          title
          answer
          questionType
          placeholder
          logicalExpression
          answerOptions{
            id
            title
            value
            displayOrder
            description
            status
            identifier
          }
        }
      }
      wishlists {
        id
        title
        recommendations
        challenges
        lessons
        mistakes
        includedRooms
      }
      giftbox {
        id
        title
        suggestions {
          id
          link
          description
        }
      }
    }
  }
`

export function useFetchNextPreviousRoom(onCompleted = () => null) {
  return useLazyQuery(FETCH_NEXT_PREVIOUS_ROOM, { errorPolicy: 'all', fetchPolicy: 'no-cache', onCompleted: onCompleted });
}

export const SUBMIT_WISHLIST = gql`
  mutation CreateWishlistMutation($project_id: ID!, $title: String, $recommendations: String, $challenges: String, $lessons: String, $mistakes: String, $included_rooms: [String!]){
    createWishlist(input: {params: {projectId: $project_id, title: $title, recommendations: $recommendations, challenges: $challenges, lessons: $lessons, mistakes: $mistakes, includedRooms: $included_rooms} }) {
      wishlist {
        id
        title
        recommendations
        challenges
        lessons
        mistakes
        includedRooms
      }
      success
      errors
    }
  }
`

export function useSubmitWishlist(onCompleted, onError) {
  return useMutation(SUBMIT_WISHLIST, { errorPolicy: 'all', fetchPolicy: 'no-cache', onCompleted: onCompleted, onError: onError });
}

export const UPDATE_WISHLIST = gql`
  mutation UpdateWishlistMutation($project_id: ID!, $id: ID!, $title: String, $recommendations: String, $challenges: String, $lessons: String, $mistakes: String, $included_rooms: [String!]){
    updateWishlist(input: {params: {projectId: $project_id, id: $id, title: $title, recommendations: $recommendations, challenges: $challenges, lessons: $lessons, mistakes: $mistakes, includedRooms: $included_rooms} }) {
      wishlist {
        id
        title
        recommendations
        challenges
        lessons
        mistakes
        includedRooms
      }
      success
      errors
    }
  }
`

export function useUpdateWishlist(onCompleted, onError) {
  return useMutation(UPDATE_WISHLIST, { errorPolicy: 'all', fetchPolicy: 'no-cache', onCompleted: onCompleted, onError: onError });
}

export const MARK_CONVERSATION_AS_OPENED = gql`
  mutation MarkConversationAsOpened($id: ID!, $conversation_id: ID!){
    markConversationAsOpened(input: {params: {id: $id, conversationId: $conversation_id} }) {
      success
      errors
    }
  }
`

export function useMarkConversationAsOpened(onCompleted, onError) {
  return useMutation(MARK_CONVERSATION_AS_OPENED, { errorPolicy: 'all', fetchPolicy: 'no-cache', onCompleted: onCompleted, onError: onError });
}

export const MARK_SUGGESTION_AS_CLICKED = gql`
  mutation MarkSuggestionAsOpened($id: ID!, $suggestion_id: ID!){
    markSuggestionAsOpened(input: {params: {id: $id, suggestionId: $suggestion_id} }) {
      success
      errors
    }
  }
`

export function useMarkSuggestionAsClicked(onCompleted, onError) {
  return useMutation(MARK_SUGGESTION_AS_CLICKED, { errorPolicy: 'all', fetchPolicy: 'no-cache', onCompleted: onCompleted, onError: onError });
}

export const UPDATE_PROJECT_ROOMS_ORDERING = gql`
  mutation updateProject($id: ID!, $room_ordering: [String!]){
    updateProject(input: {params: {id: $id, roomOrdering: $room_ordering} }) {
      success
      errors
    }
  }
`

export function useUpdateProjectRoomsOrdering(onCompleted, onError) {
  return useMutation(UPDATE_PROJECT_ROOMS_ORDERING, { errorPolicy: 'all', fetchPolicy: 'no-cache', onCompleted: onCompleted, onError: onError });
}

export const FETCH_CAROUSEL_SLIDES = gql`
  query FetchCarouselSlides {
    fetchCarouselSlides {
      id
      content
      displayOrder
      link
      linkTitle
      backgroundImageUrl
    }
  }
`;

export function useFetchCarouselCards() {
  return useLazyQuery(FETCH_CAROUSEL_SLIDES, { errorPolicy: 'all', fetchPolicy: 'no-cache' });
}

export const SUBMIT_ANSWER = gql`
  mutation SubmitAnswer($projectId: ID!, $answer: JSON!) {
    submitAnswer(input: { params: { id: $projectId, answer: $answer }}) {
      success
      errors
      room {
        id
        priorityScore
        happinessScore
        importanceScore
        happinessRating
        duration
      }
    }
  }
`;

export function useSubmitAnswer(onCompleted, onError) {
  return useMutation(SUBMIT_ANSWER, { errorPolicy: 'all', onCompleted: onCompleted, onError: onError });
}