import React from 'react';
import Routes from '../routes/Index';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '../../javascript/styles/theme';

import ApolloProvider from '../providers/ApolloProvider';
import ApplicationProvider from '../providers/ApplicationProvider';
import ProjectProvider from '../providers/ProjectProvider';

const App = () => {
  return (
    <ApolloProvider>
      <ApplicationProvider>
        <ThemeProvider theme={theme}>
          <ProjectProvider>
            <CssBaseline />
            <Routes />
          </ProjectProvider>
        </ThemeProvider>
      </ApplicationProvider>
    </ApolloProvider>
  )
}

export default App;