import React from 'react'
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Icon,
  Toolbar,
  Typography
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import isNil from 'lodash-es/isNil';
import { navigate, useParams } from '@reach/router';

import { useFetchReflection } from '../../api/reflection';
import HamburgerIcon from 'icons/hamburger.svg';
import ArrowUpIcon from 'icons/arrow-up.svg';
import ArrowDownIcon from 'icons/arrow-down.svg';
import CloseIcon from 'icons/close-outline.svg';
import ThoughtIcon from 'icons/thought-balloon.svg';
import EyeIcon from 'icons/eye-white.svg';
import GraphIcon from 'icons/graph.svg';
import HoursIcon from 'icons/24-hours.svg';
import Fade from '@material-ui/core/Fade';

import TimeForwardIcon from 'icons/time-forward-white.svg';
import { useStyles } from '../../styles/reflection';
import Carousel from './Carousel';

import useToast from '../../hooks/useToasti';
import DataLoader from '../common/DataLoader';
import WeeklyProgress from './WeeklyProgress';
import ActivityProgress from './ActivityProgress';
import ReflectionPointsForm from './ReflectionPointsForm';
import ProjectContext from '../../contexts/projectContext';
import BurgerMenu from "../common/BurgerMenu";

const ReflectionView = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = React.useState('time');
  const [sortingDirection, setSortingDirection] = React.useState('desc');
  const [float, setFloat] = React.useState(false);
  const parentDivRef = React.useRef();
  const { renderToast } = useToast();
  const { projectSlug } = useParams();
  const { currentProject } = React.useContext(ProjectContext);

  const [fetchReflection, {
    data,
    loading,
  }] = useFetchReflection();
  const timeOrHappyRef = React.useRef();
  const weeklyRef = React.useRef();
  const carouselRef = React.useRef();
  const reflectionPointsRef = React.useRef();

  // Fetch Reflection Points
  React.useEffect(() => {
    fetchReflection({ variables: { slug: projectSlug } })
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const scroll = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const upperFirstName = currentProject?.firstName.toUpperCase()

  const renderWeeklyProgress = () => {
    if (isNil(data) || isNil(data.reflectionView.reflectionDetails)) return null;

    return (
      <>
        {data.reflectionView.reflectionDetails.reflection_answers.map((segmentReflection) => {
          return <WeeklyProgress key={segmentReflection.segment_name} segmentReflection={segmentReflection} />
        })}
      </>
    )
  }

  const onNext = () => {
    navigate(`/project/${currentProject.slug}/back-to-future`)
  }

  const renderCustomSave = (save, disabled) => {
    const onClick = () => {
      save();
    }

    return (
      <Button variant="contained" color="primary" onClick={onClick} disabled={disabled}>
        Next
      </Button>
    )
  }

  const renderReflection = () => {
    if (isNil(data) || isNil(data.reflectionView.reflectionPoints)) return null;

    return <ReflectionPointsForm
      segment={data.reflectionView.reflectionPoints}
      fname={upperFirstName}
      customSave={renderCustomSave}
      onSubmitSuccessCallback={onNext}
    />
  };

  const renderActivityProgress = () => {
    const sortSegmentReflections = () => {
      if (isNil(data) || isNil(data.reflectionView.reflectionDetails)) return [];

      const reflection_answers = [...data.reflectionView.reflectionDetails.reflection_answers];

      if (category === 'time') {
        if (sortingDirection === 'desc') {
          return reflection_answers.sort((a, b) => b.hours_number - a.hours_number)
        } else {
          return reflection_answers.sort((a, b) => a.hours_number - b.hours_number)
        }
      }

      if (category === 'happiness') {
        if (sortingDirection === 'desc') {
          return reflection_answers.sort((a, b) => b.impact - a.impact)
        } else {
          return reflection_answers.sort((a, b) => a.impact - b.impact)
        }
      }
    }

    return (
      <>
        {sortSegmentReflections().map((segmentReflection) => {
          return <ActivityProgress key={segmentReflection.segment_name} segmentReflection={segmentReflection} />
        })}
      </>
    )
  }

  const renderCarouselBoxes = () => {
    if (isNil(data) || isNil(data.reflectionView.reflectionDetails)) return null;
    return (
      <>
        {data.reflectionView.reflectionDetails.my_words_boxes.map((answerData, index) => {
          return (
            <Box key={`${answerData[0].title}-${index}`} mb="24px" style={{ marginLeft: '-16px' }}>
              <Carousel answerData={answerData} />
            </Box>
          )
        })}
      </>
    )
  }

  const handleSortingDirectionClick = () => {
    if (sortingDirection === 'asc') {
      setSortingDirection('desc');
    } else {
      setSortingDirection('asc');
    }
  }

  const renderSortingArrows = () => {
    return <img onClick={handleSortingDirectionClick} src={sortingDirection === 'desc' ? ArrowDownIcon : ArrowUpIcon} />
  }

  const renderContent = () => {
    return (
      <Grid id container direction="column" style={{ backgroundColor: '#fff' }}>
        <Grid ref={weeklyRef} item style={{ marginTop: '104px', position: 'relative', marginBottom: '24px' }}>
          <Box mb="34px" className="bold" style={{ fontSize: '18px', lineHeight: '24px', textAlign: 'center' }}>{`${upperFirstName}’S 360 VIEW`}</Box>
          <Box id="weekly">This is how I spend my time  out of</Box>
          <Box>168 hours weekly</Box>
        </Grid>

        <Grid item container>
          {renderWeeklyProgress()}
        </Grid>

        <Grid ref={timeOrHappyRef} item>
          <Box mt="12px" mb="24px">
            <Box>Here's a closer view of how happy</Box>
            <Box>or unhappy I am with each activity </Box>
          </Box>
        </Grid>

        <Grid item container style={{ marginBottom: '32px' }}>
          <Grid container>
            <Grid item xs={9}>
              <Box>
                <Button onClick={() => setCategory('time')} className={category === 'time' ? clsx(classes.buttonActive) : clsx(classes.buttonInactive)} size="medium"
                  style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }}>Time</Button>
                <Button onClick={() => setCategory('happiness')} className={category === 'happiness' ? clsx(classes.buttonActive) : clsx(classes.buttonInactive)} size="medium"
                  style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}>Happy</Button>
              </Box>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'center' }}>
              {renderSortingArrows()}
            </Grid>
          </Grid>
        </Grid>

        <Grid item container>
          {renderActivityProgress()}
        </Grid>

        <Grid ref={carouselRef} item>
          <Box my="32px" style={{ textAlign: 'center' }}>And here's the view in my own words</Box>
        </Grid>

        <Grid item container style={{ overflow: 'scroll' }}>
          {renderCarouselBoxes()}
        </Grid>

        <Grid ref={reflectionPointsRef} item container>
          {renderReflection()}
        </Grid>
      </Grid>
    )
  }

  if (loading) return <DataLoader />

  return (
    <Fade in={true} unmountOnExit>
      <div ref={parentDivRef} className={clsx('page-container', classes.root)}>
        <Container disableGutters={true} maxWidth="sm">
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
            color="default"
            style={{ background: `#fff`, padding: `24px 16px 0 16px`, borderBottom: `1px solid #595959` }}
          >
            <Toolbar disableGutters={true} style={{ justifyContent: 'space-between' }}>
              <Box>
                <Typography variant="h6" className={clsx('lora')} color="primary">
                  The Empathy Project
                </Typography>
              </Box>
              <Box><Icon fontSize="large" onClick={handleDrawerOpen}><img src={HamburgerIcon} /></Icon></Box>
            </Toolbar>
          </AppBar>
          <BurgerMenu
            open={open}
            setOpen={setOpen}
            currentProject={currentProject}
          />
          <Box className={clsx(classes.viewContent)} p="16px">
            {renderToast()}
            {float && <Box className={clsx(classes.pointsButtonWrapper)}>
              <Grid container direction="column">
                <Grid item>
                  <Box onClick={() => scroll(weeklyRef)} mb="12px" className={clsx(classes.pointsButtonContainer)} style={{ background: '#01CDC2' }}>
                    <img src={HoursIcon} />
                  </Box>
                </Grid>
                <Grid item>
                  <Box onClick={() => scroll(timeOrHappyRef)} mb="12px" className={clsx(classes.pointsButtonContainer)} style={{ background: '#B08EB1' }}>
                    <img src={GraphIcon} />
                  </Box>
                </Grid>
                <Grid item>
                  <Box onClick={() => scroll(carouselRef)} mb="12px" className={clsx(classes.pointsButtonContainer)} style={{ background: '#DD3874' }}>
                    <img src={EyeIcon} />
                  </Box>
                </Grid>
                <Grid item>
                  <Box onClick={() => scroll(reflectionPointsRef)} mb="12px" className={clsx(classes.pointsButtonContainer)} style={{ background: '#DD3874' }}>
                    <img src={ThoughtIcon} />
                  </Box>
                </Grid>
                <Grid item>
                  <Box mb="12px" className={clsx(classes.pointsButtonContainer)} style={{ background: '#595959' }}>
                    <Button onClick={() => setFloat(false)}>
                      <img src={CloseIcon} />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>}

            {!float && <Box className={clsx(classes.pointsButtonWrapper)}>
              <Box mb="12px" className={clsx(classes.pointsButtonContainer)} style={{ background: '#595959' }}>
                <Button onClick={() => setFloat(true)}>
                  <img src={TimeForwardIcon} />
                </Button>
              </Box>
            </Box>}

            {renderContent()}
          </Box>
        </Container>
      </div>
    </Fade>
  )
}

export default ReflectionView;
