import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import isNil from 'lodash-es/isNil';

const DateSelector = (props) => {
  const { label, onChange, name, defaultValue, fontColor, description } = props;
  const [month, setMonth] = useState();
  const [day, setDay] = useState();
  const [year, setYear] = useState();

  useEffect(() => {
    if (!isNil(defaultValue)) {
      const dt = new Date(defaultValue);
      setMonth(dt.getMonth() + 1);
      setDay(dt.getDate());
      setYear(dt.getFullYear());
    }
  }, []);

  function inputHasValue(value) {
    return !isNil(value) && value !== '';
  }

  function handleMonthChange(event) {
    if (parseInt(event.target.value) > 12) return;
    setMonth(event.target.value);
    const val =  `${event.target.value}-${inputHasValue(day) ? day : '00'}-${inputHasValue(year) ? year : '0000'}`;
    onChange({ field: name, value: val })
  }

  function handleDayChange(event) {
    if (parseInt(event.target.value) > 31) return;
    setDay(event.target.value);
    const val =  `${inputHasValue(month) ? month : '00'}-${event.target.value}-${inputHasValue(year) ? year : '0000'}`;
    onChange({ field: name, value: val })
  }

  function handleYearChange(event) {
    if (parseInt(event.target.value) > 2021) return;
    setYear(event.target.value);
    const val =  `${inputHasValue(month) ? month : '00'}-${inputHasValue(day) ? day : '00'}-${event.target.value}`;
    onChange({ field: name, value: val })
  }

  return (
    <Box mb="24px">
      { label && <Box mb="24px" className="component-label" style={{ color: fontColor }}>{ReactHtmlParser(label)}</Box> }
      { description && <Box className="component-label-description" style={{ color: fontColor }}>{ReactHtmlParser(description)}</Box> }
      <Box>
        <Grid xs container direction="row">
          <Grid item container direction="column" xs={2}>
            <Grid item container alignItems="center">
              <Box className="bold" mb="5px" style={{ color: '#595959', marginLeft: '3px' }}>MM</Box>
            </Grid>
            <Grid item container justify="space-between" alignItems="flex-end">
              <Grid item>
                <TextField
                  style={{ width: '30px', fontSize: '10px' }}
                  placeholder="MM"
                  onChange={handleMonthChange}
                  value={month}
                  type="number"
                />
              </Grid>
              <Grid item>
                <Box className="bold" 
                  style={{ fontSize: '20px', marginRight: '7px', marginBottom: '5px' }}>/</Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={2}>
            <Grid item container alignItems="center">
              <Box className="bold" mb="5px" style={{ color: '#595959', marginLeft: '3px' }}>DD</Box>
            </Grid>
            <Grid item container justify="space-between" alignItems="flex-end">
              <Grid item>
                <TextField
                  style={{ width: '30px', fontSize: '10px' }}
                  placeholder="DD"
                  onChange={handleDayChange}
                  value={day}
                  type="number"
                />
              </Grid>
              <Grid item>
                <Box className="bold" 
                  style={{ fontSize: '20px', marginRight: '7px', marginBottom: '5px' }}>/</Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={2}>
            <Grid item container alignItems="center">
              <Box className="bold" mb="5px" style={{ color: '#595959', marginLeft: '3px' }}>YYYY</Box>
            </Grid>
            <Grid item>
              <TextField
                style={{ width: '50px', fontSize: '10px' }}
                placeholder="YYYY"
                onChange={handleYearChange}
                value={year}
                type="number"
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

const MemoizedComponent = React.memo(DateSelector);

export default MemoizedComponent;