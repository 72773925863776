import React from 'react';
import {
  Box,
  Button,
  Grid,
} from '@material-ui/core';
import ChevronRight from 'icons/chevron-right.svg';
import PlusIcon from 'icons/plus.png';

const WishList = ({ wishLists, onAddClick, onWishListClick }) => {

  function renderWishlists() {
    return wishLists.map((wishList, index) => {
      return (
        <Box 
          py="16px" key={wishList.id}
          style={{ borderBottom: '1px solid #595959' }}
          onClick={() => onWishListClick(index)}
        >
          <Grid container xs justify="space-between">
            <Grid item container xs={10} alignItems="center">
              <Grid item>{wishList.title}</Grid>
            </Grid>
            <Grid item container xs={2} justify="flex-end">
              <img src={ChevronRight} height="16px" style={{ marginRight: '5px' }} />
            </Grid>
          </Grid>
        </Box>
      )
    })
  }

  return (
    <>
      {renderWishlists()}
      <Box mb="24px">
        <Button size="small"
          style={{ 
            background: '#276EF1',
            borderRadius: '20px',
            color: '#fff',
            width: 'auto',
            padding: '5px 10px',
            marginTop: '20px'
          }}
          onClick={onAddClick}
        >
          <img src={PlusIcon} style={{ marginRight: '5px' }} />
          Add Wishlist
        </Button>
      </Box>
    </>
  )
}

export default WishList;
