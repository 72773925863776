import React from 'react';
import { Box, Container, Grid } from '@material-ui/core'
import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';

const SegmentLoader = () => {
  return (
    <Fade in={true} unmountOnExit>
      <Container disableGutters={true} maxWidth="sm">
        <Box style={{ backgroundColor: '#fff' }}>
          <Grid container className={ clsx('full-height') } component="div" direction="column" alignItems="center" justify="center">
            <Box mb="24px" className={ clsx('segment-heading') }>
              Loading Data
            </Box>
          </Grid>
        </Box>
      </Container>
    </Fade>
  )
}

export default SegmentLoader;