import { useState } from 'react';

const useProjectContextUpdater = () => {
  const [currentProject, setCurrentProject] = useState();
  const [currentSegment, setCurrentSegment] = useState();
  const [currentStepIndex, setCurrentStepIndex] = useState();
  const [currentStepId, setCurrentStepId] = useState();
  const [currentSegmentAnswers, setCurrentSegmentAnswers] = useState();
  const [currentRoomConversationsAnswer, setCurrentRoomConversationsAnswer] = useState();
  const [roomFetchAction, setRoomFetchAction] = useState();
  const [currentRoom, setCurrentRoom] = useState();
  const [rooms, setRooms] = useState();

  return {
    currentProject,
    currentSegment,
    currentStepId,
    setCurrentSegment,
    setCurrentStepId,
    currentStepIndex,
    setCurrentStepIndex,
    setCurrentProject,
    currentSegmentAnswers,
    setCurrentSegmentAnswers,
    roomFetchAction,
    setRoomFetchAction,
    currentRoomConversationsAnswer,
    setCurrentRoomConversationsAnswer,
    currentRoom,
    setCurrentRoom,
    rooms,
    setRooms
  }
}

export default useProjectContextUpdater;