import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Box, Grid } from '@material-ui/core';
import { navigate } from '@reach/router';
import PinIcon from 'icons/push-pin.png';
import SemiCircleProgressBar from 'react-progressbar-semicircle';
import isNil from 'lodash-es/isNil';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { useStyles } from '../../styles/reflectionDashboard';
import { useUpdateProjectRoomsOrdering } from '../../api/reflection';
import useReflectionHandler from '../../hooks/useReflectionHandler';

const RoomsCards = ({ currentProject }) => {
  const classes = useStyles();
  const { id: projectId, slug } = currentProject;
  const [rooms, setRooms] = useState();

  const { getRooms, loadingRooms, loadRoomsError, roomsData } = useReflectionHandler();
  const [updateProject, {}] = useUpdateProjectRoomsOrdering();

  useEffect(() => {
    getRooms(projectId);
  }, []);

  useEffect(() => {
    if (!isNil(roomsData)) {
      setRooms(roomsData.fetchRooms)
    }
  }, [roomsData])

  function tryAgainOnClick(event) {
    event.preventDefault();
    getRooms(projectId);
  }

  function onEnterClick(event, room) {
    event.preventDefault();
    navigate(`/project/${slug}/reflection/rooms/${room.id}`);
  }

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "none",
    padding: 4
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "none",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    const newState = [...rooms];
    const b = newState[dInd];
    newState[dInd] = newState[sInd];
    newState[sInd] = b;
    setRooms(newState);

    const roomIds = newState.map((room) => room.id);
    updateProject({ variables: { id: currentProject.id, room_ordering: roomIds } })
  }

  if (loadingRooms || isNil(rooms) && isNil(loadRoomsError)) {
    return (
      <div className={clsx(classes.roomsLoaderContainer)}>
        <Box px="50px" my="20px" style={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      </div>
    );
  }

  if (!isNil(loadRoomsError)) {
    return (
      <div className={clsx(classes.roomsErrorContainer)}>
        <Box px="50px" my="20px" style={{ textAlign: 'center' }}>
          <p>Ooops! An error occured. Try again by clicking try again.</p>

          <a href="#" onClick={tryAgainOnClick} className={clsx(classes.tryAgainLink)}>Try again</a>
        </Box>
      </div>
    )
  }

  function renderCards() {
    const roomNotif = (room) => {
      const count = room.unopenedConversations.length + room.unopenedSuggestions.length
      if (count === 0) return null;

      return (
        <Box mb="6px" className={clsx(classes.notifContainer)}>
          <Box className={clsx(classes.notif)}>{count}</Box>
        </Box>
      )
    }

    return rooms.map((room, index) => {

      if (index === 0) {
        return (<Grid
          item xs={4} key={room.title}
          component="div">
          <Box className={clsx(classes.roomCard)}
            style={{ backgroundColor: '#444', backgroundImage: `url(${room.backgroundImageUrl})`, backgroundSize: 'cover' }}>

            <Grid container style={{ height: '40px', display: 'flex' }}>
              <div className={clsx(classes.cardName)}>{room.title}</div>
              {/* TODO: FIX THIS */}
              {index === 0 && (
                <div className={clsx(classes.pin)}><img src={PinIcon} /></div>
              )}
            </Grid>
            <Box className={clsx(classes.percentageContainer)}>
              <SemiCircleProgressBar percentage={100} showPercentValue diameter={90} strokeWidth={4} />
            </Box>
            <Box className={clsx(classes.score)}>Priority Score</Box>
            <Box mt="20px">
              {roomNotif(room)}
              <Box className={clsx(classes.notifContainer)}>
                <a
                  href="#"
                  onClick={(event) => onEnterClick(event, room)}
                  className={clsx(classes.enterLink)}>
                  enter
                </a>
              </Box>
            </Box>

          </Box>
        </Grid>);
      } else {
        return (
          <Droppable key={room.id} droppableId={index.toString()}>
            {(provided, snapshot) => (
              <Grid
                item xs={4} key={room.title}
                component="div"
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}>
                <Draggable
                  key={room.id}
                  draggableId={room.id.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      index={index}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Box className={clsx(classes.roomCard)}
                        style={{ backgroundColor: '#444', backgroundImage: `url(${room.backgroundImageUrl})`, backgroundSize: 'cover' }}>

                        <Grid container style={{ height: '40px', display: 'flex' }}>
                          <div className={clsx(classes.cardName)}>{room.title}</div>
                          {/* TODO: FIX THIS */}
                          {index === 0 && (
                            <div className={clsx(classes.pin)}><img src={PinIcon} /></div>
                          )}
                        </Grid>
                        <Box className={clsx(classes.percentageContainer)}>
                          <SemiCircleProgressBar percentage={Math.round(room.priorityScore, 2)} showPercentValue diameter={90} strokeWidth={4} />
                        </Box>
                        <Box className={clsx(classes.score)}>Priority Score</Box>
                        <Box mt="20px">
                          {roomNotif(room)}
                          <Box className={clsx(classes.notifContainer)}>
                            <a
                              href="#"
                              onClick={(event) => onEnterClick(event, room)}
                              className={clsx(classes.enterLink)}>
                              enter
                            </a>
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  )}
                </Draggable>
              </Grid>
            )}
          </Droppable>
        )
      }
    })
  }

  return (
    <Box component="div" px="16px" style={{ width: '100%' }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container spacing={1}>
          {renderCards()}
        </Grid>
      </DragDropContext>
    </Box>
  )
}

export default RoomsCards;