import React from 'react';

import IntakerMain from '../intaker/Intaker';
import WithProjectTemplate from '../common/WithProjectTemplate'

const Intaker = () => {
  return (
    <div className="page-container">
      <WithProjectTemplate>
        <IntakerMain />
      </WithProjectTemplate>
    </div>
  )
}

export default Intaker;