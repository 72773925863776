import React from 'react'
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Icon,
  Toolbar,
  Typography
} from '@material-ui/core'
import clsx from 'clsx';

import { useStyles } from '../../styles/reflection';

import HamburgerIcon from 'icons/hamburger.svg';
import RibbonIcon from 'icons/ribbon.svg';

import {navigate, Link as HyperLink} from "@reach/router";
import ProjectContext from '../../contexts/projectContext';
import BurgerMenu from '../common/BurgerMenu';

const ReflectionCoverLetter = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { currentProject } = React.useContext(ProjectContext);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const onNext = () => {
    navigate(`/project/${currentProject.slug}/reflection/letter-to-myself`)
  }

  return (
    <div className={clsx('page-container', classes.root)}>
      <Container disableGutters={true} maxWidth="sm">
        
        <Box className={clsx(classes.reflectionCoverContent)} p="16px">
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <AppBar
                position="static"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
                color="default"
              >
                <Toolbar disableGutters={true} style={{ justifyContent: 'space-between' }}>
                  <Box>
                    <Typography variant="h6" className={clsx('lora')} color="primary">
                      The Empathy Project
                    </Typography>
                  </Box>
                  <Box><Icon fontSize="large" onClick={handleDrawerOpen}><img src={HamburgerIcon} /></Icon></Box>
                </Toolbar>
            </AppBar>
            <BurgerMenu
              open={open}
              setOpen={setOpen}
              currentProject={currentProject}
            />
            </Grid>
            <Grid item>
              <Box className={clsx(classes.coverContainer, classes.coverLetter)}>
                <Box className={clsx(classes.ribbonContainer)}>
                  <img src={RibbonIcon} />
                </Box>
                <Box mb="24px" className="italic">
                  { `Dear ${currentProject?.firstName},` }
                </Box>
                <Box mb="24px">
                  Thank you for taking part in our Empathy Project.                   
                </Box>
                <Box mb="24px">
                  We’re excited to share your Reflection Sheets, broken down into 3 parts:                  
                </Box>
                <Box mb="24px">
                  <ul className={clsx(classes.parts)}>
                    <li><HyperLink to={`/project/${currentProject?.slug}/reflection/view`}>{`${currentProject?.firstName}’s 360 View`}</HyperLink></li>
                    <li><HyperLink to={`/project/${currentProject?.slug}/back-to-future`}>{`${currentProject?.firstName}’s Back To Future`}</HyperLink></li>
                    <li><HyperLink to={`/project/${currentProject?.slug}/reflection/gift-box`}>{`${currentProject?.firstName}’s Giftbox`}</HyperLink></li>
                  </ul>
                </Box>
                <Box mb="24px">
                  Our goal in every Empathy Project is to clarify the most actionable and powerful opportunities for growth - for you.  
                </Box>
                <Box mb="24px">
                  Here they are. They were always in you.
                </Box>
                <Box className="bold">
                  The Empathy Project Team
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box mb="34px">
                <Button variant="contained" color="primary" onClick={onNext}>
                  Next
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  )
}

export default ReflectionCoverLetter;
