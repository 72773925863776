import React from 'react';
import BackToFuture from "../reflection/BackToFuture";
import WithProjectTemplate from "../common/WithProjectTemplate";

const BackToFuturePage = () => {
  return (
    <WithProjectTemplate>
      <BackToFuture />
    </WithProjectTemplate>
  )
}

export default BackToFuturePage;