import React, { useEffect } from 'react';
import isNil from 'lodash-es/isNil';
import { Box, Button, TextField } from '@material-ui/core'
import clsx from 'clsx';
import { useStyles } from '../../styles/signup';
import { navigate } from '@reach/router';

import { authenticate } from '../../utils/auth';
import { buildValidationSchema } from '../../validations/signup';
import { useRegisterUser } from '../../api/user';
import CustomDialog from '../dialog/CustomDialog';
import useForm from '../../hooks/useForm';

const SignupForm = ({ open, onClose, projectId, inviteeEmail }) => {
  const defaultState = {
    first_name: '',
    last_name: '',
    email: inviteeEmail || '',
    password: '',
    confirm_password: ''
  }

  const classes = useStyles();
  const [registerUser, { error, loading }] = useRegisterUser(onCompleted);

  function submit() {
    registerUser({ variables: { ...values, project_id: projectId } })
  }

  function onCompleted(response) {
    if (isNil(response) || isNil(response.registerUser)) return;

    if (!isNil(response.registerUser.user)) {
      const user = response.registerUser.user;
      authenticate(user.authenticationToken, user);
      if(inviteeEmail){
        navigate(`/project/${projectId}/reflection/dashboard`)
      } else {
        navigate('/');
      }
      onClose();
    }

    if (!isNil(response.registerUser.errors)) {
      setErrors(response.registerUser.errors);
    }
  }

  function renderError() {
    if (!isNil(error)) {
      return (
        <div className={clsx(classes.errorContainer)}>
          Oops! Something went wrong. Might be a network error.
        </div>
      )
    }

    return null;
  }

  function handleInputChange(event, fieldName) {
    handleSpecificChange({ field: fieldName, value: event.target.value })
  }

  const { values, handleSpecificChange, handleSubmit, errors, setErrors } = useForm({
    defaultState,
    validationSchema: buildValidationSchema(),
    submitAction: submit
  })

  const renderEmailField = () => {
    if (inviteeEmail.length === 0) return (
      <Box>
        <TextField
          label="Email"
          placeholder="user@email.com"
          margin="normal"
          fullWidth
          name="email"
          onChange={(event) => handleInputChange(event, 'email')}
          error={!isNil(errors.email)}
          helperText={errors.email}
        />
      </Box>
    )
  }


  return (
    <CustomDialog
      onClose={onClose}
      open={open}
      fullWidth
      title="SIGN UP"
    >
      {renderError()}
      <Box px="16px">
        <Box>
          <TextField
            label="First Name"
            placeholder="Firstname"
            margin="normal"
            fullWidth
            name="first_name"
            onChange={(event) => handleInputChange(event, 'first_name')}
            error={!isNil(errors.first_name)}
            helperText={errors.first_name}
          />
        </Box>
        <Box>
          <TextField
            label="Last Name"
            placeholder="Lastname"
            margin="normal"
            fullWidth
            name="last_name"
            onChange={(event) => handleInputChange(event, 'last_name')}
            error={!isNil(errors.last_name)}
            helperText={errors.last_name}
          />
        </Box>
        { renderEmailField() }
        <Box>
          <TextField
            label="Password"
            placeholder="*****************"
            margin="normal"
            fullWidth
            type="password"
            name="password"
            onChange={(event) => handleInputChange(event, 'password')}
            error={!isNil(errors.password)}
            helperText={errors.password}
          />
        </Box>
        <Box>
          <TextField
            label="Confirm Password"
            placeholder="*****************"
            margin="normal"
            fullWidth
            type="password"
            name="confirm_password"
            onChange={(event) => handleInputChange(event, 'confirm_password')}
            error={!isNil(errors.confirm_password)}
            helperText={errors.confirm_password}
          />
        </Box>
        <Box mt="24px" mb="44px">
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
            Create an Account
          </Button>
        </Box>
      </Box>
    </CustomDialog>
  )
}

export default SignupForm;