import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
} from '@material-ui/core';
import isNil from 'lodash-es/isNil';
import ChevronRightIcon from 'icons/chevron-right.svg';
import ChevronLeftIcon from 'icons/chevron-left.svg';

import { useStyles } from '../../styles/reflectionDashboard';

import useReflectionHandler from '../../hooks/useReflectionHandler';
import clsx from 'clsx';
import BubblesIcon from 'icons/talk-bubbles-line.png';
import WishlistIcon from 'icons/wishlist.png';
import GiftboxIcon from 'icons/giftbox.png';
import PartnersIcon from 'icons/partners.png';
import PopoverMenu from 'icons/popover-menu.png';

const RoomViewMenu = ({
  title,
  showNavigation,
  notifCount,
  onViewDropdownClick,
  onNextClick,
  onPreviousClick,
  showNextButton,
  showPreviousButton
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentRoom } = useReflectionHandler();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (view) => {
    onViewDropdownClick(view);
    setAnchorEl(null);
  };

  return (
    <Box mb="24px" className={clsx(classes.contentHeader)}>
      <Grid container>
        <Grid item container xs={10} alignItems="center">
          <Grid item>
            <Box className="bold" mr="10px">{title}</Box>
          </Grid>
          {showNavigation && (
            <>
              {showPreviousButton && (
                <Grid item>
                  <Box onClick={onPreviousClick} px="4px" style={{ display: 'flex' }}><img width="16" height="16" src={ChevronLeftIcon} /></Box>
                </Grid>
              )}
              {showNextButton && (
                <Grid item>
                  <Box onClick={onNextClick} px="4px" style={{ display: 'flex' }}><img width="16" height="16" src={ChevronRightIcon} /></Box>
                </Grid>
              )}
            </>
          )}

          {!isNil(notifCount) && notifCount !== 0 && (
            <Grid item>
              <Box className={clsx(classes.largeNotif)}>{notifCount}</Box>
            </Grid>
          )}
        </Grid>
        <Grid container item xs={2} justify="flex-end">
          <Button aria-haspopup="true" onClick={handleClick} style={{ padding: 0, marginRight: '-10px' }}>
            <img src={PopoverMenu} />
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            style={{
              marginTop: '-10px',
              marginRight: '-20px'
            }}
          >
            <MenuItem onClick={() => handleMenuClick('conversation')} style={{ padding: '6px 16px 6px 22px' }}>
              <Grid xs container alignItems="center">
                <Grid item xs={3}>
                  {currentRoom.unopenedConversations.length !== 0 && (
                    <Box className={clsx(classes.notif, classes.menuNotif)}>{currentRoom.unopenedConversations.length}</Box>
                  )}
                  <img src={BubblesIcon} />
                </Grid>
                <Grid item xs={9}>Conversations</Grid>
              </Grid>
            </MenuItem>
            <MenuItem onClick={() => handleMenuClick('wishlist')} style={{ padding: '6px 16px 6px 22px' }}>
              <Grid xs container alignItems="center">
                <Grid item xs={3}>
                  {/* <Box className={clsx(classes.notif, classes.menuNotif)}>3</Box> */}
                  <img src={WishlistIcon} />
                </Grid>
                <Grid item xs={9}>Wishlist</Grid>
              </Grid>
            </MenuItem>
            <MenuItem onClick={() => handleMenuClick('giftbox')} style={{ padding: '6px 16px 6px 22px' }}>
              <Grid xs container alignItems="center">
                <Grid item xs={3}>
                  {currentRoom.unopenedSuggestions.length !== 0 && (
                    <Box className={clsx(classes.notif, classes.menuNotif)}>{currentRoom.unopenedSuggestions.length}</Box>
                  )}
                  <img src={GiftboxIcon} style={{ marginRight: '5px' }} />
                </Grid>
                <Grid item xs={9}>Giftbox</Grid>
              </Grid>
            </MenuItem>
            <MenuItem onClick={() => handleMenuClick('partners')} style={{ padding: '6px 16px 6px 22px' }}>
              <Grid xs container alignItems="center">
                <Grid item xs={3}>
                  {/* <Box className={clsx(classes.notif, classes.menuNotif)}>4</Box> */}
                  <img src={PartnersIcon} style={{ marginRight: '5px' }} />
                </Grid>
                <Grid item xs={9}>Partners</Grid>
              </Grid>
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Box>
  )
}

export default RoomViewMenu;