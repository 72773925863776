import React from 'react';
import { Box } from '@material-ui/core'
import { FormGroup } from '@material-ui/core'
import { FormControlLabel } from '@material-ui/core'
import { FormControl } from '@material-ui/core'
import { Checkbox } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser';
import isNil from 'lodash-es/isNil';
import omit from 'lodash-es/omit';
import DurationField from './DurationField';

const RateField = (props) => {
  const {
    label,
    onChange,
    multi,
    name,
    row,
    defaultValue,
    options,
    description,
    fontColor,
    error
  } = props;
  const [values, setValues] = React.useState(defaultValue)

  // Reset values when default values props are changing
  React.useEffect(() => {
    setValues(defaultValue);
  }, [defaultValue])

  function handleChange(option) {
    let newValue = {};
    const cloned = {...values};
    const selected = Object.keys(cloned);

    if (selected.includes(option.id)) {
      newValue = omit(cloned, option.id)
    } else {
      newValue = { ...cloned, [option.id]: { value: option.identifier, duration: '00' } }
    }

    setValues(newValue);
    onChange({ field: name, value: newValue })
  }

  function handleDurationChange(option, value) {
    let newValue = {};
    const cloned = { ...values };
    newValue = { ...cloned, [option.id]: { value: option.identifier, duration: value} }

    setValues(newValue);
    onChange({ field: name, value: newValue })
  }

  function isSelected(option) {
    if (isNil(values)) return false;
    const selected = Object.keys(values);
    return selected.includes(option.id);
  }

  function durationValue(option) {
    if (isNil(values)) return "00";
    const selected = values[option.id];
    if (!isNil(selected)) return selected.duration;
    return '00';
  }

  function durationError(id) {
    if (isNil(error)) return null;
    if (error.split(',').includes(id)) {
      return 'please provide a duration value.'
    }
    return null;
  }

  function renderChecboxes() {
    return (
      <FormGroup aria-label="position" row={row}>
        {options.map((option) => {
          const selected = isSelected(option)
          return (
            <div key={option.identifier}>
              <FormControlLabel
                style={{ alignItems: 'flex-start', marginBottom: '12px' }}
                htmlFor={!!multi ? `${name}-checkbox-${option.id}` : `${name}-checkbox`}
                control={
                  <Checkbox
                    style={{ color: fontColor }}
                    name={name}
                    color="primary"
                    onChange={() => handleChange(option)}
                    id={!!multi ? `${name}-checkbox-${option.id}` : `${name}-checkbox`}
                    checked={selected}
                    style={{ padding: '0 9px' }}
                  />
                }
                label={option.title}
                labelPlacement="end"
                key={option.id}
              />

              {selected &&
                <DurationField
                  onChange={(value) => handleDurationChange(option, value.value)}
                  description={"HOW lONG USUALLY?"}
                  name={"duration"}
                  defaultValue={durationValue(option)}
                  error={durationError(option.id)}
                />
              }
            </div>
          )
        })}
      </FormGroup>
    )
  }

  return (
    <Box mb="24px" style={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl component="fieldset">
        {label && <Box mb="24px" className="component-label" style={{ color: fontColor }}>{ReactHtmlParser(label)}</Box>}
        {description && <Box className="component-label-description" style={{ color: fontColor }}>{ReactHtmlParser(description)}</Box>}
        {renderChecboxes()}
      </FormControl>
    </Box>
  )
}

const MemoizedComponent = React.memo(RateField);

export default MemoizedComponent;