import React, { useContext, useState } from 'react';
import {
  Box,
  Grid,
  Button
} from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core'
import { FormControl } from '@material-ui/core'
import { Checkbox } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import clsx from 'clsx';
import isNil from 'lodash-es/isNil';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useSubmitWishlist, useUpdateWishlist } from '../../api/reflection';
import buildValidationSchema from '../../validations/wishlist';

import ApplicationContext from '../../contexts/applicationContext';
import useReflectionHandler from '../../hooks/useReflectionHandler';
import useForm from '../../hooks/useForm';
import TextFieldInput from '../inputs/Textfield';
import TagSelection from '../inputs/TagSelection';

const checkboxOptions = ['recommendations', 'challenges', 'lessons', 'mistakes']

const WishListForm = ({ onSaveWishList, onUpdateWishList, wishList }) => {
  const validationSchema = buildValidationSchema();
  const { currentRoom, currentProject } = useReflectionHandler();
  const { setToastOpen, setToastMessage, setToastColor } = useContext(ApplicationContext);
  const [checkBoxError, setCheckboxError] = useState();

  function defaultState() {
    if (!isNil(wishList)) {
      return { ...wishList, included_rooms: wishList.includedRooms }
    }

    return {
      title: '',
      included_rooms: [currentRoom.id]
    }
  }

  const [submitWishlist, { loading: submittingWishList }] = useSubmitWishlist(onSubmitSuccess, onSubmitError);
  const [updateWishlist, { loading: updatingWishList }] = useUpdateWishlist(onUpdateSuccess, onSubmitError);

  // This will contain implementations for transition effect
  function onSubmitSuccess(response) {
    if (response.createWishlist.success) {
      setToastColor('success');
      setToastOpen(true);
      setToastMessage('Successfully saved wish!');
      onSaveWishList(response.createWishlist.wishlist)
    }
  }

  function onSubmitError(response) {
    setToastColor('error');
    setToastOpen(true);
    setToastMessage('Oops! Something went wrong. Try again.');
  }

  // This will contain implementations for transition effect
  function onUpdateSuccess(response) {
    if (response.updateWishlist.success) {
      setToastColor('success');
      setToastOpen(true);
      setToastMessage('Successfully saved wish!');
      onUpdateWishList(response.updateWishlist.wishlist)
    }
  }

  function handleSave() {
    // TODO: revisit this.
    // This should be handled in validations

    if (isNil(values.recommendations)) {
      setCheckboxError('Please select atleast one checkbox above and fill the input!');
    } else {
      setCheckboxError(undefined);
    }

    handleSubmit();
  }

  function submit() {
    let payload = {
      project_id: currentProject.id,
      ...values
    }

    if (!isNil(values.id)) {
      updateWishlist({ variables: payload })
    } else {
      submitWishlist({ variables: payload })
    }
  }

  const {
    values,
    errors,
    handleSubmit,
    handleSpecificChange,
    setValues,
  } = useForm({
    defaultState: defaultState(),
    submitAction: submit,
    validationSchema
  })

  function handleCheckboxChange(option) {
    const keys = Object.keys(values);
    let newValues = {};

    if (keys.includes(option) && !isNil(values[option])) {
      newValues = { ...values }
      delete newValues[option];
    } else {
      newValues = { ...values, [option]: '' }
    }

    setValues(newValues);
  }

  function handleInputChange(event, option) {
    let newValues = {};

    if (event.target.value === '') {
      newValues = { ...values }
      delete newValues[option];
    } else {
      newValues = { ...values, [option]: event.target.value }
    }
    setValues(newValues);
  }

  function selected(option) {
    const keys = Object.keys(values);
    return keys.includes(option) && !isNil(values[option]);
  }

  function renderTagSelection() {
    const options = currentRoom.roomsCollection.map(room => ({
      title: room.title,
      identifier: room.id
    }))

    return (
      <TagSelection
        onChange={handleSpecificChange}
        label={"Tag up to 3 related rooms"}
        name={"included_rooms"}
        options={options}
        defaultValue={values.included_rooms}
        fontColor={'#000'}
        description={''}
        shouldDisableOption={currentRoom.id}
      />
    )
  }

  function renderCheckboxes() {
    const checkboxLabel = (option) => {
      switch (option) {
        case 'recommendations':
          return 'I recommend...'
        case 'challenges':
          return 'Challenges I met...'
        case 'lessons':
          return 'Lessons I learned...'
        case 'mistakes':
          return 'Mistakes I made...'
        default:
          return ''
      }
    }

    return checkboxOptions.map((option) => {
      return (
        <FormControl fullWidth key={option}>
          <FormControlLabel
            style={{ alignItems: 'flex-start', marginBottom: '12px' }}
            htmlFor={`${name}-checkbox-${option}`}
            control={
              <Checkbox
                style={{ color: '#000' }}
                name={option}
                color="primary"
                onChange={() => handleCheckboxChange(option)}
                id={`${name}-checkbox-${option}`}
                checked={selected(option)}
                style={{ padding: '0 9px' }}
              />
            }
            label={checkboxLabel(option)}
            labelPlacement="end"
            key={option}
          />

          <Box mb="16px">
            <TextField
              multiline
              style={{ color: '#000' }}
              rowsMax={5}
              fullWidth
              onChange={(event) => handleInputChange(event, option)}
              defaultValue={isNil(values[option]) ? '' : values[option]}
              placeholder={''}
            />
          </Box>
        </FormControl>
      )
    });
  }

  const submitting = submittingWishList || updatingWishList;

  return (
    <Grid container m="16px" className={clsx('full-percent-height')} component="div" direction="column" alignItems="center" justify="center">
      <Grid item style={{ width: '100%' }}>

        <Box mb="24px">
          Choose a phrase and complete the wishlist by filling in the blanks relating to your experience of {currentRoom.title}.
        </Box>

        {renderCheckboxes()}

        {!isNil(checkBoxError) && (
          <div style={{
            color: '#f44336',
            marginTopn: '10px',
            marginBottom: '10px',
            fontSize: '13px'
          }}>{checkBoxError}</div>
        )}

        <p>As a result, I wish [describe something that you want more of (or less of) relating to this experience] </p>

        <TextFieldInput
          onChange={handleSpecificChange}
          label={"Give this a title"}
          name="title"
          defaultValue={values.title}
          fontColor={"#2F2F2F"}
          placeholder={''}
          error={errors.title}
        />

        {renderTagSelection()}

        <Box mb="16px">
          <Button variant="contained" color="primary" onClick={handleSave} disabled={submitting}>
            {submitting && <CircularProgress size={14} />}
            {!submitting && 'Add to WishList'}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default WishListForm;