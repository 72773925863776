import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Grid, LinearProgress } from '@material-ui/core'

import { useStyles } from '../../styles/reflection';

const Progress = withStyles(() => ({
  root: {
    height: 14,
    borderRadius: 10,
  },
  colorPrimary: {
    backgroundColor: '#fff',
  },
  bar: {
    height: 12,
    borderRadius: 10,
    backgroundColor: '#03CEC2',
    boxShadow: `2px 2px 4px rgba(54, 54, 54, 0.4)`
  },
}))(LinearProgress);

const WeeklyProgress = ({ segmentReflection }) => {
  const classes = useStyles();
  const label = `${segmentReflection.hours_number}hrs (${segmentReflection.hours_percent}%)`

  // So progress bar will show properly. Only set upto 100 percent
  const percentage = segmentReflection.hours_percent > 100 ? 100 : segmentReflection.hours_percent;
  
  return (
    <Grid xs={12} container className={clsx(classes.weeklyProgress)} spacing={2}>
      <Grid container xs={4} justify="flex-end" style={{ paddingTop: '8px' }}>
        <Grid item className={clsx(classes.weeklyProgressLabel)}>{segmentReflection.segment_name}</Grid>
      </Grid>
      <Grid container item xs={8} direction="column">
        <Grid item>
          <Progress variant="determinate" value={percentage} />
        </Grid>
        <Grid item className={clsx(classes.weeklyProgressPercentage)}>
          {label}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default WeeklyProgress;