import React from 'react';
import { Box } from '@material-ui/core'
import { Chip } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser';
import pull from 'lodash-es/pull';
import isNil from 'lodash-es/isNil';

const TagSelection = (props) => {
  const { label, onChange, name, options, defaultValue, shouldDisableOption } = props;
  const [values, setValues] = React.useState(defaultValue)

  const handleChange = (option) => {
    let newValues = values;

    if (values.includes(option.identifier)) {
      newValues = pull(values, option.identifier);
    } else {
      if (values.length !== 3 ) {
        newValues = [...values, option.identifier];
      }
    }

    setValues(newValues);
    onChange({ field: name, value: newValues })
  }

  // Reset values when default values props are changing
  React.useEffect(() => {
    setValues(defaultValue);
  }, [defaultValue])

  return (
  	<Box mb="24px" style={{ color: props.fontColor }}>
      <Box mb="24px" className="component-tag-label">{ReactHtmlParser(label)}</Box>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {options.map((option) => {
          return (
            <div key={option.id} style={{ marginLeft: '4px', marginTop: '6px' }}>
              <Chip
                style={{ color: props.fontColor, border: `1px solid ${props.fontColor}` }}
                label={option.title}
                clickable
                variant={!isNil(values) && values.includes(option.identifier) ? 'default' : 'outlined'}
                onClick={() => handleChange(option)}
                disabled={shouldDisableOption === option.identifier}
              />
            </div>
          )
      })}
      </div>
	  </Box>
  )
}

const MemoizedComponent = React.memo(TagSelection);

export default MemoizedComponent;