import React from 'react';
import { Box, Container, Grid, Icon, Paper, Typography } from '@material-ui/core';
import { navigate } from '@reach/router';
import { useStyles } from '../../styles/invitation';
import clsx from 'clsx';

import HandshakeIcon from 'icons/shake.svg'
import ChevronRightWhite from 'icons/chevron-right-white.svg'
import ProjectContext from '../../contexts/projectContext';
import ReactHtmlParser from 'react-html-parser';
import WithProjectTemplate from '../common/WithProjectTemplate';
import cookie from "cookie_js";
import Fade from '@material-ui/core/Fade';

const GoLiveEnd = () => {
  const classes = useStyles();
  const { currentProject } = React.useContext(ProjectContext);
  const currentUserFirstName = cookie.get('current_user_first_name');

  const paragraph = `Let's take a moment to pause and refocus our energies on [Clara] and all the good we will be sending through this `

  function onClickNext() {
    navigate(`/project/${currentProject.slug}/intaker`);
  }

  function strReplace(){
    const newStr = paragraph.replace('[Clara]', `<div class="fname">${currentProject.firstName}</div>`);

    return newStr;
  }

  const renderGoLive = () => {
    return (
      <Fade in={true} unmountOnExit>
        <WithProjectTemplate>
          <Container maxWidth="sm" className={clsx(classes.layout)}>
            <Grid container component="div" spacing={0} direction="column" className={clsx('full-height')}>
              <Grid item>
                <Box my="44px">
                  <Typography align="center" className={clsx(classes.title, 'lora')}>The Empathy Project</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box mx="12px" mt="80px">
                  <Paper elevation={3} className={clsx(classes.invitation)}>
                    <Box className={clsx(classes.image)}>
                      <img src={HandshakeIcon} />
                    </Box>
                    <Typography variant="body1" component="div" color="primary">
                      <Box mt="60px" mb="100px">
                        {ReactHtmlParser(strReplace())}
                        <Typography display="inline" className={clsx('bold')}>Empathy Project</Typography>
                      </Box>
                    </Typography>
                  </Paper>
                </Box>
              </Grid>
              <Grid item>
                <Box my="64px" onClick={onClickNext} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Box className={clsx('next-button')}
                    style={{ border: `1px solid #595959`, backgroundColor: '#595959' }}>
                    <Icon fontSize="large"><img src={ChevronRightWhite} /></Icon>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </WithProjectTemplate>
      </Fade>
    )
  }

  return (
    <div className={clsx('page-container', classes.root)}>
      {renderGoLive()}
    </div>
  )
}

export default GoLiveEnd;