import cookie from 'cookie_js';
import { navigate } from '@reach/router';

export const authenticate = (accessToken, user) => {
  cookie.set('authentication_token', accessToken, { expires: 1 })
  cookie.set('user_email', user.email, { expires: 1 })
  cookie.set('current_user_first_name', user.firstName, { expires: 1 })
  cookie.set('current_user_id', user.id, { expires: 1 })
}

export const isAuthenticated = () => {
  return !!cookie.get('authentication_token');
}

export const unAuthenticate = () => {
  cookie.remove('authentication_token');
  cookie.remove('user_email');
  cookie.remove('current_user_first_name');
  navigate('/login');
}