// Use this hook inside the project provider
// or it will not work properly, because it 
// needs the states inside the project context

import React from 'react';
import isNil from 'lodash-es/isNil';
import { useParams } from '@reach/router';
import { navigate } from '@reach/router';

import { SUB_QUESTION_TYPES } from '../utils/segment';
import {
  useFetchFirstSegment,
  useFetchNextPreviousSegment,
  useFetchProject,
  useFetchProjectProgress,
  useFetchSegment,
  useUpdateProject
} from '../api/project';

import ProjectContext from '../contexts/projectContext';
import ApplicationContext from '../contexts/applicationContext';

const useProjectGraphqlHandler = () => {
  const { setToastOpen, setToastMessage, setToastColor } = React.useContext(ApplicationContext)
  const { projectSlug } = useParams();

  const {
    currentProject,
    currentSegment,
    currentStepIndex,
    setCurrentStepIndex,
    setCurrentSegment,
    setCurrentProject,
    currentSegmentAnswers,
    setCurrentSegmentAnswers,
    setRoomId,
    setRoomAction,
    roomId,
    roomAction
  } = React.useContext(ProjectContext);

  // Hook to fetch first segment
  const [getFirstSegment, {
    data: firstSegmentData,
    loading: loadingFirstSegment,
    error: loadFirstSegmentError
  }] = useFetchFirstSegment(onFirstSegmentFetchSuccess);

  // Hook to fetch next or previous segment
  const [getNextPreviousSegment,
    { loading: loadingNextPreviousSegment, error: loadNextPreviousError }
  ] = useFetchNextPreviousSegment(onNextPreviousSegmentFetchSuccess, onFetchError);

  // Fetch specific segment
  const [getSegment,
    { loading: loadingSegment, error: loadSegmentError }
  ] = useFetchSegment(onSegmentFetchSuccess, onFetchError);

  // Fetch Latest project progress
  const [getProjectProgress,
    { data: projectProgress, loading: fetchingProjectProgress, error: fetchProgressError }
  ] = useFetchProjectProgress(onFetchProjectProgressCallback, onFetchError);

  // Fetch specific project
  const [fetchProject, {
    loading: fetchingCurrentProject,
    error: fetchProjectError
  }] = useFetchProject(onFetchProjectSuccess, onFetchError);

  // go to we pay it forward
  const goToPayItForward = () => {
    navigate(`/project/${currentProject.slug}/pay-forward/welcome`)
  }

  // Update Project
  const [updateProject] = useUpdateProject(goToPayItForward);

  // For now use thi single method to show toast
  // error when an api call fails
  function onFetchError() {
    setToastColor('error')
    setToastMessage('Ooops! Something went wrong. Failed to fetch data')
    setToastOpen(true)
  }

  // Placeholder if we want to do something on the
  // success callback of project progress fetch
  function onFetchProjectProgressCallback(response) {
    // Might do something here
  }

  function onFetchProjectSuccess(response) {
    if (response.fetchProject) {
      const { answer, ...rest } = response.fetchProject;
      setCurrentProject(rest);
    }
  }

  function fetchCurrentProject() {
    fetchProject({ variables: { slug: projectSlug } })
  }

  // This is to make sure that we don't miss a display order
  // There is a chance someone will delete a step in backend.
  function processSegment(segment) {
    let steps = segment.steps.slice().sort((a, b) => a.displayOrder - b.displayOrder)
    steps = steps.map((step, index) => {
      return {
        ...step,
        displayOrder: index + 1
      };
    });

    return {
      ...segment,
      steps
    }
  }

  function afterSegmentFetch(segment) {
    if (segment.hideSegmentHeader) {
      setCurrentStepIndex(0);
    } else {
      setCurrentStepIndex('segment_header');
    }
  }

  function onSegmentFetchSuccess(response) {
    if (!isNil(response)) {
      const segment = processSegment(response.fetchSegment);
      setCurrentSegment(segment);
      setCurrentSegmentAnswers(buildSegmentAnswers(response.fetchSegment));

      // For sure when this callback is called
      // There will also be a value for the current step.
      // Let's find the index of the step in segment steps
      // and set it, so that intaker can show the correct step.
      const stepIndex = response.fetchSegment.steps.findIndex((step) => parseInt(step.id) === currentProject.currentStep)
      setCurrentStepIndex(stepIndex);
    }
  }

  function onFirstSegmentFetchSuccess(response) {
    if (!isNil(response)) {
      const segment = processSegment(response.firstSegment);
      setCurrentSegment(segment);
      setCurrentSegmentAnswers(buildSegmentAnswers(response.firstSegment));
      ///setCurrentStepIndex('segment_header')
      afterSegmentFetch(segment);
    }
  }

  function onNextPreviousSegmentFetchSuccess(response) {
    if (!isNil(response)) {
      const segment = processSegment(response.nextPreviousSegment);

      setCurrentSegment(segment);
      setCurrentSegmentAnswers(buildSegmentAnswers(response.nextPreviousSegment));
      setCurrentStepIndex('segment_header')
      afterSegmentFetch(segment);
    }
  }

  // Will be use to determine if the segment contains sub questions.
  // Will be useful in steps like asking about the members of the family
  function hasSubQuestions(step) {
    let has = false;
    step.questions.forEach((question) => {
      if (SUB_QUESTION_TYPES.includes(question.questionType)) {
        has = true;
      }
    });

    return has;
  }

  function buildStepAnswers(step) {
    let questions = {};
    step.questions.forEach((question) => {
      switch (question.questionType) {
        case 'Tag Selection':
        case 'Keywords':
        case 'Check box':
        case 'Multi Select Dropdown':
          questions[question.id] = !isNil(question.answer) ? JSON.parse(question.answer).arr : [];
          break;
        case 'Rank Questions: Dropdown + Input Field':
        case 'Ranking':
        case 'Nested Checkbox with Duration Field':
        case 'Nested Rating with Duration Field':
        case 'Dynamic Dropdown':
          questions[question.id] = !isNil(question.answer) ? JSON.parse(question.answer) : {};
          break;
        default:
          questions[question.id] = question.answer;
      }
    });
    return questions;
  }

  // This will be called after a room fetch to
  // build room conversations answers
  function buildSegmentAnswers(segment) {
    let steps = {};

    segment.steps.forEach((step) => {
      if (hasSubQuestions(step)) {
        const parentQuestion = step.questions.find((q) => !SUB_QUESTION_TYPES.includes(q.questionType));
        steps[step.id] = {
          [parentQuestion.id]: !isNil(parentQuestion.answer) ? JSON.parse(parentQuestion.answer) : {}
        }
      } else {
        steps[step.id] = buildStepAnswers(step);
      }
    });

    return steps;
  }

  function fetchCurrentIntakerProgress() {
    getProjectProgress({ variables: { id: currentProject.id } })
  }

  function fetchSegment() {
    if (!isNil(currentProject)) {
      // If project status is confirmed
      // We will fetch the first segment since
      // is safe to say that there's no progress yet.
      // Else, we will fetch the specific segment the user stopped
      if (currentProject.status === 'confirmed' || currentProject.status === 'initiated') {
        getFirstSegment({ variables: { projectId: currentProject.id } });
      } else {
        getSegment({ variables: { projectId: currentProject.id, id: currentProject.currentSegment } })
      }
    }
  }

  function fetchRoom(roomId) {
    if (!isNil(currentProject)) {
      getSegment({ variables: { projectId: currentProject.id, id: roomId } })
    }
  }

  // fetch next or previous segment
  // key could be next or previous
  function fetchNextPreviousSegment(key, id) {
    const segmentId = !isNil(id) ? id : currentSegment.id;
    getNextPreviousSegment({ variables: { projectId: currentProject.id, id: segmentId, key: key } })
  }

  function buildAnswersPayload(formValues, step) {
    const questions = Object.keys(formValues);
    let answers = [];

    const shouldStringifyAnswer = (answer) => {
      return answer instanceof Array || answer instanceof Object;
    }

    const stringify = (answer) => {
      if (answer instanceof Array) {
        return JSON.stringify({ arr: answer });
      } else if (answer instanceof Object) {
        return JSON.stringify(answer);
      }
    }

    // Loop on questions
    // question will be the question id here
    questions.forEach((question) => {
      const questionAnswer = formValues[question];
      const answer = {
        step_id: step.id.toString(),
        question_id: question.toString(),
        answer: shouldStringifyAnswer(questionAnswer) ? stringify(questionAnswer) : questionAnswer
      }
      answers = [...answers, answer]
    });
    return answers;
  }

  function sortAnswerOptions(options) {
    return options.slice().sort((a, b) => a.displayOrder - b.displayOrder)
  }

  function updateCurrentSegmentAnswers(stepId, answers) {
    const updatedAnswers = currentSegmentAnswers;
    updatedAnswers[stepId] = answers;
    setCurrentSegmentAnswers(updatedAnswers);
  }

  // This returns the step answers set on segment fetch
  function buildIntakerFormDefaultStates(stepId) {
    return currentSegmentAnswers[stepId];
  }

  function segmentCurrentStep() {
    if (!isNil(currentSegment)) {
      return currentSegment.steps[currentStepIndex];
    }
    return null;
  }

  // This will determine where to go next correctly
  function setNextStep(isLastStep = false) {
    if (currentSegment.isLast && isLastStep && currentSegment.hideProgressScreen) {
      updateProject({ variables: { id: currentProject.id } })
    }

    if (isLastStep && currentSegment.hideProgressScreen) {
      fetchNextPreviousSegment('next');
      return;
    }

    if (isLastStep && !currentSegment.hideProgressScreen) {
      setCurrentStepIndex('segment_end');
      return;
    }

    if (!currentSegment.isLast && currentStepIndex === 'segment_end') {
      fetchNextPreviousSegment('next');
      return;
    }

    if (currentSegment.isLast && currentStepIndex === 'segment_end') {
      updateProject({ variables: { id: currentProject.id } })
    }

    if (currentStepIndex === 'segment_header' && !isNil(currentSegment.description)) {
      setCurrentStepIndex('segment_description');
      return;
    } else if (currentStepIndex === 'segment_header' && isNil(currentSegment.description)) {
      if (currentSegment.steps.length === 0) {
        fetchNextPreviousSegment('next');
        return;
      }
      setCurrentStepIndex(0);
      return;
    } else if (currentStepIndex === 'segment_description') {
      if (currentSegment.steps.length === 0) {
        fetchNextPreviousSegment('next');
        return;
      }
      setCurrentStepIndex(0);
      return;
    }
  }

  function correctProjectProgress() {
    return isNil(projectProgress) ? 0 : projectProgress.projectCompletedPercentage.percentageOfCompletedSegments;
  }

  function hasSegmentExisting() {
    return !isNil(currentSegment);
  }

  function hasAvailableSegment() {
    return !isNil(currentSegment);
  }

  function onSegmentStart() {
    return hasAvailableSegment() && currentStepIndex === 'segment_header';
  }

  function onSegmentDescription() {
    return hasAvailableSegment() && currentStepIndex === 'segment_description';
  }

  function onSegmentEnd() {
    return currentStepIndex === 'segment_end';
  }

  function onSegmentSteps() {
    return !onSegmentStart() && !onSegmentEnd();
  }

  return {
    fetchProject,
    fetchNextPreviousSegment,
    buildAnswersPayload,
    fetchCurrentProject,
    buildIntakerFormDefaultStates,
    hasAvailableSegment,
    hasSubQuestions,
    hasSegmentExisting,
    onSegmentSteps,
    setNextStep,
    onSegmentDescription,
    onSegmentStart,
    onSegmentEnd,
    segmentCurrentStep,
    updateCurrentSegmentAnswers,
    fetchSegment,
    sortAnswerOptions,
    fetchCurrentIntakerProgress,
    buildStepAnswers,
    fetchRoom,
    roomId,
    roomAction,
    setRoomId,
    setRoomAction,
    setCurrentStepIndex,
    currentSegment,
    currentProject,
    loadingFirstSegment,
    loadingNextPreviousSegment,
    fetchingCurrentProject,
    firstSegmentData,
    loadFirstSegmentError,
    loadNextPreviousError,
    fetchProjectError,
    currentSegmentAnswers,
    projectProgress: correctProjectProgress(),
    fetchProgressError,
    fetchingProjectProgress,
    loadingSegment,
    loadSegmentError,
  }
}

export default useProjectGraphqlHandler;