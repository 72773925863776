import React, { useEffect, useContext, useState } from 'react';
import {
  AppBar,
  Box,
  Container,
  Grid,
  Icon,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useParams, navigate } from '@reach/router';
import clsx from 'clsx';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import isNil from 'lodash-es/isNil';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import usePrevious from '../../hooks/usePrevious';
import ReactHtmlParser from 'react-html-parser';

import { useStyles } from '../../styles/reflectionDashboard';
import useReflectionHandler from '../../hooks/useReflectionHandler';
import useToast from '../../hooks/useToasti';
import RoomViewConversation from './RoomViewConversation';
import RoomViewWishlist from './RoomViewWishlist';
import RoomViewGiftbox from './RoomViewGiftbox';
import RoomViewPartners from './RoomViewPartners';

import ProjectContext from '../../contexts/projectContext';
import BurgerMenu from "../common/BurgerMenu";
import HamburgerIcon from 'icons/hamburger-white.svg';
import TepLogo from 'icons/tep-logo.png';
import ChevronLeftIcon from 'icons/chevron-left.png';
import ChevronRightIcon from 'icons/chevron-right.png';
import ConfRoomIcon from 'icons/conference-room.png';
import PinnedRoom from 'icons/pinned-room.png';
import ConvoIcon from 'icons/convo.png';
import SemiCircleProgressBar from 'react-progressbar-semicircle';

const RoomView = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [clickCounter, setClickCounter] = React.useState(0);
  const { currentProject, roomFetchAction, setRoomFetchAction, currentRoom } = useContext(ProjectContext);
  const {
    getRoom,
    loadingRoom,
    loadRoomError, 
    getNextPreviousRoom,
    nextPreviousRoomLoading,
    nextPreviousRoomError,
  } = useReflectionHandler();
  const { roomId } = useParams();
  const { renderToast } = useToast();
  const room = currentRoom;

  // This can be conversation, wishlist, giftbox or partners
  const [currentView, setCurrentView] = React.useState('conversation');

  // We need to add this so that we can correctly do right and left transition.
  // The one in project context will be use for doing correct api calls.
  // We can't use this state for api call because we need api call direction
  // to be always updating from next or previous.
  const [clickDirection, setClickDirection] = useState('right');

  // To fetch room on load using room id in url
  useEffect(() => { getRoom(roomId) }, []);

  // This is to fetch next or previous room
  const prevRoomFetchAction = usePrevious(roomFetchAction)
  useEffect(() => {
    if (prevRoomFetchAction !== roomFetchAction && !isNil(roomFetchAction)) {
      setCurrentView('conversation');
      getNextPreviousRoom(room.id, roomFetchAction);
    }
  }, [prevRoomFetchAction, roomFetchAction]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const goBackToDashboard = () => {
    navigate(`/project/${currentProject.slug}/reflection/dashboard`)
  }

  function onBackClick() {
    setClickCounter(clickCounter + 1)
    setClickDirection('left');
    if (!isNil(room) && !room.isFirst) {
      setRoomFetchAction('previous');
    }
  }

  function onViewDropdownClick(view) {
    setCurrentView(view);
  }

  function onNextClick() {
    setClickCounter(clickCounter + 1)
    setClickDirection('right');
    if (!isNil(currentRoom) && !room.isLast) {
      setRoomFetchAction('next');
    }
  }

  function renderRoomView() {
    if (currentView === 'conversation') {
      return <RoomViewConversation
        onViewDropdownClick={onViewDropdownClick}
        conversations={room.conversations}
      />
    } else if (currentView === 'giftbox') {
      return <RoomViewGiftbox onViewDropdownClick={onViewDropdownClick} giftbox={room.giftbox} />
    } else if (currentView === 'wishlist') {
      return <RoomViewWishlist onViewDropdownClick={onViewDropdownClick} wishList={room.wishlists} />
    } else if (currentView === 'partners') {
      return <RoomViewPartners onViewDropdownClick={onViewDropdownClick} />
    }
    return null;
  }

  function renderContent() {
    if (!isNil(loadRoomError) || !isNil(nextPreviousRoomError)) {
      return (
        <div className={clsx(classes.roomsErrorContainer)}>
          <Box px="50px" my="20px" style={{ textAlign: 'center' }}>
            <p>Ooops! An error occured. Please refresh the page.</p>
          </Box>
        </div>
      )
    }

    if (loadingRoom || nextPreviousRoomLoading || isNil(room)) {
      return (
        <div className={clsx(classes.roomsLoaderContainer)}>
          <Box px="50px" my="20px" style={{ textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        </div>
      )
    }

    function renderTopSection() {
      if (room.isFirst) {
        return (
          <Box px="15px" style={{ fontSize: '12px', lineHeight: '18px', color: '#fff' }}>
            <Box mb="30px">
              This room hosts life's most important conversations about joy, meaning and purpose. Who am I? Why am I here? What was I born to do? Our success and wellbeing in all the other rooms of our lives depend on this Living Room.
              </Box>
            <Grid container alignItems="center" justify="space-evenly" justify="center" py="10px" style={{ textAlign: 'center' }}>
              <Grid item xs={4}>
                <Box className={clsx(classes.percentageContainer)} style={{ marginTop: '0' }}>
                  <SemiCircleProgressBar percentage={room.priorityScore} showPercentValue diameter={90} strokeWidth={4} />
                </Box>
              </Grid>
              <Grid item xs={4} style={{ width: '90%', alignSelf: 'center' }}>
                <Box p="10px" style={{ width: '100%' }}>
                  <img src={ConvoIcon} style={{ width: '100%' }} />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <img src={PinnedRoom} />
                </Box>
              </Grid>
            </Grid>
            <Grid container justify="space-evenly" justify="center" py="10px"
              className={clsx('bold', classes.roomSubTexts)}
              style={{ textAlign: 'center' }}>
              <Grid item xs={4}>
                <Box p="10px">PRIORITY SCORE</Box>
              </Grid>
              <Grid item xs={4}>
                <Box p="10px">CONVERSATIONS</Box>
              </Grid>
              <Grid item xs={4}>
                <Box p="10px">PINNED ROOM</Box>
              </Grid>
            </Grid>
            <Grid container justify="space-evenly" justify="center" py="10px"
              style={{ textAlign: 'center' }}
              className={clsx(classes.roomSubTexts)}>
              <Grid item xs={4}>
                <Box px="10px">rates the importance of this room based on real time data</Box>
              </Grid>
              <Grid item xs={4}>
                <Box px="10px">bout living our deepest truths and highest potentials</Box>
              </Grid>
              <Grid item xs={4}>
                <Box px="10px">We can drag and drop all other rooms but Living is always #1</Box>
              </Grid>
            </Grid>
          </Box>
        )
      }

      return (
        <Box px="15px" style={{ fontSize: '12px', lineHeight: '18px', color: '#fff' }}>
          <Box mb="30px">
            {ReactHtmlParser(room.description)}
          </Box>
          <Grid container alignItems="center" justify="space-evenly" justify="center" py="10px" style={{ textAlign: 'center' }}>
            <Grid item xs={4}>
              <Box className={clsx(classes.percentageContainer)} style={{ marginTop: '0' }}>
                <SemiCircleProgressBar percentage={Math.round(room.priorityScore, 2)} showPercentValue diameter={90} strokeWidth={4} />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className={clsx(classes.percentageContainer)} style={{ marginTop: '0' }}>
                <SemiCircleProgressBar percentage={Math.round(room.duration,2)} showPercentValue diameter={90} strokeWidth={4} />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className={clsx(classes.percentageContainer)} style={{ marginTop: '0' }}>
                <SemiCircleProgressBar percentage={Math.round(room.happinessRating * 25,2)} showPercentValue diameter={90} strokeWidth={4} />
              </Box>
            </Grid>
          </Grid>
          <Grid container justify="space-evenly" justify="center" py="10px"
            style={{ textAlign: 'center' }}
            className={clsx('bold', classes.roomSubTexts)}>
            <Grid item xs={4}>
              <Box p="10px">PRIORITY SCORE</Box>
            </Grid>
            <Grid item xs={4}>
              <Box p="10px">{room.duration} OF 168 HOURS A WEEK</Box>
            </Grid>
            <Grid item xs={4}>
              <Box p="10px">{room.happinessScore} OF 4 ON HAPPINESS SCALE</Box>
            </Grid>
          </Grid>
        </Box>
      )
    }

    function notifCount() {
      return currentRoom.unopenedConversations.length + currentRoom.unopenedSuggestions.length;
    }

    return (
      <>
        <Box pb="20px" style={{ background: '#353535', color: '#fff' }}>
          <Box py="25px" px="15px">
            <Grid container justify="space-between">
              <Grid item xs={8} className="bold">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '4px' }}>{room.title}</span>
                  {notifCount() !== 0 && (
                    <span className={clsx(classes.largeNotif)}>{notifCount()}</span>
                  )}
                </div>
              </Grid>
              <Grid container item xs={4} justify="flex-end" alignItems="center">
                {/* TODO: handle this */}
                {!room.isFirst && (
                  <Grid item>
                    <Box onClick={onBackClick} px="10px"><img src={ChevronLeftIcon} /></Box>
                  </Grid>
                )}
                <Grid item>
                  <Box px="10px"><img src={ConfRoomIcon} onClick={goBackToDashboard} /></Box>
                </Grid>
                {!room.isLast && (
                  <Grid item>
                    <Box onClick={onNextClick} px="10px"><img src={ChevronRightIcon} /></Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
          {renderTopSection()}
        </Box>

        {renderRoomView()}
      </>
    )
  }

  return (
    <Slide key={`card-${clickCounter}`} direction={clickDirection} in={true} mountOnEnter={true}>
      <div className={clsx('page-container', classes.root)}>
        { renderToast() }
        <Container disableGutters={true} maxWidth="sm">
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
            color="default"
            style={{ background: `#4D4D4D`, padding: `24px 16px 0 16px` }}
          >
            <Toolbar disableGutters={true} style={{ justifyContent: 'space-between' }}>
              <Grid xs container>
                <Grid item container xs={10} alignItems="center">
                  <Grid item>
                    <img src={TepLogo} style={{ marginBottom: '-10px', marginRight: '10px', width: '60px' }} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" className={clsx('lora')} style={{ color: '#fff' }}>
                      The Empathy Project
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={2} justify="flex-end">
                  <Icon fontSize="large" onClick={handleDrawerOpen}><img src={HamburgerIcon} /></Icon>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>

          <BurgerMenu
            open={open}
            setOpen={setOpen}
            currentProject={currentProject}
          />

          <Box className={clsx(classes.dashboardContent)} mt="80px" style={{ background: '#EEEEEE' }}>
            {renderContent()}
          </Box>

        </Container>
      </div>
    </Slide>
  )
}

export default RoomView;