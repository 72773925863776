import { gql, useMutation } from '@apollo/client';

export const REGISTER_USER = gql`
  mutation RegisterUser($email: String!, $password: String!, $first_name: String!, $last_name: String!, $project_id: ID) {
    registerUser(input: { params: { email: $email, password: $password, firstName: $first_name, lastName: $last_name, projectId: $project_id }}) {
      user {
        id
        email
        authenticationToken
      }
      errors
    }
  }
`;

export function useRegisterUser(onCompleted) {
  return useMutation(REGISTER_USER, { errorPolicy: 'all', onCompleted: onCompleted });
}

export const  AUTHENTICATE_USER = gql`
  mutation SignIn($email: String!, $password: String!, $first_name: String, $last_name: String, $provider: String, $provider_id: String) {
    signIn(input: { params: { email: $email, password: $password, firstName: $first_name, lastName: $last_name, provider: $provider, providerId: $provider_id } }) {
      user {
        id
        email
        authenticationToken
        firstName
        lastName
      }
      success
      errors
    }
  }
`;

export function useAuthenticateUser(onCompleted) {
  return useMutation(AUTHENTICATE_USER, { errorPolicy: 'all', onCompleted: onCompleted });
}

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(input: {params: {email: $email} }) {
      success
      errors
    }
  }
`;

export function useForgotPassword(onCompleted) {
  return useMutation(FORGOT_PASSWORD, { errorPolicy: 'all', onCompleted: onCompleted });
}

export const RESET_PASSWORD = gql`
  mutation ResetPassword($password: String!, $passwordConfirmation: String!, $resetPasswordToken: String!) {
    resetPassword(input: {params: {password: $password, passwordConfirmation: $passwordConfirmation, resetPasswordToken: $resetPasswordToken  } }) {
      success
      errors
    }
  }
`;

export function useResetPassword(onCompleted) {
  return useMutation(RESET_PASSWORD, { errorPolicy: 'all', onCompleted: onCompleted });
}