import React from 'react';
import { Box } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser';
import clsx from 'clsx';

const ReadOnly = (props) => {
  const { fontColor, lightning } = props;

  return (
    <Box mb="24px" className={ clsx('component-label', { 'label-lightning': lightning } ) } style={{ color: fontColor }}>
      {ReactHtmlParser(props.content)}
    </Box>
  )
}

const MemoizedComponent = React.memo(ReadOnly);

export default MemoizedComponent;