import React from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
  Grid,
  Link,
  Typography
} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';

import { useStyles } from '../../styles/reflectionDashboard';
import { useNotifyGiftbox } from '../../api/project';
import { useMarkSuggestionAsClicked } from '../../api/reflection';

import useReflectionHandler from '../../hooks/useReflectionHandler';
import useToast from '../../hooks/useToasti';
import ProjectContext from '../../contexts/projectContext';
import ApplicationContext from '../../contexts/applicationContext';

import RoomViewMenu from './RoomViewMenu';
import clsx from 'clsx';

const RoomViewGiftbox = ({ onViewDropdownClick, giftbox }) => {
  const classes = useStyles();
  const { currentProject } = React.useContext(ProjectContext);
  const { currentRoom, setCurrentRoom } = useReflectionHandler();
  const { renderToast } = useToast();
  const { setToastOpen, setToastMessage, setToastColor } = React.useContext(ApplicationContext)
  const [notifyChecked, setNotifyChecked] = React.useState(currentProject.notifyForGiftbox);
  const [dontNotifyChecked, setDontNotifyChecked] = React.useState(!currentProject.notifyForGiftbox);
  const [clickedSuggestion, setClickedSuggestion] = React.useState();

  const [notifyGiftbox, { loading: updatingNotify }] = useNotifyGiftbox(updateSuccess, updateFailed);
  const [markSuggestionAsOpened, {}] = useMarkSuggestionAsClicked(onMarkSuccess);

  const RadioButton = (props) => {
    return (
      <Radio
        className={clsx(classes.radio)}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }

  function onMarkSuccess() {
    const room = {
      ...currentRoom,
      unopenedSuggestions: currentRoom.unopenedSuggestions.filter((c) => c !== clickedSuggestion)
    }
    setCurrentRoom(room);
  }

  const handleRadioChangeNotify = (e) => {
    notifyGiftbox({ variables: { id: currentProject.id, notify_for_giftbox: true } })
    setNotifyChecked(true);
    setDontNotifyChecked(false);
  }

  const handleRadioChangeDontNotify = (e) => {
    notifyGiftbox({ variables: { id: currentProject.id, notify_for_giftbox: false } })
    setNotifyChecked(false);
    setDontNotifyChecked(true);
  }

  function updateSuccess() {
    setToastColor('success');
    setToastOpen(true);
    setToastMessage('Updated Successful!');
  }

  function updateFailed() {
    setToastColor('error');
    setToastOpen(true);
    setToastMessage('Update Failed!');
  }

  function onSuggestionClick(suggestion) {
    setClickedSuggestion(suggestion.id)
    markSuggestionAsOpened({ variables: { id: currentProject.id, suggestion_id: suggestion.id } })
  }

  function renderSolutions() {
    return giftbox.suggestions.map((suggestion, index) => {
      return (
        <Box mb="24px" key={suggestion.id}>
          <Grid container>
            <Grid item xs={2}>
              <Box style={{ fontSize: '36px', lineHeight: '21px', fontWeight: '600', color: '#F98500', marginTop: '10px', marginLeft: '10px' }}>{index + 1}</Box>
            </Grid>
            <Grid item xs={10}>
              <Box style={{ fontSize: '14px', lineHeight: '21px', fontWeight: '600' }}>
                {ReactHtmlParser(suggestion.description)}
              </Box>
              <Box>
                <Link
                  underline="always"
                  onClick={() => onSuggestionClick(suggestion)}
                  href={suggestion.link}
                  target="_blank"
                  rel="noopener"
                >
                  view
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )
    })
  }

  return (
    <Box className={clsx(classes.roomContent)}>
      {renderToast()}
      <RoomViewMenu title="GIFTBOX" onViewDropdownClick={onViewDropdownClick} />

      <Box mb="24px" style={{ lineHeight: '24px' }}>
        Every gift included here is curated with empathy for what you really need to live a better life.
        </Box>
      <Box mb="24px" style={{ lineHeight: '24px' }}>
        By applying our Empathy Genie to our conversation and wish lists, we are able to bring you the latest market innovations most relevant to making your life better.
        </Box>
      <Box mb="24px" style={{ lineHeight: '24px' }}>
        Enjoy!
        </Box>
      <Box mb="44px" style={{ lineHeight: '24px' }}>
        The Empathy Project Team
        </Box>

      <Box mb="32px">
        Top <Typography display="inline" classes={{ root: 'bold' }}>{giftbox.suggestions.length}</Typography> solution(s) we found for <Typography display="inline" classes={{ root: 'bold' }}>{currentProject.firstName}</Typography>:
      </Box>

      {renderSolutions()}

      <Box mb="24px" ml="10px">
        <FormControl component="fieldset">
          <FormGroup>
            <RadioGroup aria-label="notify" name="notify">
              <FormControlLabel
                classes={{ label: classes.notifyLabel }}
                control={<RadioButton disabled={updatingNotify} checked={notifyChecked} onChange={(e) => handleRadioChangeNotify(e)} />}
                label="NOTIFY ME ABOUT MY GIFTBOX"
              />
              <FormControlLabel
                classes={{ label: classes.notifyLabel }}
                control={<RadioButton disabled={updatingNotify} checked={dontNotifyChecked} onChange={(e) => handleRadioChangeDontNotify(e)} />}
                label="NO THANKS, PLS DO NOT NOTIFY ME"
              />
            </RadioGroup>
          </FormGroup>
        </FormControl>
      </Box>
    </Box>
  )
}

export default RoomViewGiftbox;