import React from 'react';
import { Box } from '@material-ui/core'
import { Checkbox } from '@material-ui/core'
import { FormGroup } from '@material-ui/core'
import { FormControlLabel } from '@material-ui/core'
import { FormControl } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser';
import isNil from 'lodash-es/isNil';
import pull from 'lodash-es/pull';

const CheckboxComponent = (props) => {
  const { label, onChange, name, options, row, multi, defaultValue, fontColor, description } = props;
  const [values, setValues] = React.useState(defaultValue)

  function handleChange(option) {
    let newValue;

    if (!!multi) {
      newValue = values.includes(option.identifier) ? pull(values, option.identifier) : [...values, option.identifier];
    } else {
      newValue = defaultValue === option.identifier ? '' : option.identifier;
    }
    setValues(newValue);
    onChange({ field: name, value: newValue })
  }

  // Reset values when default values props are changing
  React.useEffect(() => {
    setValues(defaultValue);
  }, [defaultValue])

  return (
    <Box mb="24px" style={{ color: fontColor }}>
      <FormControl component="fieldset">
        { label && <Box mb="24px" className="component-label" style={{ color: fontColor }}>{ReactHtmlParser(label)}</Box> }
        { description && <Box mb="24px" className="component-label-description" style={{ color: fontColor }}>{ReactHtmlParser(description)}</Box> }
        <FormGroup aria-label="position" row={row}>
          {options.map((option) => {
            return (
              <FormControlLabel
                style={{ alignItems: 'flex-start', marginBottom: '12px' }}
                htmlFor={!!multi ? `${name}-checkbox-${option.id}` : `${name}-checkbox`}
                control={
                  <Checkbox
                    style={{ color: fontColor }}
                    name={name}
                    color="primary"
                    onChange={() => handleChange(option)}
                    id={!!multi ? `${name}-checkbox-${option.id}` : `${name}-checkbox`}
                    checked={
                      !!multi ? !isNil(values) && values.includes(option.identifier) : !isNil(defaultValue) && defaultValue === option.identifier
                    }
                    style={{ padding: '0 9px' }}
                  />
                }
                label={option.title}
                labelPlacement="end"
                key={option.id}
              />
            )
          })}
        </FormGroup>
      </FormControl>
    </Box>
  )
}

const MemoizedComponent = React.memo(CheckboxComponent);

export default MemoizedComponent;