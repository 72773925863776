import * as yup from 'yup';
import isNil from 'lodash-es/isNil';
import omitBy from 'lodash-es/omitBy';

export const requiredField = ({ attribute }) =>
  yup
    .string()
    .nullable(true)
    .trim()
    .required()
    .label(attribute);

export const buildValidationSchema = () => {
  const shape = {
    first_name: requiredField({ attribute: 'First name' }),
    last_name: requiredField({ attribute: 'Last name' })
  };
  return yup.object().shape(omitBy(shape, isNil));
}

export default buildValidationSchema;