import React from 'react';
import isNil from 'lodash-es/isNil';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, TextField, Typography } from '@material-ui/core';

import { useResetPassword } from '../../api/user';
import CustomDialog from '../dialog/CustomDialog';
import useForm from "../../hooks/useForm";
import {buildValidationSchema} from "../../validations/resetPassword";
import ApplicationContext from "../../contexts/applicationContext";

const useStyles = makeStyles(theme => ({
  closeIcon: {
    height: 'auto',
    width: 'auto'
  },
  content: {
    padding: '24px'
  },
  errorContainer: {
    backgroundColor: 'rgb(253, 236, 234)',
    padding: '10px',
    color: 'red',
    borderRadius: '6px',
    marginTop: '10px',
    margin: '0 16px 0 16px'
  }
}));

const ResetPasswordForm = ({ open, onClose, queryParams }) => {
  const defaultState = {
    password: '',
    passwordConfirmation: '',
    resetPasswordToken: queryParams.reset_password_token
  }
  const classes = useStyles();
  const [resetPassword, { error, loading }] = useResetPassword(onCompleted)
  const { setToastOpen, setToastMessage } = React.useContext(ApplicationContext);

  const handleClose = () => {
    onClose();
  };

  function onCompleted(response) {
    if (!isNil(response.resetPassword)) {
      setToastMessage('Password successfully reset.')
      setToastOpen(true);
      onClose();
    }
  }

  function renderError() {
    if (!isNil(error)) {
      return (
        <div className={clsx(classes.errorContainer)}>
          {error.toString()}
        </div>
      )
    }

    return null;
  }

  function submit() {
    resetPassword({ variables: { ...values } })
  }

  const { values, handleSpecificChange, handleSubmit, errors: formErrors } = useForm({
    defaultState,
    validationSchema: buildValidationSchema(),
    submitAction: submit
  })

  return (
    <CustomDialog
      onClose={handleClose}
      open={open}
      fullWidth
      title="RESET PASSWORD"
    >
      {renderError()}
      <Box className={ clsx(classes.content) }>
        <Typography variant="body1" color="primary">
          If you have forgotten your password you can reset it here.
        </Typography>
        <Box my="24px">
          <Box>
            <TextField
              label="Password"
              placeholder="*****************"
              margin="normal"
              fullWidth
              type="password"
              name="password"
              onChange={(event) => handleSpecificChange({ field: 'password', value: event.target.value })}
              error={!isNil(formErrors.password)}
              helperText={formErrors.password}
            />
          </Box>
          <Box>
            <TextField
              label="Confirm Password"
              placeholder="*****************"
              margin="normal"
              fullWidth
              type="password"
              name="passwordConfirmation"
              onChange={(event) => handleSpecificChange({ field: 'passwordConfirmation', value: event.target.value })}
              error={!isNil(formErrors.passwordConfirmation)}
              helperText={formErrors.passwordConfirmation}
            />
          </Box>
          <Box mt="24px" mb="44px">
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
              Reset Password
            </Button>
          </Box>
        </Box>
      </Box>
    </CustomDialog>
  )
}

export default ResetPasswordForm

