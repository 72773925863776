import React from 'react'
import { Box, Button, Grid } from '@material-ui/core'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles'
import ReflectionFooterBackground from 'icons/reflection-footer-bg.png';
import IgniteLogo from 'icons/ignite-logo.svg';
import ExternalLinkIcon from 'icons/external-link-outline.svg';
import EnvelopeIcon from 'icons/reflection-footer-mail.png';

const useStyles = makeStyles({
  container: {
    height: '465px',
    width: '100%',
    backgroundImage: `url(${ReflectionFooterBackground})`,
    backgroundColor: '#000'
  }
});

const ReflectionFooter = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Grid container direction="column" justify="center" alignItems="center"
      style={{ height: '100%' }}>
        <Grid item>The Future of Empathy</Grid>
        <Grid item>
          <Box mt="5px">from</Box>
        </Grid>
        <Grid item><img src={IgniteLogo} /></Grid>
        <Grid item>
          <Box px="50px" my="20px" style={{ textAlign: 'center' }}>
            Uniquely at the nexus of corporate, innovation, venture capital, and igniting all we touch to their greatest growth and impact.
          </Box>
        </Grid>
        <Grid item>
          <Box px="50px" mb="20px">
            You’ve never met anyone like us.
          </Box>
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <Box mb="10px" px="20px" style={{ width: '100%' }}>
            <Button variant="contained" size="small"
              style={{ backgroundColor: '#276EF1', border: '2px solid #276ef1', color: '#fff', padding: '15px', fontSize: '18px' }}
              onClick={() => window.open('https://ignitehouse.vc/', '_blank')}>
              Visit Ignite House
              <Box mx="12px"><img src={ExternalLinkIcon} /></Box>
            </Button>
          </Box>
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <Box px="20px" style={{ width: '100%' }}>
            <Button variant="outlined" size="small" 
              style={{ border: '2px solid #fff', color: '#fff', padding: '15px', fontSize: '18px' }}
              href={`mailto:iggy@ignitehouse.vc`}>
              Contact Us
              <Box mx="12px"><img src={EnvelopeIcon} /></Box>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ReflectionFooter