export const segment = {
  id: 1,
  name: 'Segment 1',
  description: 'Segment Description',
  steps: [
    {
      id: 2,
      title: 'Step 2',
      questions: [
        { 
          type: 'Dynamic Dropdown',
          title: 'How many are you in the family?',
          question_id: 1
        },
        {
          type: 'Sub Question Dropdown',
          title: '',
          description: 'He/She is you?',
          answerOptions: [
            { label: 'Son', value: 'son' }, { label: 'Daugther', value: 'daughter' }
          ]
        },
        {
          type: 'Sub Question Input Text Field',
          title: '',
          description: 'What can you tell about that person?',
          answerOptions: []
        },
      ]
    }
  ]
}

// Add more sub questions here
export const SUB_QUESTION_TYPES = [
  'Sub Question Dropdown',
  'Sub Question Input Text Field',
  'Sub Question Input Text Area',
  'Sub Question Whole Number Field',
  'Sub Question Age Field',
  'Sub Question Dropdown Age Range'
] 