import React, { useEffect, useState } from 'react'
import { Box, Container, Typography } from '@material-ui/core'
import clsx from 'clsx';
import { navigate } from '@reach/router';

import { isAuthenticated } from '../../utils/auth';
import { useStyles } from '../../styles/login';
import LoginForm from '../forms/LoginForm';
import Footer from './Footer'
import ForgotPasswordForm from '../forms/ForgotPasswordForm';
import SignupForm from '../forms/SignupForm';
import ResetPasswordForm from '../forms/ResetPasswordForm';
import useToast from '../../hooks/useToasti';
import useQueryParams from '../../hooks/useQueryParams';

const Login = (props) => {
  const { renderToast } = useToast();
  const queryParams = useQueryParams(props.location && props.location.search)
  const [isForgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const inviteeEmail = queryParams.inviteeEmail || ''
  const isResettingPassword = () => {
    if ((props.location && props.location.state && props.location.state.isResettingPassword) && queryParams.reset_password_token) return true;
  }
  const [isResetPasswordModalOpen, setResetPasswordModalOpen] = useState(isResettingPassword);
  const [isInviteeInbound, setIsInviteeInbound] = useState(false);
  const redirectUrl = () => {
    if(props.location && props.location.state && props.location.state.pathFrom){
      return props.location.state.pathFrom
    } else {
      return 'dashboard'
    }
  }
  const isRegistering = isSignupModalOpen || (isInviteeInbound && queryParams.projectId.length !== 0);
  // Navigate user back to dashboard if
  // was accessed while still login
  useEffect(() => {
    if(isAuthenticated()) navigate('dashboard');
    if(queryParams.inviteeInbound === 'true' && queryParams.projectId && inviteeEmail) setIsInviteeInbound(true);
  }, [])

  // Dont render anything here if authenticated already
  if (isAuthenticated()) return null;

  function setForgotModalVisible() {
    setForgotPasswordModalOpen(!isForgotPasswordModalOpen);
  }

  function setSignupModalVisible() {
    setIsSignupModalOpen(true);
  }

  function setSignupModalInvisible() {
    setIsSignupModalOpen(false);
    setIsInviteeInbound(false);
  }

  function setResetPasswordModalVisible(){
    setResetPasswordModalOpen(!isResetPasswordModalOpen);
  }

  // This is to force forgot password modal form to unmount
  // so everything will be reset, looked like material ui
  // does not unmounts the child component on close.
  function renderForgotPasswordModal() {
    if (!isForgotPasswordModalOpen) return null;
    return <ForgotPasswordForm open={isForgotPasswordModalOpen} onClose={setForgotModalVisible} />
  }

  // See line 37
  function renderSignupdModal() {
    // if (!isSignupModalOpen) return null;
    return <SignupForm open={isRegistering} onClose={setSignupModalInvisible} projectId={queryParams.projectId} inviteeEmail={inviteeEmail} />
  }

  function renderResetPasswordModal() {
    if (!isResetPasswordModalOpen) return null;
    return <ResetPasswordForm open={isResetPasswordModalOpen} onClose={setResetPasswordModalVisible} queryParams={queryParams}/>
  }

  return (
    <div className={clsx('page-container')}>
      {renderToast()}
      <Container maxWidth="sm">
        <Box mt="54px">
          <Typography variant="h2" align="center" className={ clsx('lora') }>The Empathy</Typography>
          <Typography variant="h2" align="center" className={ clsx('lora') }>Project</Typography>
        </Box>
        <LoginForm pathFrom={redirectUrl()} onForgotPasswordClick={setForgotModalVisible} onSignupClick={setSignupModalVisible} />
        {renderForgotPasswordModal()}
        {renderSignupdModal()}
        {renderResetPasswordModal()}
        <Box mt="64px">
          <Footer />
        </Box>
      </Container>
    </div>
  )
}

export default Login
