import React from 'react';

import ProjectContext from '../contexts/projectContext';
import useProjectContextUpdater from '../hooks/useProjectContextUpdater';

const ProjectProvider = ({ children }) => {
  const projectUpdater = useProjectContextUpdater();

  return (
    <ProjectContext.Provider value={projectUpdater}>
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
