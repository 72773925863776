import React from 'react'
import { AppBar, Box, Button, Container, Divider, Drawer, Grid, Icon, Link, List, Toolbar, Typography } from '@material-ui/core'
import clsx from 'clsx';

import { useStyles } from '../../styles/reflection';

import HamburgerIcon from 'icons/hamburger.svg';
import RibbonIcon from 'icons/ribbon.svg';
import ThunderCloudIcon from 'icons/thunder-cloud.svg';
import ThoughtBubbleIcon from 'icons/thought-bubble.svg';
import PlusClockIcon from 'icons/plus-clock.svg';
import MinusClockIcon from 'icons/minus-clock.svg';
import {navigate} from "@reach/router";
import ProjectContext from '../../contexts/projectContext';
import BurgerMenu from "../common/BurgerMenu";

const ReflectionLetterMyself = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { currentProject } = React.useContext(ProjectContext);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const onNext = () => {
    navigate(`/project/${currentProject.slug}/reflection/view`)
  }

  return (
    <div className={clsx('page-container', classes.root)}>
      <Container disableGutters={true} maxWidth="sm">
        
        <Box className={clsx(classes.reflectionLetterContent)} pb="16px">
          <Grid container direction="column">
            <Grid item>
              <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
                color="default"
                style={{ background: `#8FC8F5`, padding: `24px 16px 0 16px`, borderBottom: `1px solid #595959` }}
              >
              <Toolbar disableGutters={true} style={{ justifyContent: 'space-between' }}>
                <Box>
                  <Typography variant="h6" className={clsx('lora')} color="primary">
                    The Empathy Project
                  </Typography>
                </Box>
                <Box><Icon fontSize="large" onClick={handleDrawerOpen}><img src={HamburgerIcon} /></Icon></Box>
              </Toolbar>
            </AppBar>
            <BurgerMenu
              open={open}
              setOpen={setOpen}
              currentProject={currentProject}
            />
            </Grid>
            <Grid item>
              <Box className={clsx(classes.letterContainer, classes.coverLetter)}>
                <Box className={clsx(classes.ribbonContainer)}>
                  <img src={RibbonIcon} />
                </Box>
                <Box mb="24px" style={{ fontSize: '18px', lineHeight: '24px' }}>
                  An Open Letter to Myself
                </Box>
                <Box mb="24px" className={clsx(classes.fewPages)} style={{ fontWeight: 'normal' }}>
                  My <span className={clsx('bold')}> time, energy </span> (physical, mental, emotional, spiritual) and <span className={clsx('bold')}> resources </span> (talent, money, belongings) are limited. 
                </Box>
                <Box mb="24px" className={clsx(classes.fewPages, 'bold')}>
                  I have but one life to live. 
                </Box>
                <Box mb="44px">
                  <Grid container>
                    <Grid item xs={2}>
                      <img src={ThunderCloudIcon} />
                    </Grid>
                    <Grid item xs={9} className={clsx(classes.thoughts)}>
                      Am I spending <span className={clsx('bold')}> my time, energy and resources </span>
                       on activities that have the most impact  on my well-being and happiness  and those of the people I love? 
                    </Grid>
                  </Grid>
                </Box>
                <Box mb="44px">
                  <Grid container>
                    <Grid item xs={2}>
                      <img src={ThoughtBubbleIcon} />
                    </Grid>
                    <Grid item xs={9} className={clsx(classes.thoughts)}>
                      Where and how do I need to reallocate <span className={clsx('bold')}> my time, energy and resources </span> in order to improve well-being and happiness? 
                    </Grid>
                  </Grid>
                </Box>
                <Box mb="44px">
                  <Grid container>
                    <Grid item xs={2}>
                      <img src={PlusClockIcon} />
                    </Grid>
                    <Grid item xs={9} className={clsx(classes.thoughts)}>
                      Where, concretely, do I need to spend <span className={clsx('bold')}> more of my time, energy </span> and <span className={clsx('bold')}> resources</span>? 
                    </Grid>
                  </Grid>
                </Box>
                <Box mb="44px">
                  <Grid container>
                    <Grid item xs={2}>
                      <img src={MinusClockIcon} />
                    </Grid>
                    <Grid item xs={9} className={clsx(classes.thoughts)}>
                      Where, concretely, do I need to spend <span className={clsx('bold')}> less of my time, energy </span> and <span className={clsx('bold')}> resources</span>?
                    </Grid>
                  </Grid>
                </Box>
                <Box mb="24px" className={clsx(classes.fewPages)}>
                  In these next few pages I will be able to clarify these questions for myself. 
                </Box>
                <Box mb="24px" className={clsx(classes.fewPages)}>
                  Then I will find an unstoppable gift that keeps giving. 
                </Box>
                <Box className={clsx(classes.fewPages)}>
                  Love,
                </Box>
                <Box>
                  { currentProject?.firstName }
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box style={{ margin: `0 16px 34px 16px` }}>
                <Button variant="contained" color="primary" onClick={onNext}>
                  Next
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

      </Container>
    </div>
  )
}

export default ReflectionLetterMyself;
