import React from 'react';
import { Box, Button, Container, Grid, Link, Paper } from '@material-ui/core';
import clsx from 'clsx';
import { navigate } from '@reach/router';
import Styles from '../../styles/payForward';
import Slide from '@material-ui/core/Slide';

import ProjectContext from '../../contexts/projectContext';
import ShareThisPage from './ShareThisPage';

const PayForwardReflection = () => {
  const classes = Styles();
  const { currentProject } = React.useContext(ProjectContext);
  const [ shareModalOpen, setShareModalOpen ] = React.useState(false);

  function handleNext() {
    navigate(`/project/${currentProject.slug}/intaker/thank-you`)
  }

  function shareModalVisible() {
    setShareModalOpen(!shareModalOpen)
  }

  function renderShareModal() {
    if(!shareModalOpen) return null;
    return <ShareThisPage
      open={shareModalOpen}
      onClose={shareModalVisible}
      currentProject={currentProject} handleNext={handleNext}/>
  }
  
  return (
    <Slide direction={"left"} in={true} mountOnEnter unmountOnExit>
      <Container maxWidth="sm" className="container-with-gutter">
        <Paper elevation={3} className={ clsx(classes.payForward) } my="16px">
          <Grid container m="16px" className={clsx('full-height')} component="div" direction="column" alignItems="center" justify="center">
            <Grid item style={{ width: '100%' }}>
              <Box mb="24px" mx="16px" mt="32px">
                <Box mb="24px" className="component-label">
                  I would also like to send you a link that will allow you to start your own Empathy Project with your circle of friends. It's our way of paying it forward. 
                </Box>
                <Box mb="24px" className="component-label">
                  Is it ok to send you this link?
                </Box>
              </Box>
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <Box mx="16px" style={{ marginBottom: '24px', marginTop: '44px' }}>
                <Button variant="contained" color="primary" onClick={shareModalVisible}>
                  Send Now
                </Button>
              </Box>
              <Box className={ clsx('component-label') } style={{ textAlign: 'center' }}>
                <Link href="#" underline="always" onClick={handleNext}>
                  No Thanks
                </Link>
              </Box>
            </Grid>
          </Grid>'
          {renderShareModal()}
        </Paper>
      </Container>
    </Slide>
  )
}

export default PayForwardReflection;