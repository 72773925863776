import React from 'react';
import {Box, Container, Grid, Paper } from '@material-ui/core';
import clsx from 'clsx';
import Styles from '../../styles/payForward';
import Slide from '@material-ui/core/Slide';

import PayForwardReflectionForm from "../forms/PayForwardReflectionForm";

const PayForwardReflection = () => {
  const classes = Styles();

  return (
    <Slide direction={"left"} in={true} mountOnEnter unmountOnExit>
      <Container maxWidth="sm" className="container-with-gutter">
        <Paper elevation={3} className={ clsx(classes.payForward) } my="16px">
          <Grid container m="16px" className={clsx('full-height')} component="div" direction="column" alignItems="center" justify="center">
            <Grid item style={{ width: '100%' }}>
              <Box mb="24px" mx="16px" mt="32px">
                <Box mb="24px" className="component-label">
                  Congratulations. We've completed our Empathy Project!
                </Box>
                <Box mb="24px" className="component-label">
                  From here, our Empathy Genie uses software intelligence to auto-generate <Box fontStyle='italic' display='inline'>Reflection Sheets</Box> that will arrive in your inbox in a few seconds.
                </Box>
                <Box mb="24px" className="component-label">
                  These Reflection Sheets are really something to look forward to - the most exciting part of our project!
                </Box>
                <Box mb="24px" className="component-label" style={{ fontWeight: 'bold' }}>
                  <strong>They will present our conversation as a visual story consisting of 15 rooms. They are the rooms of your life.</strong>
                </Box>
                <Box mb="24px" className="component-label">
                  Plese expect the reflection Reflection Sheets in your email.
                </Box>
              </Box>
            </Grid>
            <PayForwardReflectionForm />
          </Grid>
        </Paper>
      </Container>
    </Slide>
  )
}

export default PayForwardReflection;