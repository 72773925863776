import React from 'react';
import {
  Box,
  Button,
  Container,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from '@reach/router';
import clsx from 'clsx';
import Background from 'icons/background.svg';

const useStyles = makeStyles({
  root: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'top center'
  },
  h2: {
    textAlign: 'center'
  },
  layout: {
    minHeight: '100vh',
    padding: '16px'
  }
});

const DataPrivacy = () => {
  const classes = useStyles();

  const onNext = () => {
    navigate(`/terms`);
  }

  return (
    <div className={ clsx('page-container', classes.root) }>
      <Container maxWidth="sm" className={ clsx(classes.layout) }>
        <Box mt="64px">
          <Typography variant="h2" className={ clsx(classes.h2, 'lora') }>The Empathy<br/>Project</Typography>
        </Box>
        <Box mt="44px" mb="24px" style={{ textAlign: 'center' }}>
          <Typography variant="body1" className="bold">PRIVACY POLICY</Typography>
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Introduction</Typography>
        </Box>
        <Box mb="24px">
          1. This Privacy Policy is governed by the Terms of Use (the “Terms”) and applies to our website, our mobile application (collectively, the “Site”), and the content, products and services (the “Services“) available therein. This Privacy Policy explains what personal information we collect from the users of the Site, how we use or process that information, and what choices you have with respect to the information we collect about you.
        </Box>
        <Box mb="24px">
          2. Our registered users (the “Members”) share their identities, engage with their network, exchange knowledge and insights, post and view relevant content, and learn and find opportunities. Content on some of our Services is available to non-members (the “Visitors”).
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Consent</Typography>
        </Box>
        <Box mb="24px">
          3. By signing up and creating an account, and/or by your continued use of our Services, you are deemed to have agreed to this Privacy Policy and consent to the collection, use and sharing of your personal information, such as, but not limited to, your name, age, date of birth and gender, in accordance with the Terms and Privacy Policy. [Meer: Please add a box for the user to check, as confirmation of consent.]
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Change</Typography>
        </Box>
        <Box mb="24px">
          4. We may modify this Privacy Policy from time to time. Should we make material changes to the Privacy Policy, we will provide notice through our Services or by other means to provide you the opportunity to review the changes at least five days before they become effective. If you object to any changes, you may close your account and discontinue using the Services. Your continued use of our Services after any changes to this Privacy Policy means that you are consenting to the updated Privacy Policy. Please note that our right to use your personal data will be based on the Privacy Policy in effect at the time the information is used. [Meer: Please add a box for the user to check, as confirmation of consent to any changes.]
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Information We Collect</Typography>
        </Box>

        <Box mb="24px">
          <Typography variant="body2" display="inline" className="bold">A. </Typography>
          <Typography variant="body2" underline="always" display="inline" className={clsx('bold', 'underline')}>Information You Provide</Typography>
        </Box>
        <Box mb="24px" style={{ fontStyle: 'italic' }}>
          Registration
        </Box>
        <Box mb="24px" ml="24px">
          5. To create an account, you provide data which includes your name, email address and/or mobile number and password. If you register for Premium Services and Premium Subscriptions, we may ask for additional information from you, such as credit or financial information, which are necessary to facilitate such registration. 
        </Box>
        <Box mb="24px" style={{ fontStyle: 'italic' }}>
          Profile
        </Box>
        <Box mb="24px" ml="24px">
          6. You may choose the information to display on your profile, including but not limited to, your education, work experience, skills, photos or videos, city or area where you reside and/or are based, and endorsements. Profile information helps you get more from our Services, including building a network of partners and collaborators, and helping recruiters and business opportunities find you. It is your choice whether or not to include sensitive information on your profile.
        </Box>
        <Box mb="24px" style={{ fontStyle: 'italic' }}>
          Posting and Uploading
        </Box>
        <Box mb="24px" ml="24px">
          7. We collect personal data from you when you provide, post or upload it to our Services, including but not limited to, instances when you fill out a form, respond to a survey, submit a resume or apply for or save jobs or send invitations.
        </Box>

        <Box mb="24px" className="bold">
          B. <span style={{ textDecoration: 'underline' }}>Information from Others</span>
        </Box>
        <Box mb="24px" style={{ fontStyle: 'italic' }}>
          Content and News
        </Box>
        <Box mb="24px" ml="24px">
          8. You and others may post content that includes information about you on our Services (as part of blog posts, events, feed updates, comments, photos, videos, etc.). Unless you opt out, we may collect public information about you, such as images and quotes taken during events, professional-related news and accomplishments (e.g. patents granted, professional recognition, conference speakers, projects, etc.) and make it available as part of our Services (e.g. suggestions for your profile, or notifications of mentions in the news.)
        </Box>
        <Box mb="24px" ml="24px">
          9. We may also receive personal data about you when you use the services of our customers and partners.
        </Box>

        <Box mb="24px" className="bold">
          C. <span style={{ textDecoration: 'underline' }}>Messages</span>
        </Box>
        <Box mb="24px" ml="24px">
          10. We may collect information about you when you send, receive, or engage in public messages in connection with our Services. However, private messages shall remain strictly confidential.
        </Box>

        <Box mb="24px" className="bold">
          D. <span style={{ textDecoration: 'underline' }}>Events</span>
        </Box>
        <Box mb="24px" ml="24px">
          11. We may also collect information about you when you participate in our events, such as, among others, photos, videos, quotes, and posts, which we may use in connection with our Services.
        </Box>

        <Box mb="24px" className="bold">
          E. <span style={{ textDecoration: 'underline' }}>Others</span>
        </Box>
        <Box mb="24px" ml="24px">
          12. Our Services are dynamic and we often introduce new features, which may require the collection of new information. If we collect materially different personal data or materially change how we use your data, we shall notify you of changes in the Privacy Policy. If you do not consent to the material changes with respect to the personal data collected or the use thereof, you may close your account and discontinue using the Services. Your continued use of the new features our Services and willing disclosure of new information necessary for such features shall mean that you are consenting to such changes, and to the updated Privacy Policy. 
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">How We Use Your Data</Typography>
        </Box>

        <Box mb="24px" className="bold">
          A. <span style={{ textDecoration: 'underline' }}>Access to Our Services</span>
        </Box>
        <Box mb="24px" ml="24px">
          13. We may use your data to authenticate and authorize you to access our Services. Our Services allow you to stay in touch, in communication and up to date with colleagues, partners, clients, and other professional and personal contacts. Our Services also allow you to explore careers or vocations, seek out and evaluate opportunities, collaborate with colleagues, and search for potential clients, customers, partners, among others.
        </Box>

        <Box mb="24px" className="bold">
          B. <span style={{ textDecoration: 'underline' }}>Communications</span>
        </Box>
        <Box mb="24px" ml="24px">
          14. We may contact you through email, notices, notifications, and other ways through our Site and Services, including text messages. We may send you messages about the availability of our Services, security, or other service-related issues. You may change your communication preferences at any time.
        </Box>

        <Box mb="24px" className="bold">
          C. <span style={{ textDecoration: 'underline' }}>Advertising, Marketing and Related Services</span>
        </Box>
        <Box mb="24px" ml="24px">
          15. We may use and process data and information provided by Members to convert the same into aggregate or collective anonymized data (“Collective Data”), for research, case studies, statistical analysis, or other similar purposes, which will help promote the expansion and use of our Site and/or Services. 
        </Box>
        <Box mb="24px" ml="24px">
          16. We may use data and information about Members for invitations, communications, advertising and/or marketing for the purpose of promoting memberships, networks, and patronization or engagement of some of our Services. We also use such Collective Data to promote our Site and/or Services through the conduct of presentations, seminars, or workshops.
        </Box>

        <Box mb="24px" className="bold">
          D. <span style={{ textDecoration: 'underline' }}>Customer Support</span>
        </Box>
        <Box mb="24px" ml="24px">
          17. We may use the data provided by you to respond to and resolve complaints and Service issues (e.g., bugs).
        </Box>

        <Box mb="24px" className="bold">
          D. <span style={{ textDecoration: 'underline' }}>Security and Investigations</span>
        </Box>
        <Box mb="24px" ml="24px">
          18. We may use your data (including your communications) if we think it is necessary for security purposes or to investigate possible fraud or other violations of our Terms or this Privacy Policy.
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">How We Share Information</Typography>
        </Box>

        <Box mb="24px" className="bold">
          A. <span style={{ textDecoration: 'underline' }}>Our Services</span>
        </Box>
        <Box mb="24px" ml="24px">
          19. The information and data you share in using our Services will be accessible to other Members, Visitors, or other persons or entities who participate in presentations, seminars, workshops, or other similar events or projects.
        </Box>
        <Box mb="24px" ml="24px">
          20. We shall not sell or share your personal data with other persons or third parties except as provided in this Privacy Policy.
        </Box>

        <Box mb="24px" className="bold">
          B. <span style={{ textDecoration: 'underline' }}>Related Services</span>
        </Box>
        <Box mb="24px" ml="24px">
          21. Upon your acceptance of the Privacy Policy or use of our Site and/or Services, we may share your personal data, particularly Collective Data, with third-party advertisers, networks, or affiliates to improve, develop, or provide our Services.
        </Box>

        <Box mb="24px" className="bold">
          C. <span style={{ textDecoration: 'underline' }}>Third-Party Service Providers</span>
        </Box>
        <Box mb="24px" ml="24px">
          22. We utilize the services of other persons or entities not associated or affiliated with Ignite Founders Ventures (“Third-Party Service Providers”) to help us improve, develop, or provide our Site or Services, which may include, but is not limited to, maintenance, analysis, audit, payments, fraud detection, marketing and development. They will have access to your information as reasonably necessary to perform these tasks on our behalf and shall be obligated to not to disclose or use it for other purposes. [Meer: We note that sharing of data should be reviewed on a case-to-case basis.]
        </Box>
        <Box mb="24px" ml="24px">
          23. We may share Collective Data to Third-Party Service Providers who may utilize the same for their own purposes. We ensure that the information and data they collect from us will be anonymous and will not contain personal, sensitive, and/or identifiable information or data of Members. 
        </Box>

        <Box mb="24px" className="bold">
          D. <span style={{ textDecoration: 'underline' }}>Legal Disclosures</span>
        </Box>
        <Box mb="24px" ml="24px">
          24. It is possible that we will need to disclose information about you when required by law, subpoena, or other legal processes or if the disclosure is reasonably necessary to: 
        </Box>
        <Box mb="24px" ml="48px">
          (a) investigate, prevent, or take action regarding suspected or actual illegal activities or to assist government enforcement agencies;
        </Box>
        <Box mb="24px" ml="48px">
          (b) enforce our agreements with you; 
        </Box>
        <Box mb="24px" ml="48px">
          (c) investigate and defend ourselves against any third-party claims or allegations; 
        </Box>
        <Box mb="24px" ml="48px">
          (d) protect the security or integrity of our Services (such as by sharing with companies facing similar threats); or 
        </Box>
        <Box mb="24px" ml="48px">
          (e) exercise or protect the rights and safety of Ignite Founders Ventures, our Members, personnel, or others. 
        </Box>

        <Box mb="24px">
          We will notify Members about legal demands for their personal data when appropriate in our judgment, unless prohibited by law or court order or when the request is an emergency. We may dispute such demands when we believe, in our discretion, that the requests are overbroad, vague, or lack proper authority, but we do not promise to challenge every demand.
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Data Protection</Typography>
        </Box>

        <Box mb="24px" ml="24px">
          25. We guarantee that we shall exert diligent efforts to protect and safeguard your personal information and ensure that the same is used only for the purposes indicated in the Terms or Privacy Policy and other purposes required by law.
        </Box>
        <Box mb="24px" ml="24px">
          26. We shall implement reasonable and appropriate security measures to protect the personal data you submitted from loss, unauthorized access, use, or disclosure in accordance with the provisions of the Data Privacy Act, its rules and regulations, and other related laws and issuances. 
        </Box>
        <Box mb="24px" ml="24px">
          27. Notwithstanding the foregoing provisions on Data Protection, you understand that there may be circumstances beyond our reasonable control that may result in the instability or insufficiency of said measures. You agree that, under such circumstances, we shall not be liable or responsible for any loss, unauthorized access, use or disclosure of any information that we may have collected.
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Your Choices and Obligations</Typography>
        </Box>

        <Box mb="24px" className="bold">
          A. <span style={{ textDecoration: 'underline' }}>Data Retention</span>
        </Box>
        <Box mb="24px" ml="24px">
          28. We retain the personal data you provide while your account is in existence or as needed to provide you our Services.
        </Box>
        <Box mb="24px" className="bold">
          B. <span style={{ textDecoration: 'underline' }}>User Access and Control over Personal Data</span>
        </Box>
        <Box mb="24px" ml="24px">
          29. You are in control of any personal data you submit and provide to us through this Site. If you would like to access, modify, correct, or update the personal data we have about you, if you would like to change your preferences for communications from us, or if you would like to withdraw the personal data that you have provided to us, you can let us know by contacting us through the Contact Information provided below and detailing the nature and extent of your requests to us.
        </Box>
        <Box mb="24px" className="bold">
          C. <span style={{ textDecoration: 'underline' }}>Account Closure</span>
        </Box>
        <Box mb="24px" ml="24px">
          30. If you choose to close your account, your personal data will generally stop being visible to others on our Services within twenty-four (24) hours. We generally delete closed account information within thirty (30) days of account closure, except as noted below. Your personal data will be disposed or discarded in a secure and practical manner we may deem appropriate.
        </Box>
        <Box mb="24px" ml="24px">
          31. We may retain your personal data even after you have closed your account if reasonably necessary to comply with our legal obligations (including law enforcement requests), meet regulatory requirements, resolve disputes, maintain security, prevent fraud and abuse, enforce our Terms, or fulfill your request to “unsubscribe” from further messages from us. We will retain de-personalized information after your account has been closed.
        </Box>
        <Box mb="24px" ml="24px">
          32. Information you have shared with others will remain visible after you have closed your account or deleted the information from your own profile or mailbox, and we do not control data that other Members copied out of our Services.
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Links</Typography>
        </Box>

        <Box mb="24px" ml="24px">
          33. This Site may contain links to or from websites other than those operated by us or our affiliates. Please be aware that we are not responsible for the privacy policies of other websites. This Privacy Policy applies only to the information we collect on this Site. You are encouraged to read the privacy policies of other websites that you link to or from this Site.
        </Box>

        <Box mb="24px">
          <Typography variant="body1" className="bold">Transfer of Personal Data outside Philippines</Typography>
        </Box>

        <Box mb="24px" ml="24px">
          34. As our servers may be located in other jurisdictions, your personal data may be transferred to, stored, used and processed in a jurisdiction other than Philippines. By accessing this Site and submitting your personal data to us, you acknowledge and consent to the transfer of your personal data outside Philippines.
        </Box>

        <Box mb="24px">
          <Button variant="contained" color="primary" onClick={onNext}>
            Next
          </Button>
        </Box>

      </Container>
    </div>
  )
}

export default DataPrivacy;
