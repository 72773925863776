import React from 'react';

import ReflectionLetterMyself from '../reflection/ReflectionLetterMyself';
import WithProjectTemplate from '../common/WithProjectTemplate'

const ReflectionLetterMyselfPage = () => {
  return (
    <WithProjectTemplate>
      <ReflectionLetterMyself/>
    </WithProjectTemplate>
  )
}

export default ReflectionLetterMyselfPage;