import React, { useEffect, useState } from 'react';
import isNil from 'lodash-es/isNil';
import { Box, Button, Grid, Link, Typography, TextField } from '@material-ui/core'
import clsx from 'clsx';
import { navigate } from '@reach/router';

import { authenticate } from '../../utils/auth';
import { useStyles } from '../../styles/login';
import { useAuthenticateUser } from '../../api/user';
import useForm from '../../hooks/useForm';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

const LoginForm = ({ onForgotPasswordClick, onSignupClick, pathFrom }) => {
  const defaultState = {
    email: '',
    password: ''
  }

  const preventDefault = (event) => event.preventDefault();
  const [authenticateUser, { error, loading }] = useAuthenticateUser(onCompleted);
  const classes = useStyles();
  const [originPath] = useState(pathFrom);

  function submit(facebookObj = {}) {
    if (facebookObj) {
      facebookObj.provider = 'facebook'
      facebookObj.provider_id = facebookObj.id
    };
    authenticateUser({ variables: { ...values, ...facebookObj } })
  }

  function onCompleted(response) {
    if (isNil(response) || isNil(response.signIn)) return;

    if (!isNil(response.signIn.user)) {
      authenticate(response.signIn.user.authenticationToken, response.signIn.user);
      navigate(originPath);
    }

    if (!isNil(response.signIn.errors)) {
      setErrors(response.signIn.errors)
    }
  }

  function renderError() {
    if (!isNil(error)) {
      return (
        <div className={clsx(classes.errorContainer)}>
          Oops! Something went wrong. Might be a network error.
        </div>
      )
    }

    return null;
  }

  function handleForgotPasswordClick(event) {
    preventDefault(event);
    onForgotPasswordClick();
  }

  function handleSignUpClick(event) {
    preventDefault(event);
    onSignupClick();
  }

  const { handleSpecificChange, handleSubmit, values, setErrors, errors } = useForm({
    defaultState,
    submitAction: submit
  })

  const handleFacebookResponse = (response) => {
    submit(response);
  }

  return (
    <>
      {renderError()}
      <Box mt="64px" px="16px">
        <TextField
          label="Email"
          placeholder="ex. User@email.com"
          margin="normal"
          fullWidth
          name="email"
          onChange={(event) => handleSpecificChange({ field: 'email', value: event.target.value })}
          error={!isNil(errors.email)}
          helperText={errors.email}
        />
      </Box>
      <Box px="16px">
        <TextField
          label="Password"
          placeholder="*****************"
          margin="normal"
          fullWidth
          type="password"
          name="password"
          onChange={(event) => handleSpecificChange({ field: 'password', value: event.target.value })}
          error={!isNil(errors.password)}
          helperText={errors.password}
        />
      </Box>
      <Box px="16px" component="div" textAlign="right">
        <Link className={ clsx('bold') } href="#" variant="subtitle2" onClick={handleForgotPasswordClick}>
          FORGOT PASSWORD?
        </Link>
      </Box>
      <Box mt="24px">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" disabled={loading} onClick={handleSubmit}>
              Login
            </Button>
          </Grid>
          <Grid item>
            <FacebookLogin
              appId={process.env.FACEBOOK_APP_ID}
              callback={handleFacebookResponse}
              fields='first_name,last_name,email'
              disableMobileRedirect={true}
              render={renderProps => (
                <Button variant="contained" color="primary" className={clsx(classes.fbButton)} onClick={renderProps.onClick}>
                  Continue with Facebook
                </Button>
              )}/>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container direction="column" spacing={2} alignItems="center">
          <Grid item mb="24px">
            <Typography variant="body1">or</Typography>
          </Grid>
          <Grid item>
            <Link className={clsx('bold')} href="#" variant="body1" underline="always" onClick={handleSignUpClick}>
              signup
            </Link>
          </Grid>
          <Grid container direction="row" justify="center">
            <Link href="/terms" variant="body2" underline="always">TOU</Link>
            <Box mx="5px"><Typography variant="body2">&</Typography></Box>
            <Link href="/privacy-policy" variant="body2" underline="always">PRIVACY</Link>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default LoginForm;