import React from 'react';
import {
  Box,
  Button
} from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser';
import isNil from 'lodash-es/isNil';

import useProjectGraphqlHandler from '../../hooks/useProjectGqlHandler';
import Dropdown from '../inputs/Dropdown';

import { SUB_QUESTION_TYPES } from '../../utils/segment';

const DynamicDropdown = (props) => {
  const { onChange, questions, defaultValue, label, fontColor } = props;
  const [values, setValues] = React.useState(defaultValue);
  const [parentQuestion, setParentQuestion] = React.useState();
  const {
    sortAnswerOptions
  } = useProjectGraphqlHandler();

  // Reset values when default values props are changing
  React.useEffect(() => {
    if (!isNil(defaultValue)) setValues(defaultValue);
  }, [defaultValue]);

  // Set parent question first
  React.useEffect(() => {
    const parentQuestion = questions.find((q) => !SUB_QUESTION_TYPES.includes(q.questionType));
    setParentQuestion(parentQuestion);
  }, [])

  // Monitor defaultValue
  React.useEffect(() => {
    if (!isNil(values) && !isNil(parentQuestion)) {
      const currentValue = values[parentQuestion.id];
      if (isNil(currentValue)) {
        const value = {};
        value[parentQuestion.id] = { value: null, count: 1, subQuestionAnswers: {} }
        setValues(value);
      }
    }
  }, [parentQuestion])

  // Count of subquestions should be based on the selected parent answer
  // If possible request this to backend devs to provide attribute that can be used
  // Right now, we based the count using the selected index of matched identifier
  function handleParentChange(event) {
    const value = event.value === '' ? '' : event.value;
    const count = () => {
      const optionIndex = parentQuestion.answerOptions.findIndex((option) => option.identifier === value);
      return parseInt(optionIndex + 1);
    }

    const newValue = {
      [parentQuestion.id]: { value: value, count: count(), subQuestionAnswers: {} }
    };
    setValues(newValue)
    onChange({ field: parentQuestion.id, value: newValue })
  }

  // This needs refactoring
  const handleSubQuestionChange = (event, index, subQuestion) => {
    let newSubQuestionAnswers = {};
    newSubQuestionAnswers[index] = { ...values[parentQuestion.id].subQuestionAnswers[index], [subQuestion.id]: event.value };
    const newValue = { [parentQuestion.id]: { ...values[parentQuestion.id], subQuestionAnswers: { ...values[parentQuestion.id].subQuestionAnswers, ...newSubQuestionAnswers } } };
    setValues(newValue);
    onChange({ field: parentQuestion.id, value: newValue })
  }

  function showAddMore() {
    if (isNil(values) || isNil(parentQuestion) || isNil(values[parentQuestion.id])) return false;
    return values[parentQuestion.id].value === parentQuestion.answerOptions[parentQuestion.answerOptions.length - 1].value
  }

  function handleAddMore() {
    const newValue = {
      [parentQuestion.id]: {
        ...values[parentQuestion.id],
        count: values[parentQuestion.id].count + 1
      }
    };
    setValues(newValue)
    onChange({ field: parentQuestion.id, value: newValue })
  }

  function renderParentQuestion() {
    if (!isNil(parentQuestion)) {
      return (
        <Dropdown
          onChange={(event) => handleParentChange(event)}
          label={parentQuestion.title}
          name={parentQuestion.id}
          options={sortAnswerOptions(parentQuestion.answerOptions)}
          defaultValue={!isNil(values) && !isNil(values[parentQuestion.id]) ? values[parentQuestion.id].value : null}
        />
      )
    }

    return null;
  }

  function subQuestionValue(sq, index) {
    if (isNil(values)) return null;
    if (isNil(values[parentQuestion.id]['subQuestionAnswers'][index])) return null;
    return values[parentQuestion.id]['subQuestionAnswers'][index][sq.id];
  }

  // TODO: Refactor this
  function renderSubQuestions() {
    if (isNil(values) || isNil(parentQuestion) || isNil(values[parentQuestion.id])) {
      return null;
    }

    // Needed to put in an array so that we can render by (n) of selection
    const timesToRender = isNil(values[parentQuestion.id].value) ? 1 : values[parentQuestion.id].count;

    //if (!isNil(values[parentQuestion.id].value)) {
    let subQuestions = questions.filter((q) => SUB_QUESTION_TYPES.includes(q.questionType));
    subQuestions = subQuestions.sort((a, b) => a.displayOrder - b.displayOrder);

    //const timesToRender = values[parentQuestion.id].count;
    const items = [];
    for (let x = 0; x < timesToRender; x++) {
      items.push(x);
    }

    const renderInputs = (index) => {
      // Now render how many times to render subquestions
      return subQuestions.map((sq, sqIndex) => {
        if (sq.questionType === 'Sub Question Dropdown') {
          return <Dropdown
            key={`${sq.questionType}-${sqIndex}`}
            onChange={(event) => handleSubQuestionChange(event, index, sq)}
            description={sq.description}
            label={sq.title}
            name={sq.id}
            options={sortAnswerOptions(sq.answerOptions)}
            defaultValue={subQuestionValue(sq, index)}
          />
        } else if (sq.questionType === 'Sub Question Dropdown Age Range') {
          return <Dropdown
            key={`${sq.questionType}-${sqIndex}`}
            onChange={(event) => handleSubQuestionChange(event, index, sq)}
            description={sq.description}
            label={sq.title}
            name={sq.id}
            options={sortAnswerOptions(sq.answerOptions)}
            defaultValue={subQuestionValue(sq, index)}
          />
        } else {
          return null;
        }
      })
    }

    return items.map((_, index) => {
      return (
        <div key={`div-${index}`}>
          <Box className="component-label-description" style={{ color: fontColor, marginBottom: '12px' }}>
            {`Person ${index + 1}`}
          </Box>
          <Box mb="55px" component="div">
            {renderInputs(index)}
          </Box>
        </div>
      )
    })
    //}

    //return null;
  }

  return (
    <Box mb="24px">
      <Box mb="24px" className="component-label">{ReactHtmlParser(label)}</Box>
      {renderParentQuestion()}
      {renderSubQuestions()}

      {showAddMore() && (
        <Button variant="outlined" color="primary" onClick={handleAddMore} size="small" style={{ width: 'auto' }}>
          Add More
        </Button>
      )}
    </Box>
  )
}

const MemoizedComponent = React.memo(DynamicDropdown);

export default MemoizedComponent;