import React from 'react'
import {
  Box,
  Button,
  Icon,
  Typography,
  DialogTitle,
  Grid
} from '@material-ui/core'
import clsx from 'clsx';
import isNil from 'lodash-es/isNil';

import { makeStyles } from '@material-ui/styles'
import CustomDialog from '../dialog/CustomDialog'
import PrivacyOathBackground from 'icons/privacy-oath-bg.png'
import Close from 'icons/close-white.png'

const useStyles = makeStyles(theme => ({
  closeIcon: {
    height: 'auto',
    width: 'auto'
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    zIndex: 99999,
    fontSize: '14px',
    lineHeight: '25px',
    color: '#595959'
  }
}));

const PrivacyOath = (props) => {
  const classes = useStyles();
  const { open, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <CustomDialog
      onClose={handleClose}
      open={open}
      fullWidth
      title="PRIVACY OATH"
      background={true}
    >
      <Box style={{ position: 'absolute', right: 0, top: 0, height: '370px' }}>
        <img src={PrivacyOathBackground} style={{ height: '370px', width: '100%' }} />
      </Box>
      <DialogTitle style={{ zIndex: 99999 }}>
        <Box mt="12px">
          <Grid container
            direction="row"
            justify="flex-end"
            alignItems="center">
            <Icon className={ clsx(classes.closeIcon) } onClick={handleClose}><img src={Close} /></Icon>
          </Grid>
        </Box>
      </DialogTitle>
      <Box className={clsx(classes.content)} style={{ padding: '0 24px 12px', fontWeight: 600 }}>
        <Grid container direction="column" justify="center">
          <Grid item>
            <Box mb="20px">
              TEP PRIVACY OATH
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={clsx(classes.content)} style={{ background: '#E5E5E7', padding: '0 24px 24px' }}>
        <Grid container direction="column" justify="center">
          <Grid item>
            <Box mb="20px">
              These days too many digital experiences end up victimizing and taking advantage of us - on purpose. 
            </Box>
          </Grid>
          <Grid item>
            <Box mb="20px">
              They scrape data from us, basically spying on our every move on the web. And what is their goal? 
            </Box>
          </Grid>
          <Grid item>
            <Box mb="20px">
              To somehow, one way or another, make money on us by selling us (or helping others sell to us) products and services that are not necessarily in our best interest, but are surely in their best interest. They entice us with so many things we don't need with no regard for the clutter, stress and anxiety they are adding to our lives everyday.
            </Box>
          </Grid>
          <Grid item>
            <Box mb="20px">
              Our oath in the Empathy Project is to do the exact opposite of all of the above:
            </Box>
          </Grid>
          <Grid item>
            <Box mb="20px">
              We will never disclose your identity or your individualized data in any way that is identifiable to you to any third party. 
            </Box>
          </Grid>
          <Grid item>
            <Box mb="20px">
              First and foremost, every moment of your experience here is designed with a single purpose in mind: to engage you in the authentic conversations of life. 
            </Box>
          </Grid>
          <Grid item>
            <Box mb="20px">
              As a result, we can continuously reflect back to you life's most important conversations in order to help you identify and focus on the areas of your life that will bring you joy, meaning and fulfillment.
            </Box>
          </Grid>
          <Grid item>
            <Box mb="20px">
              We will feed you recommendations that you can regularly look forward to reviewing because you don't have to look for the gold or sift through so much noise and clutter from self-interested companies who want to make a profit on you. You can keep on recieing these in your giftbox, or you can turn off the recommendations so that you are left only with the conversations.
            </Box>
          </Grid>
          <Grid item>
            <Box mb="20px">
              In sum, all our conversations, reflections, recommendations and interactions and all the associated data will be used to improve your life in the most meaningful ways we can: <strong>to connect you with your deepest truths and so help you fulfill the highest potential and greatest promise of your life. </strong>
            </Box>
          </Grid>
          <Grid item>
            <Box mb="20px">
              So help us, God.
            </Box>
          </Grid>
          <Grid item>
            <Box mt="20px" mb="30px">
              <Button variant="contained" color="primary" size="small"
                style={{ padding: '15px', fontSize: '14px' }}
                onClick={handleClose}>
                Close
              </Button>
            </Box>
          </Grid>

        </Grid>
      </Box>
    </CustomDialog>
  )
}

export default PrivacyOath