import React from 'react';
import { isAuthenticated } from '../utils/auth';
import { Redirect } from '@reach/router';


export const ProtectedRoute = ({ component: Component, ...props }) => (
  isAuthenticated() ? <Component {...props} /> :
    <Redirect to="/login" state={{ pathFrom: props.path }} noThrow />
);

export const PublicRoute = ({ component: Component, ...props }) => (
  <Component {...props} />
);
