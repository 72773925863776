import React, { useState } from 'react';
import {
  Box,
  Grid,
} from '@material-ui/core';
import clsx from 'clsx';
import isNil from 'lodash-es/isNil';

import { useStyles } from '../../styles/reflectionDashboard';
import RoomViewMenu from './RoomViewMenu';
import WishList from './WishList';
import WishListForm from './WishListForm';
import useReflectionHandler from '../../hooks/useReflectionHandler';

const RoomViewWishlist = ({ onViewDropdownClick, wishList }) => {
  const classes = useStyles();
  const [currentWishlistIndex, setCurrentWishListIndex] = useState();
  const [showForm, setShowForm] = useState(false);
  const { currentRoom, setCurrentRoom } = useReflectionHandler();

  const [wishLists, setWishlists] = useState(wishList);

  function onAddClick() {
    setShowForm(true);
  }

  function showList() {
    // this should show the list again.
    setCurrentWishListIndex(undefined);
    setShowForm(false);
  }

  function onSaveWishList(wishList) {
    showList();

    const updatedWishList = [wishList, ...wishLists];
    setWishlists(updatedWishList);

    // Now let's update the current room wish list
    const room = {
      ...currentRoom,
      wishlists: updatedWishList
    }
    setCurrentRoom(room);
  }

  function onUpdateWishList(wishList) {
    showList();

    let newList = wishLists.filter((wishlist) => wishlist.id !== wishList.id)
    const updatedWishList = [wishList, ...newList];
    setWishlists(updatedWishList);

    // Now let's update the current room wish list
    const room = {
      ...currentRoom,
      wishlists: updatedWishList
    }
    setCurrentRoom(room);
  }

  function onWishListClick(index) {
    setCurrentWishListIndex(index);
    setShowForm(true);
  }

  function onWishlistDropClick(view) {
    setCurrentWishListIndex(undefined);
    setShowForm(false);
    onViewDropdownClick(view);
  }

  function renderContent() {
    if (!isNil(currentWishlistIndex) || showForm) {
      return <WishListForm
        onViewDropdownClick={onWishlistDropClick}
        onSaveWishList={onSaveWishList}
        onUpdateWishList={onUpdateWishList}
        wishList={wishLists[currentWishlistIndex]}
      />
    }

    return (
      <>
        <Box mb="12px">In this room:</Box>

        <Box mb="24px">
          <Grid xs container>
            <Grid item container style={{ marginBottom: '12px' }} alignItems="center">
              <Grid item>
                <Box className={clsx(classes.notif)} mr="5px" style={{ background: '#595959', color: '#fff' }}>{wishLists.length}</Box>
              </Grid>
              <Grid item><Box className="bold">Wishlist</Box></Grid>
            </Grid>
          </Grid>
        </Box>

        <WishList
          wishLists={wishLists}
          onAddClick={onAddClick}
          onViewDropdownClick={onWishlistDropClick}
          onWishListClick={onWishListClick}
        />
      </>
    );
  };

  return (
    <Box className={clsx(classes.roomContent)}>
      <RoomViewMenu title="WISHLIST" onViewDropdownClick={onWishlistDropClick} />

      <Box mb="24px">
        Don't let life just pass you by. Turn everyday experiences into actionable wish lists that will add to the good
        and substract from the not so good in your life.
      </Box>

      {renderContent()}
    </Box>
  )
}

export default RoomViewWishlist;