import React from 'react';
import { ApolloProvider } from '@apollo/client';

import { client } from '../api';

const Provider = ({ children }) => {
  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}

export default Provider;