import React, { useState } from 'react';
import { Box, InputAdornment } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser';
import { TextField } from '@material-ui/core'
import isNil from 'lodash-es/isNil';

const DEFAULT_STYLES = {
  position: 'relative',
  display: 'inline-flex',
  width: '80px'
}

const DurationField = (props) => {
  const { label, name, onChange, defaultValue, description, fontColor, error } = props;
  const [value, setValue] = useState(isNil(defaultValue) ? '' : defaultValue);

  function handleChange(e) {
    // somehow max length is not working on
    // numeric types input

    if (parseInt(e.target.value) > 24) return;

    if (e.target.value.length > 2) {
      e.preventDefault();
      return;
    }

    setValue(e.target.value)

    if (e.target.value.length < 2) {
      onChange({ field: name, value: `0${e.target.value}` });
    }

    onChange({ field: name, value: e.target.value });
  }

  function inputOnBlur(e) {
    if (parseInt(e.target.value) < 10) {
      setValue(`0${e.target.value}`);
      onChange({ field: name, value: `0${e.target.value}` });
    }
  }

  return (
    <Box mb="24px" style={{ color: fontColor }}>
      { label && <Box mb="24px" className="component-label" style={{ color: fontColor }}>{ReactHtmlParser(label)}</Box>}
      { description && <Box className="component-label-description" style={{ color: fontColor }}>{ReactHtmlParser(description)}</Box>}

      <span style={{ ...DEFAULT_STYLES }}>
        <TextField
          fullWidth
          value={value}
          onChange={handleChange}
          onBlur={inputOnBlur}
          error={!isNil(error)}
          type="number"
          helperText={error}
          placeholder="00"
          InputProps={{
            endAdornment: <InputAdornment position="end">HRS</InputAdornment>,
            inputProps: {
              max: 24, min: 0
            }
          }}
        />
      </span>
    </Box>
  )
}

export default DurationField;