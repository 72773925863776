import React from 'react';
import clsx from 'clsx';
import {
  Box,
} from '@material-ui/core';
import { useStyles } from '../../styles/reflectionDashboard';
import RoomViewMenu from './RoomViewMenu';
import IgniteLogo from 'icons/ignite-logo-primary.png';

const RoomViewPartners = ({ onViewDropdownClick }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.roomContent)}>
      <Box mb="24px">
        <RoomViewMenu
          title="PARTNERS"
          showNavigation={false}
          onViewDropdownClick={onViewDropdownClick}
        />
      </Box>
      <Box mt="44px" mb="5px" className={clsx('bold')}>
        A Room of Empathy
      </Box>
      <Box>
        brought to you by
      </Box>
      <Box my="24px" style={{ textAlign: 'center' }}>
        <img src={IgniteLogo} />
      </Box>
    </Box>
  )
}

export default RoomViewPartners;