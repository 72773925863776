import React from 'react';
import { Box } from '@material-ui/core'
import { Radio } from '@material-ui/core'
import { RadioGroup } from '@material-ui/core'
import { FormControlLabel } from '@material-ui/core'
import { FormControl } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/styles'
import isNil from 'lodash-es/isNil';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  radioLabel: {
    display: 'flex',
    alignItems: 'flex-start'
  }
}));

const RadioComponent = (props) => {
  const classes = useStyles();

  const {
    label,
    onChange,
    name,
    options,
    defaultValue,
    row,
    fontColor,
    description
  } = props;

  // Again radio button is not returning an event target value correctly
  const handleChange = (event) => {
    onChange({ field: name, value: event.target.value })
  }

  return (
    <Box mb="24px">
      <FormControl component="fieldset" fullWidth>
        { label && <Box mb="24px" className="component-label" style={{ color: fontColor }}>{ReactHtmlParser(label)}</Box> }
        { description && <Box className="component-label-description" style={{ color: fontColor }}>{ReactHtmlParser(description)}</Box> }
        <RadioGroup name={name} aria-label="radio" value={!isNil(defaultValue) ? defaultValue : ''} onChange={handleChange} row={row} style={{ color: fontColor, justifyContent: 'center' }}>
          {options.map((option, i) => {
            if (row) {
              return (<Box key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel key={option.id} style={{ color: fontColor }} value={option.identifier} control={<Radio />} label={option.title} />
                  <Typography variant="body1"><Box style={{  marginRight: '10px' }}>{ i === 0 ? 'or' : 'basis' }</Box></Typography>
                </Box>)
            } else {
              return (<Box mt="24px">
                <FormControlLabel classes={{ root: classes.radioLabel }} key={option.id} style={{ color: fontColor }} value={option.identifier} control={<Radio />} label={option.title} />
                { option.description && <Box mt="5px" mb="12px" ml="30px"><Typography variant="body1">{ReactHtmlParser(option.description)}</Typography></Box> }
                </Box>)
            }
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  )
}

const MemoizedComponent = React.memo(RadioComponent);

export default MemoizedComponent;
