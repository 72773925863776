import React from 'react';
import { Box, Grid, LinearProgress } from '@material-ui/core'

import { useStyles } from '../../styles/reflection';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 14,
    borderRadius: 10,
  },
  colorPrimary: {
    backgroundColor: '#fff',
  },
  bar: {
    height: 12,
    borderRadius: 10,
    backgroundColor: '#03CEC2',
    boxShadow: `2px 2px 4px rgba(54, 54, 54, 0.4)`
  },
}))(LinearProgress);

const BorderHappyProgress = withStyles((theme) => ({
  root: {
    height: 14,
    borderRadius: 10,
  },
  colorPrimary: {
    backgroundColor: '#F0F0F0',
  },
  bar: {
    borderRadius: 12,
    backgroundColor: '#BEDFFE',
    boxShadow: `2px 2px 4px rgba(54, 54, 54, 0.4)`
  },
}))(LinearProgress);

const BorderNotHappyProgress = withStyles((theme) => ({
  root: {
    height: 14,
    borderRadius: 10,
  },
  colorPrimary: {
    backgroundColor: '#F0F0F0',
  },
  bar: {
    borderRadius: 12,
    backgroundColor: '#DD3874',
    boxShadow: `2px 2px 4px rgba(54, 54, 54, 0.4)`
  },
}))(LinearProgress);

const ActivityProgress = ({ segmentReflection }) => {
  const classes = useStyles();
  const label = `${segmentReflection.hours_number}hrs (${segmentReflection.hours_percent}%)`
  
  // So progress bar will show properly. Only set upto 100 percent
  const hoursPercentage = segmentReflection.hours_percent > 100 ? 100 : segmentReflection.hours_percent;

  const determineHappinessProgress = () => {
    const percentage = segmentReflection.impact;
    const progress = percentage > 50 
      ? <BorderHappyProgress variant="determinate" value={percentage} /> 
      : <BorderNotHappyProgress variant="determinate" value={percentage} />

    return progress;
  }

  return (
    <Grid xs={12} container style={{ marginBottom: '32px' }}>
      <Grid item xs={12} className={ clsx(classes.progressActivityLabel) }>
        <Box mb="5px">
          {segmentReflection.segment_name}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb="5px" style={{ fontSize: '12px' }}>
          {label}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb="5px">
          <BorderLinearProgress variant="determinate" value={hoursPercentage} />
        </Box>
      </Grid>
      <Grid container justify="space-between" xs={12} style={{ marginBottom: '5px' }}>
        <Grid item className={ clsx(classes.weeklyProgressHappyLabel) }>Not happy</Grid>
        <Grid item className={ clsx(classes.weeklyProgressHappyLabel) }>Super happy</Grid>
      </Grid>
      <Grid item xs={12}>
        {determineHappinessProgress()}
      </Grid>
    </Grid>
  )
}

export default ActivityProgress;