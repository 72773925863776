import React from 'react';
import { Box, Container, Grid, Icon } from '@material-ui/core';
import { navigate } from '@reach/router';
import clsx from 'clsx';
import CardMail from 'icons/card-mail.svg';
import ChevronRightWhite from 'icons/chevron-right-white.svg';
import Styles from '../../styles/payForward';
import Slide from '@material-ui/core/Slide';

import ProjectContext from '../../contexts/projectContext';

const PayForwardWelcome = () => {
  const classes = Styles();
  const { currentProject } = React.useContext(ProjectContext);

  function handleNext() {
    navigate(`/project/${currentProject.slug}/pay-forward/reflection`)
  }

  return (
    <Slide direction={"left"} in={true} mountOnEnter unmountOnExit>
      <Container disableGutters={true} maxWidth="sm">
        <Box className={ clsx(classes.payForward) } p="16px">
          <Grid container className={ clsx('full-height') } component="div" direction="column" alignItems="center" justify="center">
            <Grid item>
              <Box mb="24px">
                <Icon style={{ width: 'auto', height: 'auto' }}>
                  <img src={CardMail} />
                </Icon>
              </Box>
            </Grid>
            <Grid item>
              <Box className={ clsx('segment-heading') } style={{ color: '#595959', textAlign: 'center' }}>
                What's Next?
              </Box>
            </Grid>
            <Grid item>
              <Box 
                mt="64px"
                className={ clsx('next-button') } 
                onClick={handleNext}
                style={{ border: `1px solid #595959`, backgroundColor: '#595959' }}>
                <Icon fontSize="large">
                  <img src={ChevronRightWhite} />
                </Icon>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Slide>
  )
}

export default PayForwardWelcome;