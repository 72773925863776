import React, { useContext } from 'react';
import {
  Box,
  Grid,
  Icon,
  Paper
} from '@material-ui/core';
import clsx from 'clsx';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

import { SUB_QUESTION_TYPES } from '../../utils/segment';
import { buildValidationSchema } from '../../validations/dynamic';
import { useSubmitAnswer } from '../../api/project';

import ApplicationContext from '../../contexts/applicationContext';
import useProjectGraphqlHandler from '../../hooks/useProjectGqlHandler';
import useForm from '../../hooks/useForm';
import ChevronLeft from 'icons/chevron-left.svg';
import ChevronLeftWhite from 'icons/chevron-left-white.svg';
import ChevronRight from 'icons/chevron-right.svg';
import ChevronRightWhite from 'icons/chevron-right-white.svg';
import Dropdown from '../inputs/Dropdown';
import TextField from '../inputs/Textfield';
import Textarea from '../inputs/Textarea';
import TagSelection from '../inputs/TagSelection';
import WholeNumberField from '../inputs/WholeNumberField';
import CheckboxComponent from '../inputs/Checkbox';
import EmailAddress from '../inputs/EmailAddress';
import RadioComponent from '../inputs/Radio';
import ReadOnly from '../inputs/ReadOnly';
import RankingWithInput from '../inputs/RankingWithInput';
import Ranking from '../inputs/Ranking';
import TimeField from '../inputs/TimeField';
import DateSelector from '../inputs/DateSelector';
import RateField from '../inputs/RateField';
import NestedRateFieldWithDuration from '../inputs/NestedRateFieldWithDurationField';
import NestedCheckboxWithDuration from '../inputs/NestedCheckboxWithDuration';
import DynamicDrowdown from '../inputs/DynamicDropdown';
import MultiSelectDropdown from '../inputs/MultiSelectDropdown';
import DurationField from '../inputs/DurationField';
import Keywords from '../inputs/Keywords';

const SegmentStepForm = ({
  step,
  currentStepIndex,
  handlePrevious,
  handleNextNew,
  index,
  isLastStep,
  isFirstStep,
  setNextStep
}) => {
  const [slide, setSlide] = React.useState(true);
  const { setToastOpen, setToastMessage, setToastColor } = useContext(ApplicationContext)
  const {
    currentSegment,
    currentProject,
    buildIntakerFormDefaultStates,
    buildAnswersPayload,
    updateCurrentSegmentAnswers,
    hasSubQuestions,
    sortAnswerOptions,
  } = useProjectGraphqlHandler();

  const defaultFormState = buildIntakerFormDefaultStates(step.id);
  const [submitAnswer, { loading: submittingAnswer }] = useSubmitAnswer(onSubmitSuccess, onSubmitError);

  let timer;

  // This will contain implementations for transition effect
  function onSubmitSuccess(response) {
    if (response.submitAnswer.success) {

      // If it's the last step then go to segment end
      if (isLastStep) {
        setNextStep(isLastStep);
        return;
      }

      // This will trigger the slide effect
      setSlide(!slide);

      // Todo clear this timer to avoid memory leak
      setTimeout(() => {
        handleNextNew();
      }, 100);

      updateCurrentSegmentAnswers(step.id, { ...values })
    }
  }

  function onSubmitError() {
    setToastColor('error');
    setToastOpen(true);
    setToastMessage('Oops! Something went wrong. Try again.');
  }

  // Clicking next button will submit the current step answers
  function handleNext() {
    const payload = {
      projectId: currentProject.id,
      answer: {
        segment_id: currentSegment.id.toString(), answers: buildAnswersPayload(values, step)
      }
    }
    submitAnswer({ variables: { ...payload } })
  }

  // Go back, this only applies on segment steps
  function handleBack() {
    // Don't apply slide here if 
    if (isFirstStep) {
      handlePrevious();
      return;
    }

    setSlide(!slide);
    timer = setTimeout(() => {
      handlePrevious();
    }, 100);
  }

  const {
    values,
    errors,
    handleSubmit,
    setValues,
    handleSpecificChange,
    setSchemaValidation,
    disabledSubmit,
    setDisableSubmit
  } = useForm({
    defaultState: defaultFormState,
    submitAction: handleNext,
    validationSchema: buildValidationSchema(step.questions)
  })

  // Set form values will be set again correctly,
  // Also set validation schema again correctly
  // when step reference/prop updates
  React.useEffect(() => {
    setValues(defaultFormState)
    setSchemaValidation(buildValidationSchema(step.questions))
  }, [step])

  React.useEffect(() => {
    if (!slide && index === currentStepIndex) setSlide(!slide);
  }, [currentStepIndex])

  // Clear time on unmount to prevent memory leaks
  React.useEffect(() => {
    return () => {
      clearTimeout(timer);
    }
  }, [])

  // We render form fields based on their question types
  function renderQuestionField(question) {
    if (question.questionType) {
      switch (question.questionType) {
        case 'Dropdown':
          return <Dropdown
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            options={sortAnswerOptions(question.answerOptions)}
            defaultValue={values[question.id]}
            placeholder={question.placeholder}
            fontColor={currentSegment.fontColor}
            description={question.description}
          />
        case 'Text Area Field':
          return <Textarea
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            defaultValue={values[question.id]}
            fontColor={currentSegment.fontColor}
            description={question.description}
            placeholder={question.placeholder}
          />
        case 'Keywords':
          return <Keywords
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            defaultValue={values[question.id]}
            fontColor={currentSegment.fontColor}
            description={question.description}
            placeholder={question.placeholder}
          />
        case 'Input Text Field':
          return <TextField
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            defaultValue={values[question.id]}
            fontColor={currentSegment.fontColor}
            description={question.description}
            placeholder={question.placeholder}
          />
        case 'Duration Field':
          return <DurationField
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            defaultValue={values[question.id]}
            fontColor={currentSegment.fontColor}
            description={question.description}
          />
        case 'Tag Selection':
          return <TagSelection key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            options={sortAnswerOptions(question.answerOptions)}
            defaultValue={values[question.id]}
            fontColor={currentSegment.fontColor}
            description={question.description}
          />
        case 'Whole Number Field':
          return <WholeNumberField
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            defaultValue={values[question.id]}
            fontColor={currentSegment.fontColor}
            description={question.description}
            error={errors[question.id]}
          />
        case 'Tickbox':
          return <CheckboxComponent
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            row={false}
            options={sortAnswerOptions(question.answerOptions)}
            multi={false}
            defaultValue={values[question.id]}
            fontColor={currentSegment.fontColor}
            description={question.description}
          />
        case 'Check box':
          return <CheckboxComponent
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            row={false}
            options={sortAnswerOptions(question.answerOptions)}
            multi={true}
            defaultValue={values[question.id]}
            fontColor={currentSegment.fontColor}
            description={question.description}
          />
        case 'Email Address':
          return <EmailAddress
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            defaultValue={values[question.id]}
            error={errors[question.id]}
            fontColor={currentSegment.fontColor}
            description={question.description}
          />
        case 'Radio button':
          return <RadioComponent
            key={question.id}
            row={false}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            options={sortAnswerOptions(question.answerOptions)}
            defaultValue={values[question.id]}
            fontColor={currentSegment.fontColor}
            description={question.description}
          />
        case 'Horizontal Radio':
          return <RadioComponent
            key={question.id}
            row={true}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            options={sortAnswerOptions(question.answerOptions)}
            defaultValue={values[question.id]}
            fontColor={currentSegment.fontColor}
            description={question.description}
          />
        case 'Rank Questions: Dropdown + Input Field':
          return <RankingWithInput
            key={question.id}
            row={true}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            rankQuestions={sortAnswerOptions(question.answerOptions)}
            defaultValue={values[question.id]}
            fontColor={currentSegment.fontColor}
            description={question.description}
          />
        case 'Ranking':
          return <Ranking
            key={question.id}
            row={true}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            rankQuestions={sortAnswerOptions(question.answerOptions)}
            defaultValue={values[question.id]}
            fontColor={currentSegment.fontColor}
            description={question.description}
          />;
        case 'Rate Field (with labels)':
          return <RateField
            key={question.id}
            label={question.title}
            name={question.id}
            options={sortAnswerOptions(question.answerOptions)}
            onChange={handleSpecificChange}
            defaultValue={values[question.id]}
            fontColor={currentSegment.fontColor}
            description={question.description}
          />;
        case 'Nested Rating with Duration Field':
          return <NestedRateFieldWithDuration
            key={question.id}
            label={question.title}
            name={question.id}
            options={sortAnswerOptions(question.answerOptions)}
            onChange={handleSpecificChange}
            defaultValue={values[question.id]}
            fontColor={currentSegment.fontColor}
            description={question.description}
            error={errors[question.id]}
            logicalExpression={question.logicalExpression}
          />;
        case 'Nested Checkbox with Duration Field':
          return <NestedCheckboxWithDuration
            key={question.id}
            label={question.title}
            name={question.id}
            options={sortAnswerOptions(question.answerOptions)}
            onChange={handleSpecificChange}
            defaultValue={values[question.id]}
            fontColor={currentSegment.fontColor}
            description={question.description}
            row={false}
            multi={true}
            error={errors[question.id]}
          />;
        case 'Read_Only':
          return <ReadOnly key={question.id} content={question.title} label={question.title} fontColor={currentSegment.fontColor} lightning={false} />;
        case 'Read_Only_Lightning':
          return <ReadOnly key={question.id} content={question.title} label={question.title} fontColor={currentSegment.fontColor} lightning={true} />;
        case 'Time Field':
          return <TimeField
            key={question.id}
            onChange={handleSpecificChange}
            defaultValue={values[question.id]}
            label={question.title}
            name={question.id}
            fontColor={currentSegment.fontColor}
            description={question.description}
          />;
        case 'Date Selector':
          return <DateSelector
            key={question.id}
            onChange={handleSpecificChange}
            defaultValue={values[question.id]}
            label={question.title}
            name={question.id}
            fontColor={currentSegment.fontColor}
            description={question.description}
          />
        case 'Multi Select Dropdown':
          return <MultiSelectDropdown
            key={question.id}
            options={sortAnswerOptions(question.answerOptions)}
            onChange={handleSpecificChange}
            defaultValue={values[question.id]}
            label={question.title}
            name={question.id}
            fontColor={currentSegment.fontColor}
          />
        default:
          return <Box mb="24px" px="16px" key={question.id}>{question.questionType}</Box>;
      }
    } else {
      return null;
    }
  }

  function renderQuestions() {
    const q = step.questions.find((q) => !SUB_QUESTION_TYPES.includes(q.questionType));
    if (hasSubQuestions(step)) {
      return <DynamicDrowdown
        onChange={handleSpecificChange}
        defaultValue={values[q.id]}
        questions={step.questions}
        disableSubmit={setDisableSubmit}
      />
    }

    // Iterate on sorted questions
    const questions = step.questions.slice().sort((a, b) => a.displayOrder - b.displayOrder);
    return questions.map((question) => {
      return renderQuestionField(question);
    })
  }

  function nextButton() {
    if (submittingAnswer) {
      return <CircularProgress />;
    } else {
      return (
        <Icon fontSize="large">
          {currentSegment.fontColor.toLowerCase() === '#ffffff' ?
            <img src={ChevronRightWhite} />
            : <img src={ChevronRight} />
          }
        </Icon>
      )
    }
  }

  function showBackButton() {
    if (isFirstStep && currentSegment.hideSegmentHeader) return false;
    return true;
  }

  return (
    <Slide direction={"right"} in={slide} mountOnEnter={true} appear={false}>
      <Paper
        className="segment-step-paper"
        elevation={index === currentStepIndex ? 3 : 0}
        style={{ backgroundColor: currentSegment.bgColor }}
        my="16px"
      >
        <Grid container m="16px" className={clsx('full-percent-height')} component="div" direction="column" alignItems="center" justify="center">
          <Grid item style={{ width: '100%' }}>
            <Box mb="64px" mx="16px" mt="32px">
              {renderQuestions()}
            </Box>
          </Grid>
          <Grid container justify="space-around" style={{ marginBottom: '50px' }}>
            {showBackButton() && (
              <Grid item>
                <Box className={clsx('next-button')}
                  onClick={handleBack}
                  disabled={submittingAnswer}
                  style={{ border: `1px solid ${currentSegment.fontColor}` }}
                >
                  <Icon fontSize="large">
                    {currentSegment.fontColor.toLowerCase() === '#ffffff' ?
                      <img src={ChevronLeftWhite} />
                      : <img src={ChevronLeft} />
                    }
                  </Icon>
                </Box>
              </Grid>
            )}
            <Grid item>
              <Box className={clsx('next-button')}
                onClick={handleSubmit}
                disabled={submittingAnswer || disabledSubmit}
                style={{ border: `1px solid ${currentSegment.fontColor}` }}
              >
                {nextButton()}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Slide>
  )
}

const MemoizedComponent = React.memo(SegmentStepForm);

export default MemoizedComponent;