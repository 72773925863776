import React from 'react';

import RoomView from '../reflection/RoomView';
import WithProjectTemplate from '../common/WithProjectTemplate'

const RoomViewPage = () => {
  return (
    <WithProjectTemplate>
      <RoomView />
    </WithProjectTemplate>
  )
}

export default RoomViewPage;