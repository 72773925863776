import * as yup from 'yup';
import isNil from 'lodash-es/isNil'
import omitBy from 'lodash-es/omitBy';

export const payForwardEmailField = ({ attribute }) =>
  yup
    .string()
    .nullable(true)
    .trim()
    .required()
    .oneOf([yup.ref('confirmPayForwardEmail'), null], 'Emails must match')
    .label(attribute);

export const confirmPayForwardEmailField = ({ attribute }) =>
  yup
    .string()
    .nullable(true)
    .trim()
    .required()
    .oneOf([yup.ref('payForwardEmail'), null], 'Emails must match')
    .label(attribute);

export const buildValidationSchema = () => {
  const shape = {
    payForwardEmail: payForwardEmailField({ attribute: 'Email' }),
    confirmPayForwardEmail: confirmPayForwardEmailField({ attribute: 'Confirm Email' }),
  };

  return yup.object().shape(omitBy(shape, isNil));
};

export default buildValidationSchema;