import React from 'react';
import {
  Box,
  Grid,
  Typography
} from '@material-ui/core'
import { useStyles } from '../../styles/backtofuture';
import clsx from 'clsx';
import ReactHtmlParser from 'react-html-parser';

import FriendsIcon from 'icons/friends.svg';
import TreasureChestIcon from 'icons/treasure-chest.svg';
import TreasureChestWhiteIcon from 'icons/treasure-chest-white.svg';
import FriendsIconWhite from 'icons/friends-white.svg';

const BackToFutureQuestion = ({ question, onSelect }) => {
  const classes = useStyles();

  const [selected, setSelected] = React.useState();

  const handleSelect = (option) => {
    setSelected(option.id)
    onSelect(option.identifier)
  }

  const renderQuestions = () => {
    return question.answerOptions.map((option, index) => {
      const icon = index === 0 ? TreasureChestIcon : FriendsIcon;
      const iconWhite = index === 0 ? TreasureChestWhiteIcon : FriendsIconWhite;
      const isSelected = selected === option.id; 
      return (
        <Box mb="24px"
          className={clsx(classes.reflectionOptions)}
          style={{ border: selected === option.id ? 'none' : '2px solid #D6D6D6' }}>
          <Box p="24px"
            onClick={() => handleSelect(option)}
            className={clsx(classes.cardTop, { [classes.active]: isSelected })}>
            <Grid container spacing={2} alignItems="center">
              <Grid xs={4}>
                {isSelected ? <img src={iconWhite} /> : <img src={icon} />}
              </Grid>
              <Grid xs={8}>
                <Box className={clsx({ [classes.reflectionBoxTitle]: isSelected })}>{option.title}</Box>
              </Grid>
            </Grid>
          </Box>
          <Box className={clsx(classes.cardBottom, { [classes.show]: isSelected, [classes.hide]: !isSelected })}
            style={{
              borderBottom: '2px solid #01CDC2',
              borderLeft: '2px solid #01CDC2',
              borderRight: '2px solid #01CDC2'
            }}>
            <Typography variant="body1">
              {ReactHtmlParser(option.description)}
            </Typography>
          </Box>
        </Box>
      )
    })
  }

  return (
    <>
      <Box mb="24px" className={clsx(classes.reflectionBoxContent)}>
        At present, which person do I live to be?
      </Box>

      <Box mb="24px">
        Select one:
      </Box>
      {renderQuestions()}
    </>
  )

}

export default BackToFutureQuestion;