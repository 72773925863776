import React from 'react';
import { Box, Container, Grid, Icon } from '@material-ui/core';
import clsx from 'clsx';
import ChevronRightWhite from 'icons/chevron-right-white.svg';
import { navigate } from '@reach/router';
import Slide from '@material-ui/core/Slide';

import ProjectContext from '../../contexts/projectContext';

const IntakerThankYou = () => {
  const { currentProject } = React.useContext(ProjectContext);

  function handleNext() {
    navigate(`/project/${currentProject.slug}/intaker/end`)
  }

  return (
    <Slide direction={"left"} in={true} mountOnEnter unmountOnExit>
      <Container disableGutters={true} maxWidth="sm">
        <Box style={{ backgroundColor: '#fff', textAlign: 'center', color: '#595959' }} p="16px">
          <Grid container className={ clsx('full-height') } component="div" direction="column" alignItems="center" justify="center">
            <Grid item>
              <Box className={ clsx('segment-heading') }>
                Thank You!
              </Box>
            </Grid>
            <Grid item>
              <Box 
                mt="64px"
                className={ clsx('next-button') } 
                onClick={handleNext} 
                style={{ color: '#fff', 
                  border: `1px solid #595959`, 
                  backgroundColor: '#595959',
                  cursor: 'pointer' }}>
                <Icon fontSize="large"><img src={ChevronRightWhite} /></Icon>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Slide>
  )
}

export default IntakerThankYou;