import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ReactHtmlParser from 'react-html-parser';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useSubmitAnswer } from '../../api/reflection';

import ApplicationContext from '../../contexts/applicationContext';
import RoomViewMenu from './RoomViewMenu';
import useReflectionHandler from '../../hooks/useReflectionHandler';
import useForm from '../../hooks/useForm';
import Dropdown from '../inputs/Dropdown';
import TextField from '../inputs/Textfield';
import Textarea from '../inputs/Textarea';
import TagSelection from '../inputs/TagSelection';
import WholeNumberField from '../inputs/WholeNumberField';
import CheckboxComponent from '../inputs/Checkbox';
import EmailAddress from '../inputs/EmailAddress';
import RadioComponent from '../inputs/Radio';
import ReadOnly from '../inputs/ReadOnly';
import RankingWithInput from '../inputs/RankingWithInput';
import Ranking from '../inputs/Ranking';
import TimeField from '../inputs/TimeField';
import DateSelector from '../inputs/DateSelector';
import RateField from '../inputs/RateField';
import NestedRateFieldWithDuration from '../inputs/NestedRateFieldWithDurationField';
import NestedCheckboxWithDuration from '../inputs/NestedCheckboxWithDuration';
import MultiSelectDropdown from '../inputs/MultiSelectDropdown';
import DurationField from '../inputs/DurationField';
import ChevronRightIcon from 'icons/chevron-right.svg';
import ChevronLeftIcon from 'icons/chevron-left.svg';
import Keywords from '../inputs/Keywords';
import MobileNumber from '../inputs/MobileNumber';

const ConversationForm = ({
  onViewDropdownClick,
  conversation,
  slideDirection,
  onNextClick,
  onPreviousClick,
  currentConversationIndex,
  conversations
}) => {
  const { setToastOpen, setToastMessage, setToastColor } = useContext(ApplicationContext)
  const [updateButtonClicked, setUpdateButtonClicked] = useState(false)

  const {
    updateCurrentRoomConversationsAnswers,
    buildConversationFormDefaultStates,
    sortAnswerOptions,
    currentProject,
    buildAnswersPayload,
    currentRoom,
    setCurrentRoom
  } = useReflectionHandler();

  const defaultFormState = buildConversationFormDefaultStates(conversation.id);
  const [submitAnswer, { loading: submittingAnswer }] = useSubmitAnswer(onSubmitSuccess, onSubmitError);

  // This will contain implementations for transition effect
  function onSubmitSuccess(response) {
    if (response.submitAnswer.success) {
      const room = response.submitAnswer.room;
      setCurrentRoom({
        ...currentRoom,
        priorityScore: room.priorityScore,
        happinessScore: room.happinessScore,
        importanceScore: room.importanceScore,
        happinessRating: room.happinessRating,
        duration: room.duration
      })

      setToastColor('success');
      setToastOpen(true);
      setToastMessage('Successfully saved conversation!');
      updateCurrentRoomConversationsAnswers(conversation.id, { ...values });
      setUpdateButtonClicked(false);
      if (!updateButtonClicked) {
        onNextClick();
      }
    }
  }

  function onSubmitError() {
    setToastColor('error');
    setToastOpen(true);
    setToastMessage('Oops! Something went wrong. Try again.');
  }

  function submit() {
    const payload = {
      projectId: currentProject.id,
      answer: {
        room_id: currentRoom.id.toString(), answers: buildAnswersPayload(values, conversation)
      }
    }
    submitAnswer({ variables: { ...payload } })
  }

  function onUpdateButtonClick() {
    setUpdateButtonClicked(true);
    handleSubmit();
  }

  const {
    values,
    handleSpecificChange,
    handleSubmit,
    setValues
  } = useForm({
    defaultState: defaultFormState,
    submitAction: submit
  });

  useEffect(() => {
    const conversationAnswers = buildConversationFormDefaultStates(conversation.id);
    setValues(conversationAnswers);
  }, [conversation]);

  // We render form fields based on their question types
  function renderQuestionField(question) {
    if (question.questionType) {
      switch (question.questionType) {
        case 'Dropdown':
          return <Dropdown
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            options={sortAnswerOptions(question.answerOptions)}
            defaultValue={values[question.id]}
            placeholder={question.placeholder}
            fontColor='#000'
            description={question.description}
          />
        case 'Text Area Field':
          return <Textarea
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            defaultValue={values[question.id]}
            fontColor='#000'
            description={question.description}
            placeholder={question.placeholder}
          />
        case 'Keywords':
          return <Keywords
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            defaultValue={values[question.id]}
            fontColor={'#000'}
            description={question.description}
            placeholder={question.placeholder}
            withList={true}
          />
        case 'Input Text Field':
          return <TextField
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            defaultValue={values[question.id]}
            fontColor='#000'
            description={question.description}
            placeholder={question.placeholder}
          />
        case 'Mobile Number':
          return <MobileNumber
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            defaultValue={values[question.id]}
            fontColor='#000'
            description={question.description}
            placeholder={question.placeholder}
          />
        case 'Duration Field':
          return <DurationField
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            defaultValue={values[question.id]}
            fontColor='#000'
            description={question.description}
          />
        case 'Tag Selection':
          return <TagSelection key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            options={sortAnswerOptions(question.answerOptions)}
            defaultValue={values[question.id]}
            fontColor='#000'
            description={question.description}
          />
        case 'Whole Number Field':
          return <WholeNumberField
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            defaultValue={values[question.id]}
            fontColor='#000'
            description={question.description}
            error={errors[question.id]}
          />
        case 'Tickbox':
          return <CheckboxComponent
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            row={false}
            options={sortAnswerOptions(question.answerOptions)}
            multi={false}
            defaultValue={values[question.id]}
            fontColor='#000'
            description={question.description}
          />
        case 'Check box':
          return <CheckboxComponent
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            row={false}
            options={sortAnswerOptions(question.answerOptions)}
            multi={true}
            defaultValue={values[question.id]}
            fontColor='#000'
            description={question.description}
          />
        case 'Email Address':
          return <EmailAddress
            key={question.id}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            defaultValue={values[question.id]}
            error={errors[question.id]}
            fontColor='#000'
            description={question.description}
          />
        case 'Radio button':
          return <RadioComponent
            key={question.id}
            row={false}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            options={sortAnswerOptions(question.answerOptions)}
            defaultValue={values[question.id]}
            fontColor='#000'
            description={question.description}
          />
        case 'Horizontal Radio':
          return <RadioComponent
            key={question.id}
            row={true}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            options={sortAnswerOptions(question.answerOptions)}
            defaultValue={values[question.id]}
            fontColor='#000'
            description={question.description}
          />
        case 'Rank Questions: Dropdown + Input Field':
          return <RankingWithInput
            key={question.id}
            row={true}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            rankQuestions={sortAnswerOptions(question.answerOptions)}
            defaultValue={values[question.id]}
            fontColor='#000'
            description={question.description}
          />
        case 'Ranking':
          return <Ranking
            key={question.id}
            row={true}
            onChange={handleSpecificChange}
            label={question.title}
            name={question.id}
            rankQuestions={sortAnswerOptions(question.answerOptions)}
            defaultValue={values[question.id]}
            fontColor='#000'
            description={question.description}
          />;
        case 'Rate Field (with labels)':
          return <RateField
            key={question.id}
            label={question.title}
            name={question.id}
            options={sortAnswerOptions(question.answerOptions)}
            onChange={handleSpecificChange}
            defaultValue={values[question.id]}
            fontColor='#000'
            description={question.description}
          />;
        case 'Nested Rating with Duration Field':
          return <NestedRateFieldWithDuration
            key={question.id}
            label={question.title}
            name={question.id}
            options={sortAnswerOptions(question.answerOptions)}
            onChange={handleSpecificChange}
            defaultValue={values[question.id]}
            fontColor='#000'
            description={question.description}
            error={errors[question.id]}
            logicalExpression={question.logicalExpression}
          />;
        case 'Nested Checkbox with Duration Field':
          return <NestedCheckboxWithDuration
            key={question.id}
            label={question.title}
            name={question.id}
            options={sortAnswerOptions(question.answerOptions)}
            onChange={handleSpecificChange}
            defaultValue={values[question.id]}
            fontColor='#000'
            description={question.description}
            row={false}
            multi={true}
            error={errors[question.id]}
          />;
        case 'Read_Only':
          return <ReadOnly key={question.id} content={question.title} label={question.title} fontColor='#000' lightning={false} />;
        case 'Read_Only_Lightning':
          return <ReadOnly key={question.id} content={question.title} label={question.title} fontColor='#000' lightning={true} />;
        case 'Time Field':
          return <TimeField
            key={question.id}
            onChange={handleSpecificChange}
            defaultValue={values[question.id]}
            label={question.title}
            name={question.id}
            fontColor='#000'
            description={question.description}
          />;
        case 'Date Selector':
          return <DateSelector
            key={question.id}
            onChange={handleSpecificChange}
            defaultValue={values[question.id]}
            label={question.title}
            name={question.id}
            fontColor='#000'
            description={question.description}
          />
        case 'Multi Select Dropdown':
          return <MultiSelectDropdown
            key={question.id}
            options={sortAnswerOptions(question.answerOptions)}
            onChange={handleSpecificChange}
            defaultValue={values[question.id]}
            label={question.title}
            name={question.id}
            fontColor='#000'
          />
        default:
          return <Box mb="24px" px="16px" key={question.id}>{question.questionType}</Box>;
      }
    } else {
      return null;
    }
  }

  function renderQuestions() {
    const questions = conversation.questions.slice().sort((a, b) => a.conversationDisplayOrder - b.conversationDisplayOrder)
    return questions.map((question) => {
      return renderQuestionField(question);
    })
  }

  function renderDots() {
    return conversations.map((conversation, index) => {
      const inCurrent = currentConversationIndex === index;
      return (
        <Grid key={conversation.id} item>
          <FiberManualRecordIcon style={{ fontSize: '14px', color: inCurrent ? '#276EF1' : '#C4C4C4' }} />
        </Grid>
      );
    });
  }

  function nextButton() {
    if (submittingAnswer) {
      return (
        <div style={{ marginLeft: '10px' }}>
          <CircularProgress size={20} />
        </div>
      )
    } else {
      return (
        <Grid item><img src={ChevronRightIcon} style={{ marginLeft: '10px' }} onClick={handleSubmit} /></Grid>
      )
    }
  }

  return (
    <Slide key={`conversation-${conversation.id}`} direction={slideDirection} in={true} mountOnEnter={true}>
      <Box mb="24px">
        <RoomViewMenu
          title={conversation.title}
          showNavigation={conversations.length > 1}
          onViewDropdownClick={onViewDropdownClick}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          showNextButton={true}
          showPreviousButton={true}
        />

        <Box mb="24px">
          {ReactHtmlParser(conversation.description)}
        </Box>

        <Grid item style={{ width: '100%' }}>
          <Box mb="24px">
            {renderQuestions()}
          </Box>
        </Grid>
        <Box mt="40px" mb="16px">
          <Button loading={true} variant="contained" color="primary" onClick={onUpdateButtonClick} disabled={submittingAnswer}>
            {submittingAnswer && <CircularProgress size={14} />}
            {!submittingAnswer && 'Update Answers'}
          </Button>
        </Box>

        {conversations.length > 1 && (
          <Box mt="30px">
            <Grid container alignItems="center" justify="center">
              <Grid item><img src={ChevronLeftIcon} style={{ marginRight: '10px' }} onClick={onPreviousClick} /></Grid>
              {renderDots()}
              {nextButton()}
            </Grid>
          </Box>
        )}

      </Box>
    </Slide>
  )
}

export default ConversationForm;