import React from 'react';
import { Box, Container, Grid } from '@material-ui/core'
import clsx from 'clsx';

const Error = () => {
  return (
    <Container maxWidth="sm">
      <Grid container m="16px" className={clsx('full-height')} component="div" direction="column" alignItems="center" justify="center">
        <Grid item>
          <Box mb="64px" mx="16px">
            <h1 style={{ textAlign: 'center' }}>Ooops! Something went wrong.</h1>
            <p style={{ textAlign: 'center' }}>Please contact support if problem still persists</p>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Error;