import React from 'react';
import { Box, Container, Grid, Icon, Paper } from '@material-ui/core'
import clsx from 'clsx';
import ChevronRight from 'icons/chevron-right.svg';
import ChevronRightWhite from 'icons/chevron-right-white.svg';
import ReactHtmlParser from 'react-html-parser';
import Fade from '@material-ui/core/Fade';

import ProjectContext from '../../contexts/projectContext';
import useProjectGqlhandler from '../../hooks/useProjectGqlHandler';

const SegmentDescription = () => {
  const { setNextStep } = useProjectGqlhandler();
  const { currentSegment, currentProject } = React.useContext(ProjectContext);

  function handleNext() {
    setNextStep();
  }

  function strReplace(){
    const newStr = currentSegment.description.replace('[IntakerFNAME]', currentProject.firstName);
    return newStr;
  }

  return (
    <Fade in={true}>
      <Container maxWidth="sm" className={ clsx('container-with-gutter', 'segment-container') }>
        <Paper component="div" className="segment-desc-paper" elevation={3} style={{ backgroundColor: currentSegment.bgColor, color: currentSegment.fontColor }}>
          <Grid container m="16px" className={ clsx('full-height') } component="div" direction="column" alignItems="center" justify="center">
            <Grid item>
                <Box mt="48px" mb="64px" mx="16px" style={{ color: currentSegment.fontColor }} className="component-label">
                  {ReactHtmlParser(strReplace())}
                </Box>
            </Grid>
            <Grid item>
              <Box mb="50px" className={ clsx('next-button') } onClick={handleNext} style={{ border: `1px solid ${currentSegment.fontColor}`  }}>
                <Icon fontSize="large">
                  { currentSegment.fontColor.toLowerCase() === '#ffffff' ?
                    <img src={ChevronRightWhite} />
                    : <img src={ChevronRight} />
                  }
                </Icon>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Fade>
  )
}

export default SegmentDescription;