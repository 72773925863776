import React from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import ApplicationContext from '../contexts/applicationContext';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useToast = () => {
  const {
    toastMessage,
    toastOpen,
    toastColor,
    setToastOpen
  } = React.useContext(ApplicationContext);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    
    setToastOpen(false);
  };

  const renderToast = () => {
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={toastOpen}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={toastColor}>
            {toastMessage}
          </Alert>
        </Snackbar>
      </div>
    )
  }

  return {
    renderToast
  }
}

export default useToast;