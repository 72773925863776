import React from 'react';

import ReflectionDashboard from '../reflection/ReflectionDashboard';
import WithProjectTemplate from '../common/WithProjectTemplate';

const ReflectionDashboardPage = () => {
  return (
    <div className="page-container">
      <WithProjectTemplate>
        <ReflectionDashboard />
      </WithProjectTemplate>
    </div>
  )
}

export default ReflectionDashboardPage;