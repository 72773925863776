import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: '20px',
  },
  pageContainer: {
    padding: 0,
    background: '#fff',
    minHeight: '100vh',
    height: 'auto'
  },
  title: {
    color: '#000',
    marginBottom: '24px'
  }
});

export default useStyles;