import React from 'react'
import { AppBar, 
          Box, 
          Button, 
          Container,
          FormControl,
          FormControlLabel,
          Icon,
          RadioGroup,
          Radio,
          Toolbar,
          Typography } from '@material-ui/core'
import { useStyles } from '../../styles/giftBox';
import clsx from 'clsx';

import HamburgerIcon from 'icons/hamburger.svg';
import Fade from '@material-ui/core/Fade';
import ProjectContext from '../../contexts/projectContext';
import {navigate} from "@reach/router";
import BurgerMenu from "../common/BurgerMenu";
import { useNotifyGiftbox} from '../../api/project';

const ReflectionGiftBox = () => {
  const classes = useStyles();
  const { currentProject } = React.useContext(ProjectContext);
  const [open, setOpen] = React.useState(false);
  const [notifyChecked, setNotifyChecked] = React.useState(currentProject.notifyForGiftbox);
  const [dontNotifyChecked, setDontNotifyChecked] = React.useState(!currentProject.notifyForGiftbox);
  const [ notifyGiftbox, { loading: updatingNotify } ] = useNotifyGiftbox();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleRadioChangeNotify = (e) => {
    notifyGiftbox({ variables: { id: currentProject.id, notify_for_giftbox: true } })
    setNotifyChecked(true);
    setDontNotifyChecked(false);
  }

  const handleRadioChangeDontNotify = (e) => {
    notifyGiftbox({ variables: { id: currentProject.id, notify_for_giftbox: false } })
    setNotifyChecked(false);
    setDontNotifyChecked(true);
  }

  const RadioButton = (props) => {
    return (
      <Radio
        className={clsx(classes.radio)}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }

  const goTo = (destination) => {
    navigate(destination)
  }

  const upperFirstName = currentProject?.firstName.toUpperCase();
  const onNext = () => {
    navigate(`/project/${currentProject.slug}/reflection/pay-forward`)
  }

  return (
    <Fade in={true} unmountOnExit>
      <div className={clsx('page-container', classes.root)}>
        <Container disableGutters={true} maxWidth="sm">
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
            color="default"
            style={{ background: `#fff`, padding: `24px 16px 0 16px`, borderBottom: `1px solid #595959` }}
          >
            <Toolbar disableGutters={true} style={{ justifyContent: 'space-between' }}>
              <Box>
                <Typography variant="h6" className={clsx('lora')} color="primary">
                  The Empathy Project
                </Typography>
              </Box>
              <Box><Icon fontSize="large" onClick={handleDrawerOpen}><img src={HamburgerIcon} /></Icon></Box>
            </Toolbar>
          </AppBar>
          <BurgerMenu
            open={open}
            setOpen={setOpen}
            currentProject={currentProject}
          />
          <Box className={clsx(classes.viewContent)} p="16px">
            <Box>

              <Box mb="34px" className="bold" style={{ fontSize: '18px', lineHeight: '24px', textAlign: 'center' }}>
                {`${upperFirstName}’S GIFTBOX`}
              </Box>

              <Box mb="24px" className={ clsx('bold', classes.intro) }>
                Welcome to the most exciting part of our Empathy Project - your personalized Giftbox!
              </Box>

              <Box mb="24px" className={ clsx(classes.intro) }>
                By completing your <Typography display="inline" className={clsx('bold', 'underline')} onClick={() => goTo(`/project/${currentProject.slug}/reflection/view`)}>360 View</Typography> and <Typography display="inline" className={clsx('bold', 'underline')} onClick={() => goTo(`/project/${currentProject.slug}/back-to-future`)}>Back to Future</Typography> Reflection Sheets, you allow us to apply our Empathy Software Algorithms to find you:
              </Box>

              <Box mb="24px" mx="12px" px="20px" py="30px" className={ clsx(classes.giftBox) } style={{ background: '#01CDC2' }}>
                Curated solutions to increase the positives (gains, as we call them) in your life
              </Box> 

              <Box mb="24px" mx="12px" px="20px" py="30px" className={ clsx(classes.giftBox) } style={{ background: '#DD3874' }}>
                Curated solutions to increase the positives (gains, as we call them) in your life
              </Box> 

              <Box mb="24px" mx="12px" px="20px" py="30px" className={ clsx(classes.giftBox) } style={{ background: '#B08EB1' }}>
                Curated solutions to increase the positives (gains, as we call them) in your life
              </Box> 

              <Box mb="24px" mx="12px" px="20px" py="30px" className={ clsx(classes.giftBox) } style={{ background: '#8C9CD7' }}>
                Curated solutions to increase the positives (gains, as we call them) in your life
              </Box> 

              <Box mb="44px" mx="12px" px="20px" py="30px" className={ clsx(classes.giftBox) } style={{ background: '#919191' }}>
                Curated solutions to increase the positives (gains, as we call them) in your life
              </Box>   

              <Box mb="24px" px="12px">
                <FormControl component="fieldset">
                  <RadioGroup aria-label="notify" name="notify">
                    <FormControlLabel control={<RadioButton checked={notifyChecked} onChange={(e) => handleRadioChangeNotify(e)}/>}
                    label={
                      <Box className={clsx(classes.radioBtn)}>
                        <Box style={{ fontWeight: 600 }}>NOTIFY ME ABOUT MY GIFTBOX</Box>
                        <Box>(Whenever I have a new gift )</Box>
                      </Box>
                    } />
                    <FormControlLabel control={<RadioButton checked={dontNotifyChecked} onChange={(e) => handleRadioChangeDontNotify(e)}/>}
                    label={
                      <Box className={clsx(classes.radioBtn)}>
                        <Box style={{ fontWeight: 600 }}>NO THANKS, PLS DO NOT NOTIFY ME</Box>
                      </Box>
                    } />
                  </RadioGroup>
                </FormControl>
              </Box>      

              <Box mb="16px">
                <Button variant="contained" color="primary" onClick={onNext} disabled={updatingNotify}>
                  Next
                </Button>
              </Box>

            </Box>
          </Box>
        </Container>
      </div>
    </Fade>
  )
}

export default ReflectionGiftBox;
