import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Icon,
  Toolbar,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import isNil from 'lodash-es/isNil';

import { useStyles } from '../../styles/reflectionDashboard';
import { useReflectionViewVisited } from "../../api/project";

import ProjectContext from '../../contexts/projectContext';
import BurgerMenu from "../common/BurgerMenu";
import HamburgerIcon from 'icons/hamburger-white.svg';

import Carousel from './Carousel';
import TepLogo from 'icons/tep-logo.png';
import ReflectionFooter from './ReflectionFooter';
import ReflectionPayForwardModal from './ReflectionPayForwardModal';
import ReflectionModal from './ReflectionModal';
import Footer from '../pages/Footer';
import RoomsCards from './RoomsCards';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const ReflectionDashboard = () => {
  const classes = useStyles();
  const { currentProject, setCurrentProject } = React.useContext(ProjectContext);
  const [open, setOpen] = React.useState(false);
  const [isPayForwardModalOpen, setPayForwardModalOpen] = useState(false);
  const [ markAsViewed, {}] = useReflectionViewVisited(onMarkViewedSuccess);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  function onMarkViewedSuccess(response) {
    if (!isNil(response.reflectionViewVisited) && response.reflectionViewVisited.success) {
      setCurrentProject({
        ...currentProject,
        reflectionViewVisited: true
      })
    }
  }

  function setPayForwardModalVisible() {
    setPayForwardModalOpen(!isPayForwardModalOpen);
  }

  function renderPayForwardModal() {
    if (!isPayForwardModalOpen) return null;
    return <ReflectionPayForwardModal open={isPayForwardModalOpen} onClose={setPayForwardModalVisible} />
  }

  function onReflectionModalClose() {
    markAsViewed({ variables: { id: currentProject.id, reflection_view_visited: true } })
  }

  function renderReflectionModal() {
    if (currentProject.reflectionViewVisited) return null;
    return <ReflectionModal
      open={true}
      onClose={onReflectionModalClose}
    />
  }

  return (
    <div className={clsx('page-container', classes.root)}>
      <Container disableGutters={true} maxWidth="sm">
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
          color="default"
          style={{ background: `#4D4D4D`, padding: `24px 16px 0 16px` }}
        >
          <Toolbar disableGutters={true}>
            <Grid xs container>
              <Grid item container xs={10} alignItems="center">
                <Grid item>
                  <img src={TepLogo} style={{ marginBottom: '-10px', marginRight: '10px', width: '60px' }} />
                </Grid>
                <Grid item>
                  <Typography variant="h6" className={clsx('lora')} style={{ color: '#fff' }}>
                    The Empathy Project
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={2} justify="flex-end">
                <Icon fontSize="large" onClick={handleDrawerOpen}><img src={HamburgerIcon} /></Icon>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <BurgerMenu
          open={open}
          setOpen={setOpen}
          currentProject={currentProject}
        />

        <Box className={clsx(classes.dashboardContent)} mt="80px" pt="10px">
          <Box my="20px" px="16px" component="div">
            <Carousel />
          </Box>

          <RoomsCards currentProject={currentProject} />
          <Box my="24px" px="16px">
            <Button variant="contained" size="medium" style={{ backgroundColor: '#F98500', 'color': '#fff' }}
                    onClick={setPayForwardModalVisible}>
              Pay It Forward
            </Button>
            {renderPayForwardModal()}
          </Box>

          <ReflectionFooter />
          <Box pt="24px" style={{ backgroundColor: '#fff', color: '#595959' }}>
            <Footer />
          </Box>
        </Box>
        {renderReflectionModal()}
      </Container>
    </div>
  )
}

export default ReflectionDashboard;
