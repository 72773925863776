import React from 'react';
import { Box } from '@material-ui/core'
import { FormControl, Icon, InputLabel } from '@material-ui/core'
import { NativeSelect } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser';
import isNil from 'lodash-es/isNil';
import ChevronDownIcon from 'icons/chevron-down.svg';

const Dropdown = (props) => {
  const { label, onChange, name, options, defaultValue, fontColor, description, placeholder } = props;

  // Native select target value is undefined for some reason
  // added this workaround to get the selected value.
  // TODO: revisit this

  const finalOptions = [...options]

  const handleChange = (event) => {
    let value = '';
    value = finalOptions[event.target.options.selectedIndex].identifier;
    onChange({ field: name, value: value });
  }

  return (
    <Box mb="24px">
      <FormControl fullWidth>
        { label && <Box mb="24px" className="component-label" style={{ color: fontColor }}>{ReactHtmlParser(label)}</Box> }
        { description && <Box className="component-label-description" style={{ color: fontColor }}>{ReactHtmlParser(description)}</Box> }
        <InputLabel id="demo-mutiple-name-label">{placeholder}</InputLabel>
        <NativeSelect
          onChange={handleChange}
          inputProps={{
            name: name,
            id: 'dropdown',
          }}
          IconComponent={() => (
            <Icon fontSize="small"><img src={ChevronDownIcon} /></Icon>
          )}
          value={!isNil(defaultValue) ? defaultValue : ''}
          placeholder={placeholder}
        >
          {finalOptions.map((option) => {
            return (
              <option key={option.id} value={option.identifier}>{option.title}</option>
            )
          })}
        </NativeSelect>
        {/* <FormHelperText>Uncontrolled</FormHelperText> */}
      </FormControl>
    </Box>
  )
}

const MemoizedComponent = React.memo(Dropdown);

export default MemoizedComponent;