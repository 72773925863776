import React, { useContext } from 'react';
import clsx from "clsx";
import {Box, Button, Icon, TextField} from '@material-ui/core';
import { useCreateProject } from '../../api/project';
import { buildValidationSchema } from '../../validations/createProject';
import useForm from "../../hooks/useForm";
import {isNil} from "lodash-es";
import {navigate} from "@reach/router";
import ApplicationContext from '../../contexts/applicationContext';
import useToast from '../../hooks/useToasti';
import ChevronRightWhite from 'icons/chevron-right-white.svg'
import CircularProgress from '@material-ui/core/CircularProgress';

const CreateProjectForm = () => {
  const defaultState = {
    first_name: '',
    last_name: '',
    email: ''
  }
  const [createProject, { loading: isCreating }] = useCreateProject(onCompleted);
  const { setToastOpen, setToastMessage, setToastColor } = useContext(ApplicationContext)
  const { renderToast } = useToast();

  function onCompleted(response) {
    if (!isNil(response.createProject)) {
      const { project } = response.createProject
      navigate(`/invite/step2`, { state: {project: project} })
    } else {
      setToastColor('error')
      setToastMessage('This user already exists.\nTry using another email address.')
      setToastOpen(true)
    }
  }

  function submit() {
    createProject({ variables: { ...values } });
  }

  const { values, handleSpecificChange, handleSubmit, errors } = useForm({
    defaultState,
    validationSchema: buildValidationSchema(),
    submitAction: submit
  })

  function nextButton() {
    if (isCreating) {
      return <CircularProgress color="secondary" />;
    } else {
      return <Icon fontSize="large"><img src={ChevronRightWhite} /></Icon>
    }
  }

  return (
    <>
      <Box mt="44px" className={clsx('default-content')}>
        { renderToast() }
        <Box>
          Who should you invite?
        </Box>
        <Box mt="24px">
          Someone you can have a good conversation with, a person you would enjoy sharing 60 minutes with in open, honest tallk.
        </Box>
        <Box mt="24px">
          I'd like to invite:
        </Box>
        <Box mx="16px">
          <Box>
            <TextField
              label="First Name"
              placeholder="Firstname"
              margin="normal"
              name="first_name"
              onChange={(event) => handleSpecificChange({ field: 'first_name', value: event.target.value })}
              error={!isNil(errors.first_name)}
              helperText={errors.first_name}
              fullWidth
            />
          </Box>
          <Box>
            <TextField
              name="last_name"
              label="Last Name"
              placeholder="Lastname"
              margin="normal"
              onChange={(event) => handleSpecificChange({ field: 'last_name', value: event.target.value })}
              error={!isNil(errors.last_name)}
              helperText={errors.last_name}
              fullWidth
            />
          </Box>
        </Box>
      </Box>
      <Box mt="64px" style={{ display: 'flex', justifyContent: 'center' }}>
        <Box className={ clsx('next-button') }
          style={{ border: `1px solid #595959`, backgroundColor: '#595959' }}
          onClick={() => handleSubmit()}
          disabled={isCreating}
        >
          {nextButton()}
        </Box>
      </Box>
    </>
  )
}

export default CreateProjectForm;
