import React from 'react';

export const defaultContextValue = {
  setUser: (user) => user,
  user: undefined,
  toastMessage: '',
  toastOpen: false,
  toastColor: 'success',
  setToastMessage: '',
  setToastOpen: false,
  setToastColor: ''
};

const ApplicationContext = React.createContext(defaultContextValue);

export default ApplicationContext;
