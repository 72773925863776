import React from 'react';
import { Router, Redirect } from '@reach/router';
import { ProtectedRoute, PublicRoute } from './routes';

import Signup from '../components/pages/Signup';
import Login from '../components/pages/Login';
import Dashboard from '../components/pages/Dashboard';
import Preamble from '../components/pages/Preamble';
import InvitationClara from '../components/pages/InvitationClara';
import ProjectInvitation from '../components/pages/ProjectInvitation';
import InvitationEnd from '../components/pages/InvitationEnd';
import InviteSent from '../components/pages/InviteSent';
import GoLiveEnd from '../components/pages/GoLiveEnd';
import GoLive from '../components/pages/GoLive';
import GoLiveNext from '../components/pages/GoLiveNext';
import Intaker from '../components/pages/Intaker';
import CreateProject from '../components/pages/CreateProject';
import IntakerThankYou from '../components/pages/IntakerThankYou';
import IntakerEnd from '../components/pages/IntakerEnd';
import ReflectionView from '../components/pages/ReflectionView';
import ReflectionCoverLetter from '../components/pages/ReflectionCoverLetter';
import PayForwardWelcome from '../components/pages/PayForwardWelcome';
import PayForwardReflection from '../components/pages/PayForwardReflection';
import PayForwardLink from '../components/pages/PayForwardLink';
import PayForwardEnd from '../components/pages/PayForwardEnd';
import ReflectionLetterMyself from '../components/pages/ReflectionLetterMyself';
import ReflectionPayForward from '../components/pages/ReflectionPayForward';
import ReflectionGiftBox from '../components/pages/ReflectionGiftBox';
import BackToFuture from '../components/pages/BackToFuture';
import ReflectionDashboard from '../components/pages/ReflectionDashboard';
import ReflectionDashboardDragDrop from '../components/reflection/ReflectionDashboardDragDrop';
import DataPrivacy from '../components/pages/DataPrivacy';
import Terms from '../components/pages/Terms';
import RoomView from '../components/pages/RoomView';

const Routes = () => {
  return (
    <Router>
      <Redirect from='/reset_password' to='/login' state={{ isResettingPassword: true }} noThrow />
      <InviteSent path='invite/sent' />
      <PublicRoute path='/' component={Preamble} />
      <PublicRoute path='login' component={Login} />
      <PublicRoute path='signup' component={Signup} />
      <PublicRoute path='privacy-policy' component={DataPrivacy} />
      <PublicRoute path='terms' component={Terms} />
      <PublicRoute path='project/:projectSlug/reflection/view' component={ReflectionView} />
      <PublicRoute path='project/:projectSlug/reflection/cover-letter' component={ReflectionCoverLetter} />
      <PublicRoute path='project/:projectSlug/reflection/letter-to-myself' component={ReflectionLetterMyself} />
      <PublicRoute path='project/:projectSlug/reflection/dashboard' component={ReflectionDashboard} />
      <PublicRoute path='project/:projectSlug/reflection/drag-drop' component={ReflectionDashboardDragDrop} />
      <PublicRoute path='invite/:projectSlug' component={InvitationClara}/>
      <ProtectedRoute path='project/:projectSlug/reflection/gift-box' component={ReflectionGiftBox} />
      <ProtectedRoute path='project/:projectSlug/reflection/pay-forward' component={ReflectionPayForward} />
      <ProtectedRoute path='project/:projectSlug/go-live-start' component={GoLive} />
      <ProtectedRoute path='project/:projectSlug/go-live-next' component={GoLiveNext} />
      <ProtectedRoute path='project/:projectSlug/go-live-end' component={GoLiveEnd} />
      <ProtectedRoute path='project/create' component={CreateProject}/>
      <ProtectedRoute path='project/:projectSlug/invite-sent' component={InviteSent}/>
      <ProtectedRoute path='invite/step2' component={ProjectInvitation}/>
      <ProtectedRoute path='invite/step3' component={InvitationEnd}/>
      <ProtectedRoute path='project/:projectSlug/intaker' component={Intaker} />
      <ProtectedRoute path='project/:projectSlug/intaker/thank-you' component={IntakerThankYou} />
      <ProtectedRoute path='project/:projectSlug/intaker/end' component={IntakerEnd} />
      <ProtectedRoute path='project/:projectSlug/pay-forward/welcome' component={PayForwardWelcome} />
      <ProtectedRoute path='project/:projectSlug/pay-forward/reflection' component={PayForwardReflection} />
      <ProtectedRoute path='project/:projectSlug/pay-forward/link' component={PayForwardLink} />
      <ProtectedRoute path='project/:projectSlug/pay-forward/end' component={PayForwardEnd} />
      <ProtectedRoute path='project/:projectSlug/back-to-future' component={BackToFuture} />
      {/* <ProtectedRoute path='project/:projectSlug/room-view' component={RoomView} /> */}
      <ProtectedRoute path='project/:projectSlug/reflection/rooms/:roomId' component={RoomView} />
      <ProtectedRoute path='dashboard' component={Dashboard} />
    </Router>
  )
}

export default Routes;
