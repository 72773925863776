import React from 'react';

import ReflectionView from '../reflection/ReflectionView';
import WithProjectTemplate from '../common/WithProjectTemplate'

const ReflectionViewPage = () => {
  return (
    <WithProjectTemplate>
      <ReflectionView />
    </WithProjectTemplate>
  )
}

export default ReflectionViewPage;