import React from 'react';
import { Box } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Icon } from '@material-ui/core'
import { InputAdornment } from '@material-ui/core'
import Pencil from 'icons/pencil.svg';
import ReactHtmlParser from 'react-html-parser';
import isNil from 'lodash-es/isNil';

const Textfield = (props) => {
  const { label, onChange, name, defaultValue, description, fontColor, placeholder, error } = props;

  return (
    <Box mb="24px" style={{ color: fontColor }}>
      { label && <Box mb="24px" className="component-label" style={{ color: fontColor }}>{ReactHtmlParser(label)}</Box> }
      { description && <Box className="component-label-description" style={{ color: fontColor }}>{ReactHtmlParser(description)}</Box> }
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Icon fontSize="small"><img src={Pencil} /></Icon>
            </InputAdornment>
          )
        }}
        fullWidth
        onChange={(event => onChange({ field: name, value: event.target.value }))}
        value={!isNil(defaultValue) ? defaultValue : ''}
        placeholder={placeholder}
        error={!isNil(error)}
        helperText={error}
      />
    </Box>
  )
}

const MemoizedComponent = React.memo(Textfield);

export default MemoizedComponent;