import { makeStyles } from '@material-ui/styles'
import ReflectionCoverBackground from 'icons/cover-background.png';
import ReflectionLetterBackground from 'icons/letter-background.svg';

const drawerWidth = 240;

export const useStyles = makeStyles(theme => ({
  reflectionCoverContent: {
    background: `linear-gradient(180deg, rgba(136, 208, 207, 0.1) 0%, rgba(201, 216, 216, 0.1) 58.94%, rgba(235, 132, 178, 0.1) 100%), 
    url(${ReflectionCoverBackground}) no-repeat bottom`,
    height: '100%'
  },
  reflectionLetterContent: {
    background: `url(${ReflectionLetterBackground}) no-repeat`,
    backgroundSize: 'cover',
    height: '100%'
  },
  viewContent: {
    height: '100%',
    fontSize: '16px',
    lineHeight: '18px'
  },
  h2: {
    textAlign: 'center'
  },
  content: {
    textAlign: 'center',
    margin: '12px 24px 24px 24px',
    fontSize: '16px'
  },
  root: {
    display: 'flex',
    backgroundColor: '#fff'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
    background: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    padding: '16px'
  },
  drawerHeader: {
    marginBottom: '34px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  ribbonContainer: {
    position: 'absolute',
    right: '-20px',
    top: '-25px',
    margin: 0,
    padding: 0
  },
  coverContainer: {
    backgroundColor: '#fff',
    padding: `64px 24px 44px 24px`,
    position: 'relative',
    margin: '24px 0 44px'
  },
  letterContainer: {
    background: `rgba(255, 255, 255, 0.4)`,
    border:` 1px solid #F0F7FB`,
    fontFamily: 'Merriweather',
    padding: `64px 24px 44px 24px`,
    position: 'relative',
    margin: '104px 16px 44px'
  },
  coverLetter: {
    fontFamily: 'Merriweather',
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.primary.main
  },
  parts: {
    paddingLeft: '16px',
    fontFamily: `'Open Sans', sans-serif`,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    textDecoration: 'underline',
    color: '#6C6C6C'
  },
  thoughts: {
    fontSize: '16px',
    lineHeight: '24px',
    marginLeft: '16px',
    fontStyle: 'italic'
  },
  fewPages: {
    fontFamily: `'Open Sans', sans-serif`,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 700
  },
  weeklyProgress: {
    marginBottom: '24px',
    backgroundColor: '#fff'
  },
  weeklyProgressLabel: {
    textAlign: 'right',
    fontSize: '12px',
    fontWeight: 'bold'
  },
  progressActivityLabel: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'bold'
  },
  weeklyProgressHappyLabel: {
    fontSize: '10px',
    lineHeight: '21px'
  },
  weeklyProgressPercentage: {
    fontSize: '12px',
    lineHeight: '21px'
  },
  buttonActive: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: '#fff',
    border: `1px solid #595959`,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '24px',
    width: '100px',
    height: '30px'
  },
  buttonInactive: {
    backgroundColor: '#fff',
    color: `${theme.palette.primary.main} !important`,
    border: `1px solid #595959`,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '24px',
    width: '100px',
    height: '30px'
  },
  uiCenter: {
    textAlign: 'center'
  },
  carouselContainer: {
    position: 'relative',
    height: 'auto',
    maxWidth: '960px',
    margin: '0 auto',
    // overflow: 'hidden',
    touchAction: 'pan-y'
  },
  carouselTrack: {
    display: 'flex',
    height: '100%'
  },
  carouselCard: {
    flex: '0 0 337px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'white'
  },
  carouselCardInner: {
    flexDirection: 'column',
    width: '317px',
    height: 'auto',
    marginTop: '48.5px'
  },
  carouselCardContainerTop: {
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    paddingBottom: '10px',
    margin: '0 16px'
  },
  carouselCardContainerBottom: {
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    padding: '16px',
    margin: '0 16px',
    height: '162px',
    color: theme.palette.primary
  },
  carouselCardContainerBottomContent: {
    height: '100%',
    overflowX: 'scroll'
  },
  carouselTitle: {
    marginTop: '20px',
    fontSize: '1.5em'
  },
  carouselText: {
    padding: '1em',
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    fontFamily: 'sans-serif'
  },
  carouselPaginationWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    pointerEvents: 'none'
  },
  carouselPagination: {
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'space-around',
    width: '180px',
    padding: 0
  },
  carouselCardImage: {
    width: '97px',
    height: '97px',
    borderRadius: '50%',
    textAlign: 'center',
    margin: '0px auto',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '-48.5px',
    backgroundColor: '#fff'
  },
  carouselCardHighlight: {
    height: '90px',
    fontSize: '16px',
    lineHeight: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  pointsWrapper: {
    // position: 'absolute',
    // top: '170px',
    // width: '100%',
    // height: `calc(100vh - 200px)`,
    // padding: '16px'
    marginTop: '60px'
  },
  pointsContainer: {
    // backgroundColor: theme.palette.primary.main,
    // boxShadow: `0px 4px 9px rgba(133, 133, 133, 0.25)`,
    // padding: '44px 16px 0 16px',
    // borderRadius: '10px',
    // color: '#fff',
    // height: '100%',
    // overflow: 'scroll'
  },
  pointsButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    bottom: '117px',
    zIndex: '99',
    right: '16px'
  },
  pointsButtonContainer: {
    width: '71px',
    height: '71px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 4px 9px rgba(133, 133, 133, 0.25)'
  },
  pointsTitleContainer: {
    width: '255px',
    height: '71px',
    display: 'flex',
    background: '#595959',
    alignItems: 'center',
    borderRadius: '50px',
    justifyContent: 'center',
    position: 'fixed',
    right: '16px',
    zIndex: '99',
    color: '#fff',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.13em',
    padding: '12px',
    fontWeight: 600
  },
  btnSave: {
    height: '22px',
    fontSize: '12px',
    lineHeight: '16px',
    width: '53px'
  },
  sidebarHeader: {
    height: '100%',
    fontSize: '18px',
    color: theme.palette.primary.main
  },
  sidebarLink: {
    fontSize: '14px',
    lineHeight: '19px',
    color: '#A39C9C'
  },
  sidebarList: {
    padding: '16px 0'
  }
}));