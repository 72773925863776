import React from 'react'
import {Box, Grid, Link, Paper, Typography} from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "../../styles/invitation";
import EnvelopeIcon from 'icons/envelope.svg'
import CardsIcon from 'icons/cards-outline.svg'

const Invitation = (props) => {
  const classes = useStyles();
  return (
    <Typography align="center" variant="body1" component="div">
      <Paper elevation={3} className={ clsx(classes.invitation) }>
        <Box className={ clsx(classes.image) }>
          <img src={EnvelopeIcon} />
        </Box>
        <Box mb="12px">
          Starts with this invitation,<br /> from me, to you.
        </Box>
        <Box my="12px">
          Let's catch up in a different way <br />- yes, about life these days <br />but we'll go deeper.
        </Box>
        <Box my="12px">
          I will use digital flashcards<br />
          to take us on this Empathy Project,<br />
          which is simply a 60-minute<br />
          conversation that gets more powerful<br />
          the deeper we get into it.
        </Box>
        <Box my="12px">
          As a friend so kindly took me<br />
          on this one-of-a-kind experience,<br />
          so I now invite you to join me<br />
          for life's most important conversations
        </Box>
        <Box my="12px" mb='44px'>
          Let me know when you're open?
        </Box>
        <Box className={ clsx(classes.learn, 'bold') } mb="32px">
          <Link href="https://empathy100.io/" underline="always" target="_blank">LEARN MORE</Link>
        </Box>
        <Box className={ clsx(classes.learn, 'bold') } mb="44px">
          <Grid xs container direction="row" alignItems="center" justify="center">
            <Grid item>
              <img src={CardsIcon} />
            </Grid>
            <Grid item>
              <Box ml="5px" style={{ textAlign: 'left' }}><Link href="/dashboard" underline="always">Access Flashcards</Link></Box>
              <Box ml="5px" style={{ fontWeight: 'normal' }}><Link href="/dashboard" underline="always">(for project initiator only)</Link></Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Typography>
  )
}

export default Invitation;