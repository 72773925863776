import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import isNil from 'lodash-es/isNil';
import max from 'lodash-es/max';

import SegmentStepForm from './SegmentStepForm';
import useProjectQqlHandler from '../../hooks/useProjectGqlHandler';

const useStyles = makeStyles(() => ({
  parent: {
    height: '100%',
    width: '100%',
    position: 'absolute'
  },
  stack: {
    position: 'absolute',
    left: '0',
    top: '0',
    maxHeight: '100%'
  }
}));

const SegmentSteps = ({ steps, currentProject, nextStep }) => {
  const classes = useStyles();
  const ref = React.useRef();
  const { currentSegment, setCurrentStepIndex } = useProjectQqlHandler();
  const [currentTopStep, setCurrentTopStep] = React.useState();
  const [lastStep, setLastStep] = React.useState();

  React.useEffect(() => {
    setCurrentTopStep(setTopStep());
    setLastStep(setBottomStep());
  }, [currentSegment])

  // Useful for determining max step using display order
  function setBottomStep() {
    const displayOrders = steps.map((step) => step.displayOrder);
    return max(displayOrders);
  }

  // Useful for determining top step to show initially
  function setTopStep() {
    if (!isNil(currentProject.currentStep)) {
      const step = steps.find((step) => step.id.toString() === currentProject.currentStep.toString())
      if (!isNil(step)) return step.displayOrder;
    }
    return 1;
  }

  // Use for moving forward
  function handleNext() {
    ref.current.scrollTo(0, 0);
    setCurrentTopStep(currentTopStep + 1);
  }

  // Use for moving backward
  function handlePrevious() {
    if (currentTopStep === 1 && !currentSegment.hideSegmentHeader) {
      if (!isNil(currentSegment.description)) {
        return setCurrentStepIndex('segment_description');
      } else if (isNil(currentSegment.description)) {
        return setCurrentStepIndex('segment_header');
      }
    }

    setCurrentTopStep(currentTopStep - 1);
  }

  // We want to conditionally control the height
  // of the stacked cards so that they will be the same initially
  const cardStyles = (step, index) => {
    const styles = {
      zIndex: step.displayOrder === currentTopStep ? steps.length : steps.length - index,
    }

    if (step.displayOrder === currentTopStep) {
      return styles;
    }

    return {
      ...styles,
      maxHeight: '100%',
      overflow: 'hidden'
    }
  }

  return (
    <Slide direction={"left"} in={true} mountOnEnter unmountOnExit>
      <div ref={ref} className={clsx(classes.parent)}>
        {steps.map((step, index) => {
          let divProps = {
            className: clsx(classes.stack),
            style: cardStyles(step, index),
            key: step.id
          }

          const component = (
            <div {...divProps}>
              <Container maxWidth="sm" className={ clsx('container-with-gutter', 'segment-container') }>
                <SegmentStepForm 
                  step={step}
                  index={step.displayOrder}
                  isLastStep={lastStep === step.displayOrder}
                  isFirstStep={1 === step.displayOrder}
                  currentStepIndex={currentTopStep}
                  handlePrevious={handlePrevious}
                  handleNextNew={handleNext}
                  setNextStep={nextStep}
                />
              </Container>
            </div>
          )
          return component;
        })}
      </div>
    </Slide>
  )
}

const MemoizedComponent = React.memo(SegmentSteps);

export default MemoizedComponent;