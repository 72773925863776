import React, { useEffect, useContext, useState, withState } from 'react';
import clsx from 'clsx';
import { Box, Grid } from '@material-ui/core';
import PinIcon from 'icons/push-pin.png';
import SemiCircleProgressBar from 'react-progressbar-semicircle';
import isNil from 'lodash-es/isNil';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link, navigate } from '@reach/router';

import { useStyles } from '../../styles/reflectionDashboard';
import { useFetchRooms } from '../../api/reflection';

import ProjectContext from '../../contexts/projectContext';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const DragDrop = ({ currentProject }) => {
  const { id: projectId, slug } = currentProject;
  const { setRoomId } = useContext(ProjectContext);

  const classes = useStyles();

  const [ fetchRooms, { loading, error, data } ] = useFetchRooms(onFetchSuccess);
  const [ rooms, setRooms ] = useState();

  useEffect(() => {
    fetchRooms({ variables: { id: projectId } })
  }, []);

  function onFetchSuccess() {
    setRooms(data.fetchRooms);
  }

  function tryAgainOnClick(event) {
    event.preventDefault();
    fetchRooms({ variables: { id: projectId } })
  }

  function onEnterClick(event, room) {
    event.preventDefault();
    navigate(`/project/${slug}/reflection/rooms/${room.id}`);
  }

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "none",
    padding: 4
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
  
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "none",
  
    // styles we need to apply on draggables
    ...draggableStyle
  });

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    const newState = [...rooms];
    const b = newState[dInd];
    newState[dInd] = newState[sInd];
    newState[sInd] = b;
    setRooms(newState);
  }

  if (loading || isNil(rooms)) {
    return (
      <div className={clsx(classes.roomsLoaderContainer)}>
        <Box px="50px" my="20px" style={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      </div>
    );
  }

  if (!isNil(error)) {
    return (
      <div className={clsx(classes.roomsErrorContainer)}>
        <Box px="50px" my="20px" style={{ textAlign: 'center' }}>
          <p>Ooops! An error occured. Try again by clicking try again.</p>

          <a href="#" onClick={tryAgainOnClick} className={clsx(classes.tryAgainLink)}>Try again</a>
        </Box>
      </div>
    )
  }

  function renderCards() {
    return rooms.map((room, index) => {
      if (index === 0) {
        return (<Grid 
          item xs={4} key={room.title}
          component="div">
          <Box className={clsx(classes.roomCard)}
            style={{ backgroundColor: '#444', backgroundImage: `url(${room.backgroundImageUrl})`, backgroundSize: 'cover' }}>

            <Grid container style={{ height: '40px', display: 'flex' }}>
              <div className={clsx(classes.cardName)}>{room.title}</div>
              {/* TODO: FIX THIS */}
              {index === 0 && (
                <div className={clsx(classes.pin)}><img src={PinIcon} /></div>
              )}
            </Grid>
            <Box className={clsx(classes.percentageContainer)}>
              <SemiCircleProgressBar percentage={100} showPercentValue diameter={90} strokeWidth={4} />
            </Box>
            <Box className={clsx(classes.score)}>Priority Score</Box>
            {/*<Box mt="20px" className={clsx(classes.notifContainer)}>*/}
            {/*  <Box className={clsx(classes.notif)}>{room.notificationCount}</Box>*/}
            {/*</Box>*/}
            <Box mt="24px" className={clsx(classes.notifContainer)}>
              <a 
                href="#"
                onClick={(event) => onEnterClick(event, room)}
                className={clsx(classes.enterLink)}>
                  enter
              </a>
            </Box>

          </Box>
        </Grid>);
      } else {
        return (
          <Droppable key={room.id} droppableId={index.toString()}>
            {(provided, snapshot) => (
              <Grid 
                item xs={4} key={room.title}
                component="div"
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}>
                <Draggable
                  key={room.id}
                  draggableId={room.id.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      index={index}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Box className={clsx(classes.roomCard)}
                        style={{ backgroundColor: '#444', backgroundImage: `url(${room.backgroundImageUrl})`, backgroundSize: 'cover' }}>
  
                        <Grid container style={{ height: '40px', display: 'flex' }}>
                          <div className={clsx(classes.cardName)}>{room.title}</div>
                          {/* TODO: FIX THIS */}
                          {index === 0 && (
                            <div className={clsx(classes.pin)}><img src={PinIcon} /></div>
                          )}
                        </Grid>
                        <Box className={clsx(classes.percentageContainer)}>
                          <SemiCircleProgressBar percentage={100} showPercentValue diameter={90} strokeWidth={4} />
                        </Box>
                        <Box className={clsx(classes.score)}>Priority Score</Box>
                        {/*<Box mt="20px" className={clsx(classes.notifContainer)}>*/}
                        {/*  <Box className={clsx(classes.notif)}>{room.notificationCount}</Box>*/}
                        {/*</Box>*/}
                        <Box mt="24px" className={clsx(classes.notifContainer)}>
                          <a 
                            href="#"
                            onClick={(event) => onEnterClick(event, room)}
                            className={clsx(classes.enterLink)}>
                              enter
                          </a>
                        </Box>
  
                      </Box>
                    </div>
                    )}
                </Draggable>
              </Grid>
            )}
          </Droppable>  
        )
      }
      
    })
  }

  return (
    <Box component="div" px="16px" style={{ width: '100%' }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container spacing={1}>
          {renderCards()}
        </Grid>
      </DragDropContext>
    </Box>
  )
}

export default DragDrop;