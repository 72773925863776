import React from 'react'
import { Box,
         Grid,
         Container,
         Typography } from '@material-ui/core'
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles'
import CreateProjectForm from '../forms/CreateProjectForm';
import Background from 'icons/background.svg'
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles({
  root: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'top center'
  },
  h2: {
    textAlign: 'center'
  },
  content: {
    textAlign: 'center',
    margin: '12px 24px 24px 24px',
    fontSize: '16px'
  }
});

const CreateProject = () => {
  const classes = useStyles();

  return (
    <Fade in={true}>
      <div className={clsx('page-container', classes.root)}>
        <Container maxWidth="sm">
          <Box mt="64px">
            <Typography variant="h2" className={ clsx(classes.h2, 'lora') }>The Empathy Project</Typography>
          </Box>
          <Box m="32px">
            <Grid container justify="space-evenly" className={clsx('segment-subtitle')} style={{ color: '#C4C4C4' }}>
              <Grid item className={clsx('bold')} style={{ color: '#595959' }}>Step 1</Grid>
              <Grid item>|</Grid>
              <Grid item>Step 2</Grid>
              <Grid item>|</Grid>
              <Grid item>Step 3</Grid>
            </Grid>
          </Box>
          <CreateProjectForm />
        </Container>
      </div>
    </Fade>
  )
}

export default CreateProject