import React, { useContext, useEffect } from "react";
import {Box, Grid, Icon, TextField} from "@material-ui/core";
import ChevronRightWhite from 'icons/chevron-right-white.svg';
import clsx from "clsx";
import isNil from "lodash-es/isNil";
import {navigate} from "@reach/router";

import { buildValidationSchema } from '../../validations/sendPayForwardEmail';
import { useSendPayForwardEmail } from '../../api/payForward';
import useForm from '../../hooks/useForm';
import ApplicationContext from '../../contexts/applicationContext';
import ProjectContext from '../../contexts/projectContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import useToast from '../../hooks/useToasti';

const PayForwardReflectionForm = () => {
  const defaultState = {
    payForwardEmail: '',
    confirmPayForwardEmail: ''
  }
  const { currentProject } = React.useContext(ProjectContext);
  const { renderToast } = useToast();
  const { setToastOpen, setToastMessage, setToastColor } = useContext(ApplicationContext)
  const [sendPayforwardEmail, { loading, error }] = useSendPayForwardEmail(onUpdateCompleted, onUpdateFail);

  useEffect(() => {
    if (!isNil(error)) {
      setErrors({ payForwardEmail: error.message, confirmPayForwardEmail: error.message })
    }
  }, [error])

  function submit() {
    sendPayforwardEmail({ variables: { id: currentProject.id, email: values.payForwardEmail } })
  }

  const { values, handleSpecificChange, handleSubmit, errors: formErrors, setErrors } = useForm({
    defaultState,
    validationSchema: buildValidationSchema(),
    submitAction: submit
  })

  function onUpdateCompleted(response) {
    if (!isNil(response.addProjectUser) && response.addProjectUser.success) {
      navigate(`/project/${currentProject.slug}/pay-forward/link`)
    }
  }

  function onUpdateFail() {
    setToastColor('error')
    setToastMessage('Problem encountered. Please try again.')
    setToastOpen(true)
  }

  function nextButton() {
    if (loading) {
      return <CircularProgress color="secondary" />;
    } else {
      return (
        <Icon fontSize="large">
          <img src={ChevronRightWhite} />
        </Icon>
      )
    }
  }

  return (
    <>
      {renderToast()}
      <Grid item style={{ width: '100%' }}>
        <Box mb="12px" mx="16px">
          <TextField
            label='Email'
            name='payForwardEmail'
            placeholder="Email Address"
            margin="normal"
            onChange={(event) => handleSpecificChange({ field: 'payForwardEmail', value: event.target.value })}
            error={!isNil(formErrors.payForwardEmail)}
            helperText={formErrors.payForwardEmail}
            fullWidth
          />
        </Box>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <Box mb="12px" mx="16px">
          <TextField
            label='Confirm Email'
            name='confirmPayForwardEmailField'
            placeholder="Email Address"
            margin="normal"
            onChange={(event) => handleSpecificChange({ field: 'confirmPayForwardEmail', value: event.target.value })}
            error={!isNil(formErrors.confirmPayForwardEmail)}
            helperText={formErrors.confirmPayForwardEmail}
            fullWidth
          />
        </Box>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <Box mb="12px" mx="16px" className="component-label">
          Please expect the you received the email.<br />
          Sometimes it ends up in junk mail or span<br />
          box, If so, before opening the email, please<br />
          move it to your inbox.
        </Box>
      </Grid>
      <Box mt="30px" style={{ display: 'flex', justifyContent: 'center', marginBottom: '50px' }}>
        <Box className={ clsx('next-button') }
          style={{ border: `1px solid #595959`, backgroundColor: '#595959' }}
          onClick={() => handleSubmit()}
          disabled={loading}
        >
          {nextButton()}
        </Box>
      </Box>
    </>
  )
}

export default PayForwardReflectionForm;
