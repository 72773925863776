import React from 'react';
import { Box, Container, Grid, Icon, Paper, Typography } from '@material-ui/core';
import { navigate } from '@reach/router';
import clsx from 'clsx';

import { useStyles } from '../../styles/invitation';

import HandshakeIcon from 'icons/shake.svg'
import ChevronRightWhite from 'icons/chevron-right-white.svg'
import ProjectContext from '../../contexts/projectContext';
import WithProjectTemplate from '../common/WithProjectTemplate';
import cookie from "cookie_js";
import Fade from '@material-ui/core/Fade';

const GoLiveNext = () => {
  const classes = useStyles();
  const { currentProject } = React.useContext(ProjectContext);

  function onClickNext() {
    navigate(`/project/${currentProject.slug}/go-live-end`);
  }

  const renderGoLive = () => {
    return (
      <Fade in={true} unmountOnExit>
        <WithProjectTemplate>
          <Container maxWidth="sm" className={clsx(classes.layout)}>
            <Grid container component="div" spacing={0} direction="column" className={clsx('full-height')}>
              <Grid item>
                <Box my="44px">
                  <Typography align="center" className={ clsx(classes.title, 'lora') }>The Empathy Project</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box mx="12px" mt="80px">
                  <Paper elevation={3} className={clsx(classes.invitation)}>
                    <Box className={clsx(classes.image)}>
                      <img src={HandshakeIcon} />
                    </Box>
                    <Typography variant="body1" component="div" color="primary">
                      <Box mb="24px">
                        You will notice that the flashcards are designed to do 2 things:
                      </Box>
                    </Typography>
                    <Typography variant="body1" component="div" color="primary">
                      <Box>
                        1. Encourage your friend to tell
                      </Box>
                      <Box mb="12px" mb="24px">
                        <Typography display="inline" className={clsx('bold', 'underline')}>personal stories.</Typography>
                      </Box>
                    </Typography>
                    <Typography variant="body1" component="div" color="primary">
                      <Box>
                        2. Encourage you to listen deeply by finding <Typography display="inline" className={clsx('bold', 'underline')}>keywords</Typography> in your friend's stories and noting these <Typography display="inline" className={clsx('bold', 'underline')}>keywords</Typography>
                      </Box>
                      <Box mb="12px" mb="24px">
                        in the flashcards.
                      </Box>
                      <Box mb="24px">
                        This mix of <Typography display="inline" className={clsx('bold')}>stories and keywords</Typography> will be
                        your keys to a fruitful Empathy Project.
                      </Box>
                    </Typography>
                    <Typography variant="body1" component="div" color="primary">
                      <Box mb="24px">
                        Enjoy!
                      </Box>
                    </Typography>
                  </Paper>
                </Box>
              </Grid>
              <Grid item>
                <Box my="64px" onClick={onClickNext} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Box className={ clsx('next-button') }
                       style={{ border: `1px solid #595959`, backgroundColor: '#595959' }}>
                    <Icon fontSize="large"><img src={ChevronRightWhite} /></Icon>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </WithProjectTemplate>
      </Fade>
    )
  }

  return (
    <div className={clsx('page-container', classes.root)}>
      {renderGoLive()}
    </div>
  )
}

export default GoLiveNext;