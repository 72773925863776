import React, {useContext} from 'react';
import { Box, Button, Card, Container, Grid, Link, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Footer from './Footer'
import { useStyles } from '../../styles/invitation';
import {navigate} from '@reach/router';
import { useSendEmailInvite } from '../../api/project';
import ApplicationContext from '../../contexts/applicationContext';
import useToast from '../../hooks/useToasti';
import copy from 'copy-to-clipboard';
import Invitation from "./Invitation";

const InvitationClara = (props) => {
  const classes = useStyles();
  const onShare = () => {
    navigate('invite-sent')
  }

  const { renderToast } = useToast();
  const { setToastOpen, setToastMessage } = useContext(ApplicationContext)
  const [ sendEmailInvite ] = useSendEmailInvite(onEmailInvite)

  // TODO: Recheck this if we still need it
  const copyLink = () => {
    copy(`${process.env.REACT_APP_BASE_URL}/project/${props.projectId}/clara`);
    setToastMessage('Invitation link copied')
    setToastOpen(true);
  }

  function onEmailInvite() {
    setToastMessage('Email invite sent')
    setToastOpen(true);
  }

  // TODO: Recheck this if we still need it
  const emailInviteAction = () => {
    sendEmailInvite({ variables: { id: props.projectId } })
  };

  return (
    <div className={ clsx('page-container', classes.root) }>
      { renderToast() }
      <Container maxWidth="sm" className={ clsx(classes.layout) }>
        <Box mx="12px">
          <Box my="44px">
            <Typography align="center" className={ clsx(classes.title, 'lora') }>The Empathy Project</Typography>
          </Box>
          <Invitation />
        </Box>
        <Box mt="64px">
          <Footer />
        </Box>
      </Container>
    </div>
  )
}

export default InvitationClara;