import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import cookie from 'cookie_js';
import isNil from 'lodash-es/isNil';

const authLink = setContext((_, { headers }) => {
  const token = cookie.get('authentication_token');

  return {
      headers: {
          ...headers,
          authorization: !isNil(token) ? `Bearer ${token}` : '',
      },
  };
});

const httpLink = createHttpLink({
  uri: '/graphql',
  credentials: 'include'
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});