import React from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { Link } from "@reach/router";
import clsx from 'clsx';
import Footer from './Footer'

import { useStyles } from '../../styles/invitation';

const InviteSent = () => {
  const classes = useStyles();

  return (
    <div className={ clsx('page-container', classes.root) }>
      <Container maxWidth="sm" className={ clsx(classes.layout) }>
        <Box mx="12px">
          <Grid container component="div" spacing={0} direction="column" justify="space-between" className={ clsx('full-height') }>
            <Grid item>
              <Box>
                <Box my="44px">
                  <Typography align="center" variant="h2" color="primary" className={ clsx('lora') }>THE EMPATHY</Typography>
                  <Typography align="center" variant="h2" color="primary" className={ clsx('lora') }>PROJECT</Typography>
                </Box>
                <Typography variant="body1" component="div" align="center" color="primary">
                  <Box mb="12px">
                 When you and your friend are ready on your appointed date and time, you will be provided with digital flashcards to guide your conversation.
                  </Box>
                </Typography>
                <Typography variant="body1" component="div" align="center" color="primary">
                  <Box mt="12px" mb="44px">
                    We've sent you an email to access these tools. Or you can simply
                    <Link to="/dashboard" underline="always"> click here </Link>
                    to access your Empathy Project dashboard.
                  </Box>
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box mt="64px">
                <Footer />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  )
}

export default InviteSent;