import React from 'react';

import PayForwardWelcome from '../payForward/PayForwardWelcome';
import WithProjectTemplate from '../common/WithProjectTemplate'

const PayForwardWelcomePage = () => {
  return (
    <WithProjectTemplate>
      <PayForwardWelcome />
    </WithProjectTemplate>
  )
}

export default PayForwardWelcomePage;