import React from 'react';
import isNil from 'lodash-es/isNil';
import { Box } from '@material-ui/core'

import useProjectQqlHandler from '../../hooks/useProjectGqlHandler';
import SegmentDescription from './SegmentDescription';
import SegmentHeader from './SegmentHeader';
import SegmentEnd from './SegmentEnd';
import SegmentError from './SegmentError';
import SegmentLoader from './SegmentLoader';
import SegmentSteps from './SegmentSteps';
import ProjectContext from '../../contexts/projectContext';
import useToast from '../../hooks/useToasti';

const Intaker = () => {
  let component;
  const { currentProject } = React.useContext(ProjectContext);
  const {
    onSegmentSteps,
    onSegmentEnd,
    loadingFirstSegment,
    loadingSegment,
    fetchingCurrentProject,
    loadingNextPreviousSegment,
    segmentCurrentStep,
    onSegmentStart,
    onSegmentDescription,
    loadNextPreviousError,
    loadFirstSegmentError,
    fetchProgressError,
    fetchSegment,
    fetchProjectError,
    loadSegmentError,
    currentSegment,
    setCurrentStepIndex,
    setNextStep,
    fetchNextPreviousSegment
  } = useProjectQqlHandler();
  const { renderToast } = useToast();
  const segmentProgress = currentSegment?.progressInPercentage || 0;

  // On mount, fetch segment
  React.useEffect(fetchSegment, []);

  // When currenct project is set, fetch segment
  React.useEffect(fetchSegment, [currentProject]);

  React.useEffect(() => {
    if (!isNil(currentSegment) && currentSegment.hideSegmentHeader && currentSegment.steps.length === 0) {
      fetchNextPreviousSegment('next');
    }
  }, [currentSegment]);

  const showLoader = fetchingCurrentProject
    || loadingFirstSegment
    || loadingNextPreviousSegment 
    || loadingSegment;

  const showError = !isNil(loadFirstSegmentError)
    || !isNil(loadNextPreviousError)
    || !isNil(fetchProgressError)
    || !isNil(fetchProjectError)
    || !isNil(loadSegmentError)

  // Present correct screens here
  if (showLoader) {
    component = <SegmentLoader />
  } else if (showError) {
    component = <SegmentError />
  } else if (onSegmentStart()) {
    component = <SegmentHeader />
  } else if (onSegmentDescription()) {
    component = <SegmentDescription />
  } else if (onSegmentSteps() && !isNil(segmentCurrentStep())) {
    component = <SegmentSteps
      steps={currentSegment.steps.sort((a,b) => a.displayOrder - b.displayOrder)}
      currentProject={currentProject}
      setCurrentStep={setCurrentStepIndex}
      nextStep={setNextStep}
    />
  } else if (onSegmentEnd()) {
    component = <SegmentEnd
      projectProgress={segmentProgress}
      setNextStep={setNextStep}
    />
  } else {
    component = null; // Discuss this, do we need to handle this?
  }

  return (
    <Box>
      { renderToast() }
      {component}
    </Box>
  )
}

export default Intaker;