import { makeStyles } from '@material-ui/styles'
import Background from 'icons/background.svg'

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'top center'
  },
  layout: {
    minHeight: '100vh'
  },
  title: {
    fontSize: '28px',
    lineHeight: '36px'
  },
  invitation: {
    display: 'grid',
    borderRadius: '10px',
    backgroundColor: '#fff',
    marginTop: '80px',
    padding: '0 24px',
    color: theme.palette.primary.main
  },
  card: {
    marginTop: '60px'
  },
  image: {
    backgroundColor: '#fff',
    width: '115px',
    height: '115px',
    borderRadius: '50%',
    textAlign: 'center',
    margin: '0 auto',
    marginTop: '-57px',
    paddingTop: '20px'
  },
  learn: {
    fontSize: '12px',
    lineHeight: '16px'
  },
  button: {
    fontSize: '14px',
    fontWeight: 'normal'
  },
  linkContainer: {
    textAlign: 'center',
    border: '1px solid #c4c4c4',
    borderRadius: '10px',
    padding: '16px 12px',
    wordBreak: 'break-word'
  }
}));