import React from 'react';

export const defaultContextValue = {
  currentSegment: undefined,
  setCurrentSegment: undefined,
  currentStepId: undefined,
  setCurrentStepId: undefined,
  currentStepIndex: undefined,
  setCurrentStepIndex: undefined,
  currentSegmentAnswers: undefined,
  setCurrentSegmentAnswers: undefined,
  roomFetchAction: undefined,
  currentRoomConversationsAnswer: undefined,
  setCurrentRoomConversationsAnswer: undefined,
  currentRoom: undefined,
  rooms: undefined,
  setRooms: undefined
};

const ProjectContext = React.createContext(defaultContextValue);

export default ProjectContext;
