import * as yup from 'yup';
import isNil from 'lodash-es/isNil';
import omitBy from 'lodash-es/omitBy';

// TODO: Remove this!
// For the time Being while markups are a WIP
const DONT_INCLUDE = [
  'Rank Questions: Dropdown + Input Field',
  'Date Selector',
  'Time Field',
  'Ranking',
  'Read_Only',
  'Rate Field (with labels)',
  'Dropdown',
  'Text Area Field',
  'Input Text Field',
  'Tag Selection',
  'Tickbox',
  'Check box',
  'Radio button',
  'Horizontal Radio'
]

export const requiredField = ({ attribute }) =>
  yup
    .string()
    .nullable(true)
    .trim()
    .required()
    .label(attribute);

export const emailField = ({ attribute }) =>
  yup
    .string()
    .nullable(true)
    .trim()
    .email('Please input a valid email address')
    .label(attribute);

export const wholeNumberField = ({ attribute }) =>
  yup
    .number()
    .nullable(true)
    .min(1700, 'Min value is 1700')
    .max(2019, 'Max value is 2019')
    .label(attribute);

export const nestedCheckboxWithDuration = ({ attribute }) =>
  yup
    .object()
    .test("validate duration values", function (value) {
      const keys = Object.keys(value);
      let invalidValues = [];
      keys.map((key) => {
        if (value[key].duration === '00') {
          invalidValues.push(key);
        }
      })
      if (invalidValues.length !== 0) {
        return this.createError({
          message: invalidValues.join(),
          path: attribute, // Fieldname
        });
      } else return true;
    })
    .label(attribute)

export const nestedRatingWithDuration = ({ attribute }) =>
  yup
    .object()
    .test("validate duration values", function (value) {
      if (value.duration === '00') {
        return this.createError({
          message: 'Please provide a duration value.',
          path: attribute, // Fieldname
        });
      } else return true;
    })
    .label(attribute)


// Build field here.
// Handle what kind of field validation to apply    
function buildField(field) {
  if (field.questionType === 'Email Address') {
    return emailField({ attribute: '' })
  } else if (field.questionType === 'Whole Number Field') {
    return wholeNumberField({ attribute: '' })
  } else if (field.questionType === 'Nested Checkbox with Duration Field') {
    return nestedCheckboxWithDuration({ attribute: field.id.toString() })
  } else if (field.questionType === 'Nested Rating with Duration Field') {
    return nestedRatingWithDuration({ attribute: field.id.toString() })
  }
}

export const buildValidationSchema = (fields) => {
  let shape = {};
  fields.forEach((field) => {
    // Don't include fields that are read only in the schema
    if (!DONT_INCLUDE.includes(field.questionType)) {
      shape[field.id.toString()] = buildField(field)
    }
  })

  return yup.object().shape(omitBy(shape, isNil));
};

export default buildValidationSchema;