import React, { useState, useEffect } from 'react'
import { Box, Link } from '@material-ui/core'
import clsx from 'clsx';
import { Carousel } from 'react-responsive-carousel';
import CircularProgress from '@material-ui/core/CircularProgress';
import isNil from 'lodash-es/isNil';

import { useStyles } from '../../styles/reflectionDashboard';
import { useFetchCarouselCards } from '../../api/reflection';

import CarouselFourth from 'icons/carousel-fourth.png';
import ReactHtmlParser from 'react-html-parser';
import PrivacyOathModal from './PrivacyOathModal';

const ReflectionCarousel = () => {
  const classes = useStyles();

  const [isPrivacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [fetchCarouselCards, { loading, error, data }] = useFetchCarouselCards();

  useEffect(fetchCarouselCards, []);

  function setPrivacyModalVisible() {
    setPrivacyModalOpen(!isPrivacyModalOpen);
  }

  function renderPrivacyModal() {
    if (!isPrivacyModalOpen) return null;
    return <PrivacyOathModal open={isPrivacyModalOpen} onClose={setPrivacyModalVisible} />
  }

  if (!isNil(error)) {
    return (
      <div className={clsx(classes.roomsErrorContainer)}>
        <Box px="50px" my="20px" style={{ textAlign: 'center' }}>
          <p>Ooops! An error occured. Try again by clicking try again.</p>

          <a href="#" onClick={() => fetchCarouselCards()} className={clsx(classes.tryAgainLink)}>Try again</a>
        </Box>
      </div>
    )
  }

  function renderCards() {
    if (isNil(data.fetchCarouselSlides)) return null;
    
    return data.fetchCarouselSlides.map((slide) => {
      const hasLink = slide.linkTitle !== '' && slide.link !== '';

      return (
        <Box className={clsx(classes.roomCard)}
          style={{
            width: '100%',
            backgroundColor: '#444',
            backgroundImage: `url(${slide.backgroundImageUrl})`,
            backgroundSize: '100% 100%', borderRadius: '8px'
          }}>
            <div style={{ marginTop: '35px' }}>
              <div className={clsx(classes.carouselContent)}>
                {ReactHtmlParser(slide.content)}
              </div>

              {hasLink && (
                <div className={clsx(classes.carouselLink)}>
                  <Link target="_blank" rel="noopener" href={slide.link} underline="always" style={{ color: '#fff' }}>
                    {slide.linkTitle}
                  </Link>
                </div>
              )}
            </div>
        </Box>
      );
    }) 
  }

  if (loading || isNil(data)) {
    return (
      <div className={clsx(classes.roomsLoaderContainer)}>
        <Box px="50px" my="20px" style={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      </div>
    );
  }

  return (
    <>
      <Carousel
        style={{ borderRadius: '8px' }}
        autoPlay
        showIndicators
        infiniteLoop
        stopOnHover
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        interval={7000}
      >
        {renderCards()}
        <Box className={clsx(classes.roomCard)}
          style={{
            width: '100%',
            backgroundColor: '#444',
            backgroundImage: `url(${CarouselFourth})`,
            backgroundSize: 'cover', borderRadius: '8px'
          }}>
          <Box className={clsx(classes.carouselContentOath)}>
            <Link href="#" onClick={setPrivacyModalVisible} underline="always" style={{ color: '#fff' }}>
              TEP PRIVACY OATH
            </Link>
            {renderPrivacyModal()}
          </Box>
        </Box>
      </Carousel>
    </>
  )
}

export default ReflectionCarousel;
