import React from 'react';
import ReflectionGiftBox from '../reflection/ReflectionGiftBox';
import WithProjectTemplate from '../common/WithProjectTemplate';

const ReflectionGiftBoxPage = () => {
  return (
    <WithProjectTemplate>
      <ReflectionGiftBox />
    </WithProjectTemplate>
  )
}

export default ReflectionGiftBoxPage