import { makeStyles } from '@material-ui/styles';

const drawerWidth = 240;

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#fff'
  },
  dashboardContent: {
    height: '100%',
    fontSize: '16px',
    lineHeight: '18px',
    backgroundColor: '#2F2F2F',
    color: '#fff'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
    background: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    padding: '16px'
  },
  drawerHeader: {
    marginBottom: '34px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  roomCard: {
    borderRadius: '8px',
    height: '220px',
    padding: '10px',
    fontSize: '12px',
    lineHeight: '16px',
    '&:after': {
      opacity: 0.5
    }
  },
  percentageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    fontSize: '18px'
  },
  score: {
    fontSize: '10px',
    lineHeight: '13px',
    textAlign: 'center',
    marginTop: '5px',
    fontWeight: 'bold'
  },
  notifContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  notif: {
    width: '20px',
    height: '20px',
    backgroundColor: '#F98500',
    color: '#fff',
    borderRadius: '50%',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '18px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  menuNotif: {
    position: 'absolute',
    top: '2px',
    left: '10px'
  },
  largeNotif: {
    width: '34px',
    height: '34px',
    backgroundColor: '#F98500',
    color: '#fff',
    borderRadius: '50%',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '18px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentHeader: {
    fontSize: '16px',
    lineHeight: '24px'
  },
  roomContent: {
    background: '#EEEEEE',
    color: '#595959',
    padding: '32px 16px'
  },
  enterLink: {
    color: '#fff',
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '18px'
  },
  item: {
    borderRadius: '8px !important'
  },
  carouselContent: {
    background: 'none',
    fontSize: '17px',
    lineHeight: '25px',
    width: '80%',
    margin: '35px auto 0 auto',
    textAlign: 'left',
    marginBottom: '4px'
  },
  carouselContentOath: {
    margin: 0,
    textAlign: 'center', 
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    fontSize: '17px',
    lineHeight: '25px'
  },
  carouselLink: {
    margin: 0,
    textAlign: 'center', 
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    fontSize: '17px',
    lineHeight: '25px'
  },
  cardName: {
    fontWeight: 'bold',
    flex: 2,
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
    paddingTop: '10px'
  },
  pin: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    display: 'flex',
    height: '25px'
  },
  roomsLoaderContainer: {
    justifyContent: 'center',
    display: 'flex'
  },
  roomsErrorContainer: {
    justifyContent: 'center',
    display: 'flex',
    color: '#ffffff',
    textAlign: 'center'
  },
  tryAgainLink: {
    color: 'rgb(39, 110, 241)'
  },
  roomSubTexts: {
    fontSize: '10px',
    lineHeight: '13px'
  },
  notifDot: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    marginLeft: '5px',
    background: '#FF8B03'
  },
  notifyLabel: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '21px',
    marginLeft: '10px'
  },
  radio: {
    root: {
      marginBottom: '24px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#276EF1',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
}));