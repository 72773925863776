import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles({
  payForward: {
    backgroundColor: '#E3EAF7',
    color: '#595959',
    width: '100%'
  },
  componentLabel: {
  	color: '#595959'
  },
  componentLabelDescription: {
  	color: '#595959'
  }
});

export default useStyles;