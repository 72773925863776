import React from 'react';
import { useStyles } from "../../styles/giftBox";
import { useTheme } from '@material-ui/core/styles';
import { Divider, Drawer, Grid, Icon, List, ListItem } from "@material-ui/core";
import { Link } from '@reach/router';
import CloseSidebarIcon from 'icons/close-sidebar.svg';
import clsx from "clsx";
import ChevronRight from 'icons/chevron-right.svg';

const BurgerMenu = ({ open, setOpen, currentProject }) => {
  const classes = useStyles();
  const theme =useTheme();
  const projectUrlPrefix = `/project/${currentProject.slug}`;
  const reflectionUrlPrefix = `${projectUrlPrefix}/reflection`;

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="temporary"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.drawerHeader}>
        <Grid container className={ clsx(classes.sidebarHeader, 'lora') } onClick={handleDrawerClose} justify="space-between" alignItems="flex-end">
          <Grid item>
            The Empathy Project
          </Grid>
          <Grid item>
            {theme.direction === 'ltr' ? <Icon fontSize="small">
              <img src={CloseSidebarIcon} /></Icon> : <Icon fontSize="small"><img src={ChevronRight} /></Icon>}
          </Grid>
        </Grid>
      </div>
      <List className={ clsx(classes.sidebarList) }>
        <Link to='/dashboard' className={ clsx(classes.sidebarLink) }>
          DASHBOARD
        </Link>
      </List>
      <List className={ clsx(classes.sidebarListSub) }>
        <Link to={`${projectUrlPrefix}/reflection/dashboard`} className={ clsx(classes.sidebarLinkSub) }>
          REF SHEET
        </Link>
      </List>
      <List className={ clsx(classes.sidebarListSub) }>
        <Link to='/project/create' className={ clsx(classes.sidebarLinkSub) }>
          START PROJECT
        </Link>
      </List>
      <List className={ clsx(classes.sidebarListSub) } style={{ paddingBottom: '24px' }}>
        <Link to='/dashboard' className={ clsx(classes.sidebarLinkSub) }>
          INVITE LIST
        </Link>
      </List>
      <Divider />
      <List className={ clsx(classes.sidebarList) }>
        <Link to='#' className={ clsx(classes.sidebarLink) } disabled>
          PARTNER WITH US
        </Link>
      </List>
      <Divider />
      <List className={ clsx(classes.sidebarList) }>
        <Link to='#' className={ clsx(classes.sidebarLink) }>
          FAQs
        </Link>
      </List>
      <Divider />
      <List className={ clsx(classes.sidebarList) }>
        <Link to='/privacy-policy' className={ clsx(classes.sidebarLink) }>
          DATA USE & PRIVACY
        </Link>
      </List>
      <Divider />
      <List className={ clsx(classes.sidebarList) }>
        <Link to='#' className={ clsx(classes.sidebarLink) }>
          CONTACT
        </Link>
      </List>
      <Divider />
    </Drawer>
  )
}

export default BurgerMenu;