import React from 'react'
import { AppBar, 
          Box, 
          Button, 
          Container, 
          Icon,
          Toolbar,
          Typography } from '@material-ui/core'
import { useStyles } from '../../styles/giftBox';
import clsx from 'clsx';

import HamburgerIcon from 'icons/hamburger.svg';
import LetterIcon from 'icons/envelope-letter.svg';
import Fade from '@material-ui/core/Fade';
import BurgerMenu from "../common/BurgerMenu";
import ProjectContext from '../../contexts/projectContext';

const ReflectionPayForward = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const { currentProject } = React.useContext(ProjectContext);

  const startAnotherProject = () => {
    window.open('https://empathy100.io/','_blank')
  }
  return (
    <Fade in={true} unmountOnExit>
      <div className={clsx('page-container', classes.root)}>
        <Container disableGutters={true} maxWidth="sm">
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
            color="default"
            style={{ background: `#fff`, padding: `24px 16px 0 16px`, borderBottom: `1px solid #595959` }}
          >
            <Toolbar disableGutters={true} style={{ justifyContent: 'space-between' }}>
              <Box>
                <Typography variant="h6" className={clsx('lora')} color="primary">
                  The Empathy Project
                </Typography>
              </Box>
              <Box><Icon fontSize="large" onClick={handleDrawerOpen}><img src={HamburgerIcon} /></Icon></Box>
            </Toolbar>
          </AppBar>
          <BurgerMenu
            open={open}
            setOpen={setOpen}
            currentProject={currentProject}
          />
          <Box className={clsx(classes.viewContent)} p="16px">
            <Box>
              <Box mb="34px" className="bold" style={{ fontSize: '18px', lineHeight: '24px', textAlign: 'center' }}>
                PAY IT FORWARD
              </Box>
              <Box mb="34px" p="16px" className={ clsx(classes.payForward) }>
                <Box mt="34px" mb="24px">
                  <img src={LetterIcon} />
                </Box>
                <Box mb="24px">
                  Pay it forward by inviting a friend
                  to experience the Empathy Project.
                </Box>
                <Box mb="44px">
                  Give the unstoppable gift that keeps giving.
                </Box>
              </Box>
              <Box mb="16px">
                <Button variant="contained" color="primary" onClick={startAnotherProject}>
                  Start an Empathy Project
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </div>
    </Fade>
  )
}

export default ReflectionPayForward;
