import React, { useContext } from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { Link } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { navigate } from '@reach/router';

import ProjectContext from '../../contexts/projectContext';
import useToast from '../../hooks/useToasti';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px 0',
    padding: '16px 12px',
    fontSize: '14px',
    lineHeight: '19px',
    color: theme.palette.primary.main
  },
  content: {
    height: '100%'
  }
}));

const ProjectCard = ({ project }) => {
  const classes = useStyles();
  const { firstName, lastName, status, slug } = project;
  const [controllableStatus] = React.useState(status);
  const [actionLabel, setActionLabel] = React.useState();
  const [cardType, setCardType] = React.useState();
  const [statusLabel, setStatusLabel] = React.useState();
  const [statusClass, setStatusClass] = React.useState();
  const { setCurrentProject } = useContext(ProjectContext);
  const { renderToast } = useToast();

  // On mount define correct styles and states
  React.useEffect(processStates, [])

  function processStates() {
    switch (controllableStatus) {
      case 'completed':
        setCardType("card-review");
        setStatusLabel("Completed");
        setStatusClass("completed");
        setActionLabel("Reflection Sheet");
        break;
      case 'confirmed':
      case 'initiated':
        setCardType("card-go-live");
        setStatusLabel("Ready");
        setStatusClass("pending");
        setActionLabel("Get Started");
        break;
      case 'in_progress':
        setCardType("card-in-progress");
        setStatusLabel("In Progress");
        setStatusClass("pending");
        setActionLabel("Continue");
        break;
    }
  }

  // TODO: Handle completed projects correctly
  function onActionClick(event) {
    event.preventDefault()
    switch (status) {
      case 'completed':
        setCurrentProject(project);
        navigate(`project/${slug}/reflection/dashboard`);
        break;
      case 'initiated':
        // do go live task
        setCurrentProject(project);
        navigate(`project/${slug}/go-live-start`);
        break;
      case 'in_progress':
        setCurrentProject(project);
        navigate(`project/${slug}/intaker`);
        break;
    }
  }
  
  return (
    <Paper elevation={3} className={ clsx(classes.root, cardType, 'bold') }>
      { renderToast() }
      <Grid container className={ clsx(classes.content) } alignItems="flex-end" justify="space-between">
        <Grid item>
          <Grid container direction="column">
            <Grid item className={ clsx('uppercase') }>
              {`${firstName} ${lastName}`}
            </Grid>
            <Grid item className={ clsx(statusClass) }>
              {statusLabel}
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={ clsx('uppercase') }>
          { actionLabel !== 'Reflection Sheet' &&
            <Link href="#" onClick={onActionClick} underline="always">
              <>{actionLabel}</>
            </Link>
          }
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ProjectCard;