import React from 'react'
import {
  Box,
  Button,
  TextField,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx';
import isNil from 'lodash-es/isNil';

import { makeStyles } from '@material-ui/styles'
import { useForgotPassword } from "../../api/user";
import { buildValidationSchema } from "../../validations/forgotPassword";
import CustomDialog from '../dialog/CustomDialog'
import useForm from "../../hooks/useForm";
import ApplicationContext from '../../contexts/applicationContext';

const useStyles = makeStyles(theme => ({
  closeIcon: {
    height: 'auto',
    width: 'auto'
  },
  content: {
    padding: '24px'
  },
  errorContainer: {
    backgroundColor: 'rgb(253, 236, 234)',
    padding: '10px',
    color: 'red',
    borderRadius: '6px',
    marginTop: '10px',
    margin: '0 16px 0 16px'
  }
}));

const ForgotPassword = (props) => {
  const defaultState = { email: '' }
  const classes = useStyles();
  const { open, onClose } = props;
  const [forgotPassword, { error, loading }] = useForgotPassword(onCompleted);
  const { setToastOpen, setToastMessage } = React.useContext(ApplicationContext);

  const handleClose = () => {
    onClose();
  };

  function onCompleted(response) {
    if (isNil(response) || isNil(response.forgotPassword)) return;

    if (response.forgotPassword.success) {
      setToastMessage('Successfully sent reset password to your email')
      setToastOpen(true);
      onClose();
    }

    if (!response.forgotPassword.success) {
      setErrors(response.forgotPassword.errors)
    }
  }

  function renderError() {
    if (!isNil(error)) {
      return (
        <div className={clsx(classes.errorContainer)}>
          Oops! Something went wrong. Might be a network error.
        </div>
      )
    }

    return null;
  }

  function submit() {
    forgotPassword({ variables: { ...values } });
  }

  const { values, handleSpecificChange, handleSubmit, setErrors, errors: formErrors } = useForm({
    defaultState,
    validationSchema: buildValidationSchema(),
    submitAction: submit
  })

  return (
    <CustomDialog
      onClose={handleClose}
      open={open}
      fullWidth
      title="FORGOT PASSWORD"
    >
      {renderError()}
      <Box className={clsx(classes.content)}>
        <Typography variant="body1" color="primary">
          If you have forgotten your password you can reset it here.
        </Typography>
        <Box mt="24px">
          <TextField
            name='email'
            label="Email"
            onChange={(event) => handleSpecificChange({ field: 'email', value: event.target.value })}
            margin="normal"
            fullWidth
            error={!isNil(formErrors.email)}
            helperText={formErrors.email}
          />
        </Box>
        <Box my="24px">
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
            Send My Password
          </Button>
        </Box>
      </Box>
    </CustomDialog>
  )
}

export default ForgotPassword