import React from 'react';
import { Box } from '@material-ui/core'
import { Chip } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser';
import isNil from 'lodash-es/isNil';

const RateField = (props) => {
  const { label, onChange, name, defaultValue, options, description, fontColor } = props;

  const handleClick = (option) => {
    onChange({ field: name, value: option.identifier })
  }

  function renderRateFields() {
    let answerOptions = options.map(option => {
      return <Grid item key={option.id}>
        <Grid container direction="column" alignItems="center" spacing={4}>
          <Typography variant="h2">
            <Chip 
              color="primary"
              label={option.title}
              clickable size="medium"
              className="chip-rate"
              onClick={() => handleClick(option)}
              variant={!isNil(defaultValue) && defaultValue === option.identifier ? 'default' : 'outlined'}
            />
          </Typography>
          <Box style={{ width: '80px', color: '#595959', textAlign: 'center', lineHeight: '14px', marginTop: '5px' }}>
            {ReactHtmlParser(option.description)}
          </Box>
        </Grid>
      </Grid>;
    });

    return answerOptions;
  }

  return (
    <Box mb="24px" style={{ display: 'flex', flexDirection: 'column' }}>
      { label && <Box mb="24px" className="component-label" style={{ color: fontColor }}>{ReactHtmlParser(label)}</Box> }
      { description && <Box className="component-label-description" style={{ color: fontColor }}>{ReactHtmlParser(description)}</Box> }
      <Box my="24px">
        <Grid container justify="space-around">
          {renderRateFields()}
        </Grid>
      </Box>
    </Box>
  )
}

const MemoizedComponent = React.memo(RateField);

export default MemoizedComponent;