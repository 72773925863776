import React from 'react';
import { Box, Container, Grid, Icon } from '@material-ui/core'
import clsx from 'clsx';
import ChevronRight from 'icons/chevron-right.svg';
import ChevronRightWhite from 'icons/chevron-right-white.svg';
import ReactHtmlParser from 'react-html-parser';
import Fade from '@material-ui/core/Fade';

import ProjectContext from '../../contexts/projectContext';
import useProjectGqlhandler from '../../hooks/useProjectGqlHandler';

const SegmentHeader = () => {
  const { setNextStep } = useProjectGqlhandler();
  const { currentSegment } = React.useContext(ProjectContext);
  const icon = currentSegment.iconName ? require(`icons/${currentSegment.iconName.trim()}.svg`) : null;

  function handleNext() {
    setNextStep();
  }

  return (
    <Fade in={true} unmountOnExit>
      <Container disableGutters={true} maxWidth="sm">
        <Box style={{ backgroundColor: currentSegment.bgColor, textAlign: 'center', color: currentSegment.fontColor }}>
          <Grid container className={ clsx('full-height') } component="div" direction="column" alignItems="center" justify="center">
            { currentSegment.subtitle && <Grid item>
              <Box mb="24px" style={{ color: currentSegment.fontColor }} className={ clsx('segment-subtitle', 'bold') }>
                {ReactHtmlParser(currentSegment.subtitle)}
              </Box>
            </Grid> }
            { currentSegment.iconName && <Grid item>
              <Box mb="24px" style={{ color: currentSegment.fontColor }}>
                <Icon style={{ width: 'auto', height: 'auto' }}>
                  <img src={icon} />
                </Icon>
              </Box>
            </Grid> }
            { currentSegment.subSegmentTitle && <Grid item>
              <Box mb="24px" style={{ color: currentSegment.fontColor }} className={ clsx('segment-subheading') }>
                {currentSegment.subSegmentTitle}
              </Box>
            </Grid> }
            { currentSegment.name && <Grid item>
              <Box style={{ color: currentSegment.fontColor }} className={ clsx('segment-heading') }>
                {currentSegment.name}
              </Box>
            </Grid> }
            <Grid item>
              <Box 
                mt="64px"
                className={ clsx('next-button') } 
                onClick={handleNext} 
                style={{ color: currentSegment.fontColor, 
                  border: currentSegment.nextButtonColor ? `1px solid ${currentSegment.nextButtonColor}` : `1px solid #595959`, 
                  backgroundColor: currentSegment.nextButtonColor || '#595959' }}>
                <Icon fontSize="large">
                  { currentSegment.nextButtonColor && currentSegment.nextButtonColor.toLowerCase() === '#ffffff' ?
                    <img src={ChevronRight} />
                    : <img src={ChevronRightWhite} />
                  }
                </Icon>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Fade>
  )
}

export default SegmentHeader;