import React from 'react';

import ReflectionCoverLetter from '../reflection/ReflectionCoverLetter';
import WithProjectTemplate from '../common/WithProjectTemplate'

const ReflectionCoverLetterPage = () => {
  return (
    <WithProjectTemplate>
      <ReflectionCoverLetter />
    </WithProjectTemplate>
  )
}

export default ReflectionCoverLetterPage;