import React from 'react'
import {
  Box,
  Button, Divider, Grid, Icon, Link, Typography,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'
import CustomDialog from '../dialog/CustomDialog'
import clsx from "clsx";
import ViberIcon from 'icons/viber-grey.svg'
import WhatsappIcon from 'icons/whatsapp-grey.svg'
import MessengerIcon from 'icons/messenger-grey.svg'
import EnvelopeMailIcon from 'icons/envelope-mail-grey.svg'
import ApplicationContext from "../../contexts/applicationContext";
import copy from 'copy-to-clipboard';
import useToast from '../../hooks/useToasti';

const useStyles = makeStyles(theme => ({
  closeIcon: {
    height: 'auto',
    width: 'auto'
  },
  content: {
    padding: '24px'
  },
  errorContainer: {
    backgroundColor: 'rgb(253, 236, 234)',
    padding: '10px',
    color: 'red',
    borderRadius: '6px',
    marginTop: '10px',
    margin: '0 16px 0 16px'
  }
}));

const ShareThisPage = ({ open, onClose, currentProject, handleNext }) => {
  const { setToastOpen, setToastMessage } = React.useContext(ApplicationContext);
  const classes = useStyles();
  const { renderToast } = useToast();

  function onClickLink(e) {
    e.preventDefault();
    copy('https://empathy100.io/village');
    setToastMessage('Copied to clipboard!')
    setToastOpen(true);
  }

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      fullWidth
      title="SHARE THIS PAGE"
    >
      {renderToast()}
      <Box className={clsx(classes.content)}>
        <Typography variant="body1" color="primary" margin='normal'>
          <Link href='#' underline='always' onClick={(e) => { onClickLink(e) }}>
            Copy this link https://empathy100.io/village
          </Link>
        </Typography>
        <Box mt="24px">
          <Button variant="contained" color="primary" onClick={handleNext}>
            Done
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box mt="24px">
        <Grid container spacing={4} justify="center">
          <Grid item>
            <Link href={`viber://forward?text=${"Thanks for spreading the Empathy. Just follow this link and click on the orange button to START AN EMPATHY PROJECT (just like what you and I just did)! https://empathy100.io/village"}`}>
              <Icon fontSize="large"><img src={ViberIcon} /></Icon>
            </Link>
          </Grid>
          <Grid item>
            <Link href={`whatsapp://send?text=${"Thanks for spreading the Empathy. Just follow this link and click on the orange button to START AN EMPATHY PROJECT (just like what you and I just did)! https://empathy100.io/village"}`}>
              <Icon fontSize="large"><img src={WhatsappIcon} /></Icon>
            </Link>
          </Grid>
          <Grid item>
            <Link href={`fb-messenger://share/?link=Thanks for spreading the Empathy. Just follow this link and click on the orange button to START AN EMPATHY PROJECT (just like what you and I just did)! https://empathy100.io/village`}>
              <Icon fontSize="large"><img src={MessengerIcon} /></Icon>
            </Link>
          </Grid>
          <Grid item>
            <Link href={`mailto:?body=${"Thanks for spreading the Empathy. Just follow this link and click on the orange button to START AN EMPATHY PROJECT (just like what you and I just did)! https://empathy100.io/village"}`} target='_top'>
              <Icon fontSize="large"><img src={EnvelopeMailIcon} /></Icon>
            </Link>
          </Grid>
        </Grid>
        <Typography variant="body1" component="div">
          <Box mt="44px" style={{ textAlign: 'center', color: '#99989E', fontSize: '14px', lineHeight: '19px', margin: '24px 16px' }}>
            Please send this invite via the messaging app you use with your friend.
          </Box>
        </Typography>
      </Box>
    </CustomDialog>
  )
}

export default ShareThisPage;