import { gql, useLazyQuery, useMutation } from '@apollo/client';

export const FETCH_PROJECTS = gql`
  query fetchProjects {
    fetchProjects {
      id
      firstName
      lastName
      status
      currentStep
      currentSegment
      slug
      notifyForGiftbox
      inviteeId
      inviterId
    }
  }
`;

export function useFetchProjects() {
  return useLazyQuery(FETCH_PROJECTS, { errorPolicy: 'all', fetchPolicy: 'no-cache' });
}

export const FETCH_PROJECT = gql`
  query fetchProject($slug: String!) {
    fetchProject(slug: $slug) {
      id
      firstName
      lastName
      status
      currentStep
      currentSegment
      slug
      notifyForGiftbox
      reflectionViewVisited
    }
  }
`;

export function useFetchProject(onCompleted, onError) {
  return useLazyQuery(FETCH_PROJECT, { errorPolicy: 'all', fetchPolicy: 'no-cache', onCompleted: onCompleted, onError: onError });
}

export const FETCH_PROJECT_PROGRESS = gql`
  query projectCompletedPercentage($id: ID!) {
    projectCompletedPercentage(id: $id) {
      percentageOfCompletedSegments
    }
  }
`;

export function useFetchProjectProgress(onCompleted, onError) {
  return useLazyQuery(FETCH_PROJECT_PROGRESS, { errorPolicy: 'all', fetchPolicy: 'no-cache', onCompleted: onCompleted, onError: onError });
}

export const CREATE_PROJECT = gql`
  mutation CreateProject($first_name: String!, $last_name: String!, $email: String!) {
    createProject(input: {params: {firstName: $first_name, lastName: $last_name, email: $email}}) {
      project {
        id
        firstName
        lastName
        email
        slug
        notifyForGiftbox
      }
      errors
    }
  }
`;

export function useCreateProject(onCompleted, onError) {
  return useMutation(CREATE_PROJECT, { errorPolicy: 'all', onCompleted: onCompleted, onError: onError });
}

export const FETCH_FIRST_SEGMENT = gql`
  query FirstSegment($projectId: ID!) {
    firstSegment(projectId: $projectId) {
      id
      description
      name
      bgColor
      fontColor
      subtitle
      subSegmentTitle
      nextButtonColor
      previousButtonColor
      hideProgressScreen
      hideSegmentHeader
      isLast
      isFirst
      displayOrder
      progressInPercentage
      steps{
        id
        title
        nextButtonColor
        previousButtonColor
        displayOrder
        questions {
          description
          displayOrder
          id
          questionTypeId
          questionType
          status
          title
          answer
          placeholder
          logicalExpression
          answerOptions{
            id
            title
            value
            displayOrder
            description
            status
            identifier
          }
        }
      }
    }
  }
`;

export function useFetchFirstSegment(onCompleted, onError) {
  return useLazyQuery(FETCH_FIRST_SEGMENT, { errorPolicy: 'all', onCompleted: onCompleted, onError: onError });
}

export const FETCH_SEGMENT = gql`
  query FetchSegment($id: ID!, $projectId: ID!) {
    fetchSegment(projectId: $projectId, id: $id) {
      id
      description
      name
      bgColor
      fontColor
      subtitle
      subSegmentTitle
      nextButtonColor
      previousButtonColor
      hideProgressScreen
      hideSegmentHeader
      isLast
      isFirst
      displayOrder
      progressInPercentage
      steps{
        id
        title
        nextButtonColor
        previousButtonColor
        displayOrder
        questions {
          description
          displayOrder
          id
          questionTypeId
          questionType
          status
          title
          answer
          placeholder
          logicalExpression
          answerOptions{
            id
            title
            value
            displayOrder
            description
            status
            identifier
          }
        }
      }
    }
  }
`;

export function useFetchSegment(onCompleted, onError) {
  return useLazyQuery(FETCH_SEGMENT, { errorPolicy: 'all', onCompleted: onCompleted, onError: onError });
}

export const FETCH_NEXT_PREVIOUS_SEGMENT = gql`
  query NextPreviousSegment($id: ID!, $projectId: ID!, $key: String!) {
    nextPreviousSegment(projectId: $projectId, id: $id, key: $key) {
      id
      description
      name
      bgColor
      subtitle
      iconName
      fontColor
      subSegmentTitle
      nextButtonColor
      previousButtonColor
      hideProgressScreen
      hideSegmentHeader
      isFirst
      isLast
      displayOrder
      progressInPercentage
      steps {
        id
        title
        nextButtonColor
        previousButtonColor
        displayOrder
        questions {
          description
          displayOrder
          id
          questionTypeId
          status
          title
          answer
          questionType
          placeholder
          logicalExpression
          answerOptions{
            id
            title
            value
            displayOrder
            description
            status
            identifier
          }
        }
      }
    }
  }
`;

export function useFetchNextPreviousSegment(onCompleted, onError) {
  return useLazyQuery(FETCH_NEXT_PREVIOUS_SEGMENT, { errorPolicy: 'all', onCompleted: onCompleted, onError: onError });
}

export const SUBMIT_ANSWER = gql`
  mutation SubmitAnswer($projectId: ID!, $answer: JSON!) {
    submitAnswer(input: { params: { id: $projectId, answer: $answer }}) {
      success
      errors
    }
  }
`;

export function useSubmitAnswer(onCompleted, onError) {
  return useMutation(SUBMIT_ANSWER, { errorPolicy: 'all', onCompleted: onCompleted, onError: onError });
}

export const UPDATE_PROJECT = gql`
  mutation ChangeProjectStatus($id: ID!){
    changeProjectStatus(input: {id: $id}) {
      project {
        status
      }
      success
      errors
    }
  }
`;

export function useUpdateProject(onCompleted) {
  return useMutation(UPDATE_PROJECT, { errorPolicy: 'all', onCompleted: onCompleted });
}

export const SEND_EMAIL_INVITE = gql`
  query InviteUser($id: ID!){
    inviteUser(id: $id) {
      id
      firstName
      lastName
      email
      answer
      status
    }
  } 
`;

export function useSendEmailInvite(onCompleted) {
  return useLazyQuery(
    SEND_EMAIL_INVITE, {
      errorPolicy: 'all',
      onCompleted: onCompleted
    }
  );
}

export const NOTIFY_GIFT_BOX = gql`
  mutation NotifyForGiftbox($id: ID!, $notify_for_giftbox: Boolean!){
    notifyForGiftbox(input: {params: {id: $id, notifyForGiftbox: $notify_for_giftbox} }) {
      project {
        notifyForGiftbox
      }
      success
      errors
    }
  }
`;

export function useNotifyGiftbox(onCompleted, onError) {
  return useMutation(NOTIFY_GIFT_BOX, { errorPolicy: 'all', onCompleted: onCompleted, onError: onError });
}

export const REFLECTION_VIEW_VISITED = gql`
  mutation ReflectionViewVisited($id: ID!, $reflection_view_visited: Boolean!){
    reflectionViewVisited(input: {params: {id: $id, reflectionViewVisited: $reflection_view_visited} }) {
      success
      errors
    }
  }
`;

export function useReflectionViewVisited(onCompleted) {
  return useMutation(REFLECTION_VIEW_VISITED, { errorPolicy: 'all', onCompleted: onCompleted });
}