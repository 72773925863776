import * as yup from 'yup';
import isNil from 'lodash-es/isNil'
import omitBy from 'lodash-es/omitBy';

export const passwordField = ({ attribute }) =>
  yup
    .string()
    .nullable(true)
    .trim()
    .required()
    .oneOf([yup.ref('passwordConfirmation'), null], 'Passwords must match')
    .label(attribute);

export const confirmPasswordField = ({ attribute }) =>
  yup
    .string()
    .nullable(true)
    .trim()
    .required()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .label(attribute);

export const buildValidationSchema = () => {
  const shape = {
    password: passwordField({ attribute: 'Password' }),
    passwordConfirmation: confirmPasswordField({ attribute: 'Confirm Password' }),
  };

  return yup.object().shape(omitBy(shape, isNil));
};

export default buildValidationSchema;