import * as yup from 'yup';
import isNil from 'lodash-es/isNil';
import omitBy from 'lodash-es/omitBy';

export const emailField = ({ attribute }) =>
  yup
    .string()
    .nullable(true)
    .trim()
    .required()
    .email()
    .label(attribute);

export const requiredField = ({ attribute }) =>
  yup
    .string()
    .nullable(true)
    .trim()
    .required()
    .label(attribute);

export const passwordField = ({ attribute }) =>
  yup
    .string()
    .nullable(true)
    .trim()
    .required()
    .oneOf([yup.ref('confirm_password'), null], 'Passwords must match')
    .label(attribute);

export const confirmPasswordField = ({ attribute }) =>
  yup
    .string()
    .nullable(true)
    .trim()
    .required()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .label(attribute);

export const buildValidationSchema = () => {
  const shape = {
    email: emailField({ attribute: 'Email' }),
    password: passwordField({ attribute: 'Password' }),
    confirm_password: confirmPasswordField({ attribute: 'Confirm password' }),
    first_name: requiredField({ attribute: 'First name' }),
    last_name: requiredField({ attribute: 'Last name' }),
  };
  return yup.object().shape(omitBy(shape, isNil));
};

export default buildValidationSchema;