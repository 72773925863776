import React from 'react';
import { Box } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser';
import isNil from 'lodash-es/isNil';
import { Icon, InputAdornment } from '@material-ui/core'
import Pencil from 'icons/pencil.svg';

const Textarea = (props) => {
  const { label, onChange, name, defaultValue, description, fontColor, placeholder } = props;

  function renderTextarea() {
    return (<Box style={{ color: fontColor }}>
      { label && <Box mb="24px" className="component-label" style={{ color: fontColor }}>{ReactHtmlParser(label)}</Box> }
      { description && <Box className="component-label-description" style={{ color: fontColor }}>{ReactHtmlParser(description)}</Box> }
      <TextField 
        multiline
        style={{ color: fontColor }}
        rowsMax={5}
        fullWidth
        name={name}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Icon fontSize="small"><img src={Pencil} /></Icon>
            </InputAdornment>
          )
        }}
        onChange={(event) => onChange({ field: name, value: event.target.value })}
        value={!isNil(defaultValue) ? defaultValue : ''}
        placeholder={placeholder}
      /></Box>);
  }

  return (
    <Box mb="24px">
      {renderTextarea()}
    </Box>
  )
}

const MemoizedComponent = React.memo(Textarea);

export default MemoizedComponent;