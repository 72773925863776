import React from 'react';
import {
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import { useStyles } from '../../styles/backtofuture';
import GeniusIcon from 'icons/genius.svg';
import PurposeIcon from 'icons/purpose.svg';
import ServiceIcon from 'icons/service.svg';
import clsx from 'clsx';
import Uppercase from 'lodash-es/upperCase';
import isNil from 'lodash-es/isNil';

const BackToFutureDetails = ({ details }) => {
  const classes = useStyles();

  const renderGenius = () => {
    if (isNil(details)) return null;
    const genius = details.back_to_future_answers.find((d) => d.name === 'genius');
    if (isNil(genius)) return null;

    return (
      <Box mb="34px">
        <Box p="24px"
          className={clsx(classes.cardTop)}
          style={{
            backgroundColor: '#DD3874',
            borderTop: '2px solid #DD3874',
            borderLeft: '2px solid #DD3874',
            borderRight: '2px solid #DD3874'
          }}>
          <Grid container spacing={2} alignItems="center">
            <Grid xs={3}>
              <img src={GeniusIcon} />
            </Grid>
            <Grid xs={9}>
              <Box className={clsx(classes.reflectionBoxTitle)}>{Uppercase(genius.name)}</Box>
            </Grid>
          </Grid>
        </Box>
        <Box pb="24px" className={clsx(classes.contentContainer, classes.cardBottom)}>
          <Box p="24px"
            className={clsx(classes.cardBottom)}
            style={{
              borderBottom: '2px solid #fff',
              borderLeft: '2px solid #fff',
              borderRight: '2px solid #fff'
            }}>
            <Typography variant="body1">
              So much has changed in the world even in such a short time. What is it in me that is gifted... that allows me to make people and situations better... that remains in me no matter how much the world changes... and allows me to stay relevant and keep being a force for good?
            </Typography>
          </Box>
          <Box mx="24px" p="16px"
            className={clsx(classes.reflectionBoxInside)}
            style={{ border: '2px solid #DD3874', color: '#DD3874' }}
          >
            {genius.answer}
          </Box>
        </Box>
      </Box>
    )
  }

  const renderPurpose = () => {
    if (isNil(details)) return null;
    const purpose = details.back_to_future_answers.find((d) => d.name === 'purpose');
    if (isNil(purpose)) return null;

    return (
      <Box mb="34px">
        <Box p="24px"
          className={clsx(classes.cardTop)}
          style={{
            backgroundColor: '#B08EB1',
            borderTop: '2px solid #B08EB1',
            borderLeft: '2px solid #B08EB1',
            borderRight: '2px solid #B08EB1'
          }}>
          <Grid container spacing={2} alignItems="center">
            <Grid xs={3}>
              <img src={PurposeIcon} />
            </Grid>
            <Grid xs={9}>
              <Box className={clsx(classes.reflectionBoxTitle)}>{Uppercase(purpose.name)}</Box>
            </Grid>
          </Grid>
        </Box>
        <Box pb="24px" className={clsx(classes.contentContainer, classes.cardBottom)}>
          <Box p="24px"
            className={clsx(classes.cardBottom)}
            style={{
              borderBottom: '2px solid #fff',
              borderLeft: '2px solid #fff',
              borderRight: '2px solid #fff'
            }}>
            <Typography variant="body1">
              What pains have I experienced most meaningfully in my own life... pains that have become a gift for me — because they allowed me to care deeply about something bigger than myself?
            </Typography>
          </Box>
          <Box mx="24px" p="16px"
            className={clsx(classes.reflectionBoxInside)}
            style={{ border: '2px solid #B08EB1', color: '#B08EB1' }}
          >
            {purpose.answer}
          </Box>
        </Box>
      </Box>
    )
  }

  const renderService = () => {
    if (isNil(details)) return null;
    const service = details.back_to_future_answers.find((d) => d.name === 'service');
    if (isNil(service)) return null;

    return (
      <Box mb="64px" pb="24px">
        <Box p="24px"
          className={clsx(classes.cardTop)}
          style={{
            backgroundColor: '#8C9CD7',
            borderTop: '2px solid #8C9CD7',
            borderLeft: '2px solid #8C9CD7',
            borderRight: '2px solid #8C9CD7'
          }}>
          <Grid container spacing={2} alignItems="center">
            <Grid xs={3}>
              <img src={ServiceIcon} />
            </Grid>
            <Grid xs={9}>
              <Box className={clsx(classes.reflectionBoxTitle)}>{Uppercase(service.name)}</Box>
            </Grid>
          </Grid>
        </Box>
        <Box pb="24px" className={clsx(classes.contentContainer, classes.cardBottom)}>
          <Box p="24px"
            className={clsx(classes.cardBottom)}
            style={{
              borderBottom: '2px solid #fff',
              borderLeft: '2px solid #fff',
              borderRight: '2px solid #fff'
            }}>
            <Typography variant="body1">
              Beyond my inner circle of family and friends... who are the people I encounter most meaningfully in my life... people whose pains call out to my giftedness?
            </Typography>
          </Box>
          <Box mx="24px" p="16px"
            className={clsx(classes.reflectionBoxInside)}
            style={{ border: '2px solid #8C9CD7', color: '#8C9CD7' }}
          >
            {service.answer}  
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Box mb="24px">
        Here's a clue from my own deeply personal GPS about my greatest joy, meaning and fulfillment...
      </Box>

      {renderGenius()}
      {renderPurpose()}
      {renderService()}
    </>
  )
}

export default BackToFutureDetails;