import React from 'react';
import { Box, Container, Grid, Icon } from '@material-ui/core'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import {navigate} from '@reach/router';
import clsx from 'clsx';
import ChevronRight from 'icons/chevron-right.svg';
import 'react-circular-progressbar/dist/styles.css';
import Fade from '@material-ui/core/Fade';

import ProjectContext from '../../contexts/projectContext';

const SegmentEnd = () => {
  const { currentProject } = React.useContext(ProjectContext);
  
  function handleNext() {
    navigate(`/project/${currentProject.slug}/intaker/thank-you`)
  }

  return (
    <Fade in={true} unmountOnExit>
      <Container disableGutters={true} maxWidth="sm">
        <Box style={{ backgroundColor: '#727272', textAlign: 'center' }} p="16px">
          <Grid container className={clsx('full-height')} component="div" direction="column" alignItems="center" justify="center">
            <div style={{ marginBottom: '100px' }}>
              <Box style={{ width: '124px', height: '124px' }}>
                <CircularProgressbar
                  value={100}
                  text={`100%`}
                  strokeWidth={10}
                  background="#FFFFFF"
                  styles={buildStyles({
                    strokeLinecap: 'flat',
                    textSize: '30px',
                    pathTransitionDuration: 0.5,
                    pathColor: '#00D7D7',
                    textColor: '#00D7D7',
                    trailColor: '#C4C4C4',
                    backgroundColor: '#FFFFFF',
                  })} />;
              </Box>
            </div>

            <Grid item>
              <Box mb="64px" mx="16px">
                <Box className="segment-end">
                  We are 100% of the way through!
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box className={clsx('next-button')} onClick={handleNext}
                style={{ border: `1px solid #fff`, backgroundColor: '#fff' }}>
                <Icon fontSize="large" color='inherit'><img src={ChevronRight} /></Icon>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Fade>
  )
}

export default SegmentEnd;