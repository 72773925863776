import React from 'react';

import ApplicationContext from '../contexts/applicationContext';
import useApplicationContextUpdater from '../hooks/useApplicationContextUpdater';

const ApplicationProvider = ({ children }) => {
  const applicationUpdater = useApplicationContextUpdater();

  return (
    <ApplicationContext.Provider value={applicationUpdater}>
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationProvider;
