import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  closeIcon: {
    height: 'auto',
    width: 'auto'
  },
  content: {
    padding: '24px'
  },
  errorContainer: {
    backgroundColor: 'rgb(253, 236, 234)',
    padding: '10px',
    color: 'red',
    borderRadius: '6px',
    marginTop: '10px',
    margin: '0 16px 0 16px'
  }
}));