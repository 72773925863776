import React from 'react';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { navigate } from '@reach/router';
import clsx from 'clsx';
import Slide from '@material-ui/core/Slide';

const IntakerThankYou = () => {
  function handleNext() {
    navigate(`/dashboard`)
  }

  return (
    <Slide direction={"left"} in={true} mountOnEnter unmountOnExit>
      <Container disableGutters={true} maxWidth="sm">
        <Box style={{ backgroundColor: '#fff', textAlign: 'center', color: '#595959' }} p="16px">
          <Grid container className={ clsx('full-height') } component="div" direction="column" alignItems="center" justify="center">
            <Grid item>
              <Typography variant="body1">
                <Box mb="64px" className="component-label">
                  That's it for our Empathy Project! Thank you so much for sharing your time and stories. 
                </Box>
              </Typography>
            </Grid>
            <Grid item>
              <Box mx="16px" style={{ marginBottom: '24px', marginTop: '44px' }}>
                <Button variant="contained" color="primary" onClick={handleNext} style={{ padding: '24px' }}>
                  Back to My Projects
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Slide>
  )
}

export default IntakerThankYou;