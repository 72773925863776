import React from 'react';
import clsx from 'clsx';
import isNil from 'lodash-es/isNil';
import {navigate} from '@reach/router';
import { Box, Button, Typography } from '@material-ui/core'

import { useFetchProjects } from '../../api/project';
import Styles from '../../styles/dashboard';
import JournalIcon from 'icons/journal.svg'
import ProjectCard from './ProjectCard'
import DataLoader from '../common/DataLoader';
import Error from '../common/Error';
import cookie from "cookie_js";
import InviteeCard from "./InviteeCard";

const Projects = () => {
  const classes = Styles();
  const [fetchProjects, { loading, error, data }] = useFetchProjects();
  const currentUserId = cookie.get('current_user_id');

  function renderInviterCards() {
    if (isNil(data)) return null;
    if (!isNil(error) || isNil(data.fetchProjects) || data.fetchProjects.length === 0) return null;

    return data.fetchProjects
      .filter(project => currentUserId === project.inviterId)
      .map((project) => {
        return (
          <ProjectCard
            key={`${project.id}`}
            project={project}
          />
        )
    })
  }

  function renderInviteeCards() {
    if (isNil(data)) return null;
    if (!isNil(error) || isNil(data.fetchProjects) || data.fetchProjects.length === 0) return null;

    return data.fetchProjects
      .filter(project => currentUserId === project.inviteeId)
      .map((project) => {
        return (
          <InviteeCard
            key={`${project.id}`}
            project={project}
          />
        )
      })
  }

  function onInvite() {
    navigate('project/create')
  }

  // Fetch Projects on component mount
  React.useEffect(fetchProjects, []);

  if (!isNil(error)) return <Error />;
  if (loading) return <DataLoader />;

  return (
    <Box mt="44px" className={clsx('default-content')}>
      <Typography className={clsx(classes.title, 'bold')}>MY PROJECTS</Typography>
      {renderInviterCards()}
      <Typography className={clsx(classes.title, 'bold')} style={{marginTop: '44px'}}>MY REFLECTION SHEET</Typography>
      {renderInviteeCards()}
      <Box my="24px">
        <Button
          variant="contained"
          color="primary"
          onClick={onInvite}
        >
          <Box mx="12px"><img src={JournalIcon} /></Box>
          Invite a Friend
        </Button>
      </Box>
    </Box>
  )
}

export default Projects;