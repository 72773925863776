import React from 'react'
import {
  Box,
  Button,
  Icon,
  Typography,
  DialogTitle,
  Grid
} from '@material-ui/core'
import clsx from 'clsx';
import isNil from 'lodash-es/isNil';

import { makeStyles } from '@material-ui/styles'
import CustomDialog from '../dialog/CustomDialog'
import PayItForwardBackground from 'icons/pay-it-forward-bg.png'
import Close from 'icons/close-white.png'

const useStyles = makeStyles(theme => ({
  closeIcon: {
    height: 'auto',
    width: 'auto'
  },
  content: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 99999,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '25px',
    color: '#595959'
  }
}));

const ReflectionPayForwardModal = (props) => {
  const classes = useStyles();
  const { open, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  const handleStartEmpathy = () => {
    onClose();
    window.location.href = '/dashboard';
  };

  return (
    <CustomDialog
      onClose={handleClose}
      open={open}
      fullWidth
      title="PAY IT FORWARD"
      background={true}
    >
      <Box style={{ position: 'absolute', right: 0, top: 0, height: '370px' }}>
        <img src={PayItForwardBackground} style={{ height: '370px', width: '100%' }} />
      </Box>
      <DialogTitle style={{ zIndex: 99999 }}>
        <Box mt="12px">
          <Grid container
            direction="row"
            justify="flex-end"
            alignItems="center">
            <Icon className={ clsx(classes.closeIcon) } onClick={handleClose}><img src={Close} /></Icon>
          </Grid>
        </Box>
      </DialogTitle>
      <Box className={clsx(classes.content)}>
        <Grid container direction="column" justify="center">
          <Grid item>
            <Box mb="20px">
              Pay it forward by inviting a friend
              to experience the Empathy Project.
            </Box>
          </Grid>
          <Grid item>
            <Box mb="20px">
              Give the unstoppable gift 
              that keeps giving.
            </Box>
          </Grid>
          <Grid item>
            <Box mt="20px" mb="30px">
              <Button variant="contained" color="primary" size="small"
                style={{ background: '#276EF1', padding: '15px', fontSize: '14px' }}
                onClick={handleStartEmpathy}>
                Start an Empathy Project
              </Button>
            </Box>
          </Grid>

        </Grid>
      </Box>
    </CustomDialog>
  )
}

export default ReflectionPayForwardModal